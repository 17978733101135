import mixpanel from "mixpanel-browser";
import { MantineProvider } from "@mantine/core";
import { Suspense, useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useDispatch } from "react-redux";
import { RouterProvider } from "react-router-dom";
import { IntercomProvider } from "react-use-intercom";
import FallBack from "./Components/FallBack/FallBack";
import { updateBaseUrl } from "./reducer/configSlice";
import router from "./routes/routes";

const INTERCOM_APP_ID = "slv7vm15";

function fallbackRender({ error, resetErrorBoundary }) {
  // Call resetErrorBoundary() to reset the error boundary and retry the render.

  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre style={{ color: "red" }}>{error.message}</pre>
    </div>
  );
}

// Initialize Mixpanel
const production_host = 'app.propulsionhq.com';
const dev_token = '891d285d077d3c3aeb16f9a0e74bdcff';
const prod_token = 'a5a8f4680ba603686cfa5b290af4557e';

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    const prepareState = async () => {
      dispatch(updateBaseUrl());
    };
    //If the hostname is anything other than your production domain, initialize the Mixpanel library with your Development Token 

    if (window.location.hostname === production_host) {
      mixpanel.init(prod_token, {
        debug: false,
        track_pageview: true,
        persistence: "localStorage",
      });
    } else {
      console.log('Mixpanel initialized with dev token');
      mixpanel.init(dev_token, {
        debug: true,
        track_pageview: true,
        persistence: "localStorage",
      });
    }
    prepareState();
  }, [dispatch]);

  return (
    <ErrorBoundary fallback={fallbackRender}>
      <Suspense fallback={<FallBack />}>
        <IntercomProvider
          appId={INTERCOM_APP_ID}
          autoBoot={true}
          initializeDelay={1000}
        >
          <MantineProvider
            theme={{
              fontFamily: "Dm-Sans, Open Sans, sans-serif",
            }}
          >
            <RouterProvider router={router} />
          </MantineProvider>
        </IntercomProvider>
      </Suspense>
    </ErrorBoundary>
  );
}

export default App;
