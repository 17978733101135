import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../service/axios";

export const getKnowledgeBaseList = createAsyncThunk(
  "knowledge_base/knowledge_base_list",
  async ({ projectId, page_size = "10", page = "1" }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `/knowledgebases/v1/knowledgebases?project_id=${projectId}&page=${page}&page_size=${page_size}`
      );

      return { list: data.knowledgebases, metadata: data.pagination_meta };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createKnowledgeBase = createAsyncThunk(
  "knowledge_base/create_knowledge_base",
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `/knowledgebases/v1/knowledgebase`,
        payload
      );

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getKnowledgeBase = createAsyncThunk(
  "knowledge_base/get_knowledge_base",
  async (knowledgeBaseId, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `/knowledgebases/v1/knowledgebase/${knowledgeBaseId}`
      );
      return data.knowledgebase;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteKnowledgeBase = createAsyncThunk(
  "knowledge_base/delete_knowledge_base",
  async (knowledgeBaseId, { rejectWithValue }) => {
    try {
      await axios.delete(`/knowledgebases/v1/knowledgebase/${knowledgeBaseId}`);
      return knowledgeBaseId;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateKnowledgeBase = createAsyncThunk(
  "knowledge_base/update_knowledge_base",
  async ({ knowledgeBaseId, payload }, { rejectWithValue }) => {
    try {
      const { data } = await axios.patch(
        `/knowledgebases/v1/knowledgebase/${knowledgeBaseId}`,
        payload
      );

      return data.knowledgebase;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

/* -------------------------------------------------------------------------- */
/*                                    FILES                                   */
/* -------------------------------------------------------------------------- */
export const getKnowledgeBaseFiles = createAsyncThunk(
  "knowledge_base/knowledge_base_files",
  async (
    { knowledgeBaseId, page_size = "10", page = "1" },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await axios.get(
        `knowledgebases/v1/knowledgebase/${knowledgeBaseId}/files?page=${page}&page_size=${page_size}`
      );
      return { list: data.knowledgebase_files, metadata: data.pagination_meta };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const deleteKnowledgeBaseFile = createAsyncThunk(
  "knowledge_base/delete_knowledge_base_files",
  async ({ knowledgeBaseId, fileId }, { rejectWithValue }) => {
    try {
      await axios.delete(
        `/knowledgebases/v1/knowledgebase/${knowledgeBaseId}/files/${fileId}`
      );
      return fileId;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const uploadKnowledgeBaseFile = createAsyncThunk(
  "knowledge_base/upload_knowledge_base_file",
  async ({ knowledgeBaseId, formData }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `knowledgebases/v1/knowledgebase/file/upload?id=${knowledgeBaseId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
