import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../service/axios";

export const getDeploymentsList = createAsyncThunk(
  "deployments/get_deployments_list",
  async ({ projectId, page = 1, size = 25 }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `/deployments/v1/project/${projectId}/deployments?page=${page}&page_size=${size}`
      );
      return {
        data: data.deployments,
        metadata: data.pagination_meta,
      };
    } catch (error) {
      console.log("🚀 ~ file: deployments.js:16 ~ error:", error);
      return rejectWithValue(error);
    }
  }
);

export const createDeployment = createAsyncThunk(
  "deployments/create_deployments",
  async ({ projectId, payload }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `/deployments/v1/project/${projectId}/deployment`,
        payload
      );
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateDeployment = createAsyncThunk(
  "deployments/update_deployments",
  async ({ deploymentId, payload }, { rejectWithValue }) => {
    try {
      const { data } = await axios.patch(
        `/deployments/v1/deployment/${deploymentId}?page_size=10`,
        payload
      );
      return data.deployment;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
