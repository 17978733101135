import { LoadingOverlay } from "@mantine/core";
import { useFormik } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import Button from "../../../../../../Components/Button/Button";
import Input from "../../../../../../Components/Input/Input";
import Select from "../../../../../../Components/Select/Select";
import TextArea from "../../../../../../Components/TextArea/TextArea";
import {
  deleteDatasetById,
  getDataSetById,
  getDataSetConfig,
  updateDatasetById,
} from "../../../../../../action/dataset";

const validationSchema = Yup.object().shape({
  name: Yup.string().required().label("Project Name"),
  input_type: Yup.string().required().label("Input Type"),
  action_type: Yup.string().required().label("Action Type"),
});
const initialValues = {
  name: "",
  description: "",
  input_type: "",
  action_type: "",
};

const DatasetSetting = () => {
  const [submitting, setSubmitting] = useState(false);
  const dispatch = useDispatch();
  const { datasetId } = useParams();
  const { data } = useSelector((state) => state.dataset.dataset);
  const { categories } = useSelector((state) => state.dataset.config);

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        setSubmitting(true);
        const action = await dispatch(
          updateDatasetById({
            datasetId,
            payload: values,
          })
        );

        if (!updateDatasetById.rejected.match(action)) {
          dispatch(getDataSetById(datasetId));
        }
      } catch (error) {}
      setSubmitting(false);
    },
  });

  const {
    values,
    errors,
    touched,
    dirty,
    handleChange,
    handleSubmit,
    setFieldValue,
    setErrors,
  } = formik;

  useEffect(() => {
    const prepareState = async () => {
      dispatch(getDataSetConfig());
    };
    prepareState();
  }, [dispatch]);

  useEffect(() => {
    const prepareState = async () => {
      if (!data) return;

      setFieldValue("name", data.name);
      setFieldValue("description", data.description);
      setFieldValue("input_type", data.input_type);
      setFieldValue("action_type", data.action_type);
    };
    prepareState();
  }, [data, setErrors, setFieldValue]);

  const handleDeleteDataset = () => {
    deleteDatasetById();
  };

  const handleSelectChange = (value, name) => {
    setFieldValue(name, value);

    if (name === "input_type") setFieldValue("action_type", "");
  };

  const typeOptions = useMemo(() => {
    if (!categories) return [];

    return categories.map((value) => ({
      label: value.name,
      value: value.code,
    }));
  }, [categories]);

  const operationOptions = useMemo(() => {
    if (!categories || !values.input_type) return [];

    const selectedType = categories.find(
      (value) => value.code === values.input_type
    );
    if (selectedType && selectedType.actions) {
      return selectedType.actions.map((value) => ({
        label: value.name,
        value: value.code,
      }));
    }

    return [];
  }, [categories, values.input_type]);

  return (
    <>
      <LoadingOverlay visible={submitting} />
      <form className="" onSubmit={handleSubmit}>
        <div className="grid w-full grid-cols-2 gap-4 xl:grid-cols-1 xl:w-1/2">
          <Input
            placeholder="Enter Dataset Name"
            label="Dataset Name *"
            name="name"
            onChange={handleChange}
            value={values.name}
            error={touched.name && errors.name}
          />
          <TextArea
            placeholder="Enter Dataset Name"
            label="Dataset Description"
            name="description"
            onChange={handleChange}
            value={values.description}
            error={touched.description && errors.description}
          />
          <Select
            placeholder="Select Data Type"
            label="Data Type"
            name="input_type"
            data={typeOptions}
            value={values.input_type}
            error={touched.input_type && errors.input_type}
            onChange={(value) => handleSelectChange(value, "input_type")}
          />
          <Select
            placeholder="Select Data Type"
            label="Data Type"
            name="action_type"
            data={operationOptions}
            value={values.action_type}
            error={touched.action_type && errors.action_type}
            onChange={(value) => handleSelectChange(value, "action_type")}
          />

          <Button type="submit" width="full" disabled={!dirty}>
            SAVE CHANGES
          </Button>
          <Button intent="danger" width="full" onClick={handleDeleteDataset}>
            DELETE DATASET
          </Button>
        </div>
      </form>
    </>
  );
};

export default DatasetSetting;
