import { Button, Input, LoadingOverlay, Modal, Radio } from '@mantine/core';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getAllTags, splitDataset } from '../../../../../../action/dataset';
import Slider from '../../../../../../Components/Slider/Slider';
import axios from '../../../../../../service/axios';

const DatasetItemsSplit = forwardRef((props, ref) => {
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [opened, setOpened] = useState(false);
  const dispatch = useDispatch();
  const { projectId, datasetId } = useParams();
  const [isApiCallInProgress, setIsApiCallInProgress] = useState(false);
  const [splitPercentageValue, setSplitPercentageValue] = useState(10);
  const [splitMechanism, setSplitMechanism] = useState('random');
  const [newDatasetInputValue, setNewDatasetInputValue] = useState('');
  const { data: tags } = useSelector((state) => state.dataset.tags);
  const [selectedTags, setSelectedTags] = useState({});
  const { list: datasetList } = useSelector((state) => state.dataset);
  const { data: activeDataset } = useSelector((state) => state.dataset.dataset);
  const [isSplitButtonDisabled, setIsSplitButtonDisabled] = useState(false);
  const [createDatasetError, setCreateDatasetError] = useState(null);

  // Load all the tags
  useEffect(() => {
    dispatch(getAllTags({ datasetId }));
  }, [datasetId, dispatch]);

  useEffect(() => {
    if (
      !newDatasetInputValue ||
      (splitMechanism === 'tags' && Object.keys(selectedTags).length === 0)
    ) {
      setIsSplitButtonDisabled(true);
      return;
    }

    setIsSplitButtonDisabled(false);
  }, [newDatasetInputValue, splitMechanism, selectedTags]);

  const handleSplitItems = async () => {
    setIsApiCallInProgress(true);
    const payload = {
      project_id: Number(projectId),
      name: newDatasetInputValue,
      description: '',
      input_type: 'LANGUAGE',
      action_type: 'LANGUAGE_CONVERSATIONAL',
    };
    // TODO: temporarily commenting out this code because of some unwanted rendered causing the modal to close.
    // await dispatch(createDataSet(payload));
    let newDataset;
    try {
      const { data } = await axios.post(`/datasets/v1/dataset`, payload);
      newDataset = data;
    } catch (e) {
      setCreateDatasetError(e.message);
    }

    // TODO: VIVEK: To remove commented parameters sent to this action.
    const action = await dispatch(
      splitDataset({
        datasetId,
        targetDatasetId: newDataset.id,
        // taskId: props.taskId,
        splitType: splitMechanism,
        ...(splitMechanism === 'random' && {
          splitPercentage: splitPercentageValue,
        }),
        ...(splitMechanism === 'tags' && {
          splitTags: Object.values(selectedTags).reduce((acc, tag) => {
            if (acc) {
              return acc + ',' + tag.id;
            } else {
              return tag.id;
            }
          }, ''),
        }),
        // tags: '',
        // queries: '',
      })
    );

    if (
      splitDataset.fulfilled.match(action) ||
      splitDataset.rejected.match(action)
    ) {
      setIsApiCallInProgress(false);
      setOpened(false);
    }
  };

  const resetState = () => {
    setSelectedTags({});
    setNewDatasetInputValue('');
    setSplitPercentageValue(10);
    setSplitMechanism('random');
  };

  useImperativeHandle(ref, () => ({
    openSplitModal(_totalItems) {
      resetState();
      setTotalItems(_totalItems);
      setNewDatasetInputValue(activeDataset?.name + '-eval');
      setOpened(true);
    },
  }));

  useEffect(() => {
    if (props.filteredTags?.length > 0 || props.searchQuery) {
      setIsFilterApplied(true);
    } else {
      setIsFilterApplied(false);
    }
  }, [props.filteredTags, props.searchQuery]);

  const handleCheckAndUncheck = async (tag) => {
    if (selectedTags[tag.id]) {
      setSelectedTags((prevTags) => {
        const { [tag.id]: _, ...updatedTags } = prevTags;
        return updatedTags;
      });
    } else {
      setSelectedTags((prevTags) => ({ ...prevTags, [tag.id]: tag }));
    }
  };

  return (
    <Modal.Root
      opened={opened}
      onClose={() => setOpened(false)}
      centered
      size="480px">
      <Modal.Overlay />

      <Modal.Content>
        <Modal.Header>
          <h1 className="text-2xl font-semibold px-4 mb-2">Split</h1>
        </Modal.Header>

        <Modal.Body>
          <div className="px-4 flex flex-col gap-8">
            <p>Move items to a new dataset based on selected criteria.</p>

            {splitMechanism !== 'tags' && (
              <div className="flex flex-col gap-2">
                <div className="flex flex-row items-center justify-between">
                  <h4 className="text-md font-medium">Split Percentage</h4>
                  <div className="text-sm font-semibold tracking-wider">
                    {splitPercentageValue}%
                  </div>
                </div>
                <Slider
                  label={null}
                  min={0}
                  max={100}
                  step={5}
                  name="splitPercentage"
                  onChange={(value) => setSplitPercentageValue(value)}
                  value={splitPercentageValue}
                />
                <p className="text-sm self-end">
                  <span>Rows Affected: </span>
                  <span className="inline-block min-w-[20px]">
                    {Math.round((splitPercentageValue * totalItems) / 100)}
                  </span>
                </p>
              </div>
            )}

            <div className="flex flex-col gap-2">
              <div className="flex flex-row items-center justify-between">
                <h4 className="text-md font-medium">Split Mechanism</h4>
              </div>
              <Radio.Group
                className="flex flex-row items-center justify-between px-3 mt-2"
                value={splitMechanism}
                onChange={setSplitMechanism}>
                <Radio
                  label={`Random`}
                  value="random"
                  color="red"
                />
                <Radio
                  label="Tag"
                  value="tags"
                  color="red"
                />
                <Radio
                  disabled={true}
                  label="Distance"
                  value="distance"
                  color="red"
                />
              </Radio.Group>
            </div>

            {splitMechanism === 'tags' && (
              <div className="flex flex-col gap-2 p-2 border border-gray-30 rounded-md max-h-[200px] overflow-y-auto">
                {tags &&
                  tags.map((tag) => (
                    <div
                      onClick={() => handleCheckAndUncheck(tag)}
                      key={tag.id}
                      className={`flex flex-row gap-2 items-center hover:bg-[#f1ebf4] p-2 cursor-pointer rounded ${
                        selectedTags[tag.id]
                          ? 'bg-[#F8ECFF] text-[#8830C0]'
                          : ''
                      }`}>
                      <div
                        style={{ backgroundColor: tag.color || 'black' }}
                        className="w-4 h-4 rounded"></div>
                      <div>{tag.label}</div>
                      <div className="flex-1 min-h-[24px]"></div>
                    </div>
                  ))}
              </div>
            )}

            <div className="flex flex-col gap-2">
              <div className="flex flex-row items-center justify-between">
                <h4 className="text-md font-medium">New Dataset Name</h4>
              </div>
              <Input
                value={newDatasetInputValue}
                placeholder="Enter new dataset name"
                onChange={(e) => setNewDatasetInputValue(e.target.value)}
              />
            </div>

            <div className="flex flex-row justify-end items-center gap-6 px-4">
              <Button
                uppercase
                variant="subtle"
                color="dark"
                onClick={() => setOpened(false)}>
                CANCEL
              </Button>
              <Button
                uppercase
                disabled={isSplitButtonDisabled}
                onClick={() => handleSplitItems()}
                styles={(theme) => ({
                  root: {
                    backgroundColor: '#fa5252 !important',
                  },
                })}
                color="red">
                SPLIT
              </Button>
            </div>
          </div>
          <LoadingOverlay
            visible={isApiCallInProgress === true}
            overlayBlur={1}
          />
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
});

export default DatasetItemsSplit;
