import { Loader } from "@mantine/core";
import { cva } from "class-variance-authority";
import React from "react";
import { Link } from "react-router-dom";

const button = cva("px-6 border-2 font-bold text-xs rounded-md", {
  variants: {
    intent: {
      primary: "bg-black text-white  border-black",
      disabled:
        "bg-[#DBDBDB] text-[#929292]  border-[#CBCBCB] cursor-not-allowed",
      danger:
        "bg-venetian-red-200 text-venetian-red-500 border-venetian-red-500",
      success: "bg-success-200 text-success-500 border-success-500",
      transparent: "bg-transparent border-transparent",
      outlined: "px-6 py-2 border-2 border-gray-800",
      outlined_dark: "px-6 py-2 border-2 border-gray-800",
      ghost: "bg-transparent border-none",
      danger_ghost:
        "bg-transparent text-[#EA6669] border-transparent transition-all duration-300 hover:bg-[#EA6669] hover:bg-opacity-10",
    },
    align: {
      left: "text-left",
      center: "text-center flex justify-center items-center",
      right: "text-right",
    },
    size: {
      none: "px-0 py-0",
      xs: "p-1",
      small: "py-[6px] px-1",
      medium: "py-2",
      large: "py-3",
      loading: "py-3",
    },
    width: {
      fit: "w-fit",
      full: "w-full",
    },
    disabled: {
      true: "cursor-not-allowed opacity-50",
    },
  },
  compoundVariants: [
    {
      intent: "disabled",
      size: "loading",
    },
    { intent: "ghost", disabled: true },
  ],
  defaultVariants: {
    intent: "primary",
    size: "medium",
    width: "fit",
    align: "center",
  },
});

const Button = ({
  children,
  type = "button",
  intent,
  size,
  width,
  isLoading = false,
  href = "",
  onClick,
  disabled,
  state = {},
  ...props
}) => {
  if (href)
    return (
      <Link
        to={href}
        className={button({
          intent,
          size: isLoading ? "loading" : size,
          width,
          disabled,
        })}
        state={state}
      >
        {isLoading ? (
          <Loader color="red" variant="dots" size={"xs"} />
        ) : (
          children
        )}
      </Link>
    );

  return (
    <button
      type={type}
      className={button({
        intent,
        size: isLoading ? "loading" : size,
        width,
        disabled,
      })}
      disabled={disabled}
      onClick={onClick}
    >
      {isLoading ? <Loader color="red" variant="dots" size={"xs"} /> : children}
    </button>
  );
};

export default Button;
