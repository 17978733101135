import { Navigate, createBrowserRouter } from "react-router-dom";
import DataSetImport from "../Compositions/Dataset/DataSetImport/DataSetImport";
import { PostAuthLayout, PreAuthLayout } from "../Views/Layout/index";
import ChatPublic from "../Views/Pages/ChatPublic/ChatPublic";

import NotValidDataSet from "../Compositions/Dataset/NotValidDataSet/NotValidDataSet";

import {
  default as LabelStudio,
  default as LabelStudioReact,
} from "../Views/Pages/Labelling/Labelling";
import DatasetDetail from "../Views/Pages/Project/DataSet/DatasetDetail/DatasetDetail";
import DatasetItems from "../Views/Pages/Project/DataSet/DatasetDetail/DatasetItems/DatasetItems";
import DatasetItemsLayout from "../Views/Pages/Project/DataSet/DatasetDetail/DatasetItems/DatasetItemsLayout";
import DatasetItemsNewItemConversation from "../Views/Pages/Project/DataSet/DatasetDetail/DatasetItems/DatasetItemsNewItemConversation";
import DatasetItemsNewItemPromptResponsePair from "../Views/Pages/Project/DataSet/DatasetDetail/DatasetItems/DatasetItemsNewItemPromptResponsePair";
import DatasetItemsNoItemsPage from "../Views/Pages/Project/DataSet/DatasetDetail/DatasetItems/DatasetItemsNoItemsPage";
import DatasetSetting from "../Views/Pages/Project/DataSet/DatasetDetail/DatasetSetting/DatasetSetting";
import VersionDetail from "../Views/Pages/Project/Models/Model/Version/VersionDetail/VersionDetail";
import {
  AllProjects,
  Allset,
  ApiKey,
  Billing,
  BillingSideBar,
  CreateModel,
  CreateProject,
  CreateTeam,
  CreateVersion,
  Dashboard,
  DataSetCreate,
  Dataset,
  DatasetConnectToCloud,
  DatasetFileMapping,
  DatasetImportPip,
  DatasetList,
  DatasetProgress,
  DatasetStart,
  DatasetSteps,
  DatasetUploadFile,
  Demo,
  DeploymentCreate,
  Deployments,
  DeploymentsList,
  Home,
  Invitation,
  KnowledgeBase,
  KnowledgeBaseCreate,
  KnowledgeBaseFiles,
  KnowledgeBaseFilesUpload,
  KnowledgeBaseList,
  KnowledgeBaseSettings,
  KnowledgeBaseTab,
  Login,
  Members,
  Model,
  ModelAPI,
  ModelList,
  ModelSetting,
  Models,
  Profile,
  Project,
  ProjectSettings,
  ProtectedRoute,
  ResetPassword,
  ResetPasswordTrigger,
  Settings,
  Signup,
  Support,
  Team,
  TeamList,
  TeamSettings,
  Teams,
  UpdateProject,
  UpdateTeam,
  VerifyOtp,
  Version,
  VersionApi,
  VersionCreateType,
  VersionDemo,
  VersionList,
  VersionOverview,
  VersionSetting,
} from "../Views/Pages/index";

const router = createBrowserRouter([
  {
    path: "/labelling",
    element: <LabelStudioReact />,
  },
  {
    path: "/team/invitation",
    element: <Invitation />,
  },
  {
    path: "/",
    element: <PreAuthLayout />,
    children: [
      {
        path: "/",
        element: <Navigate to="/login" />,
      },
      {
        path: "/login",
        index: true,
        element: <Login />,
      },
      {
        path: "/signup",
        element: <Signup />,
      },
      {
        path: "/verify-otp",
        element: <VerifyOtp />,
      },
      {
        path: "/all-set",
        element: <Allset />,
      },
      {
        path: "/reset-password-trigger",
        element: <ResetPasswordTrigger />,
      },
      {
        path: "/reset-password/:token",
        element: <ResetPassword />,
      },
      {
        path: "*",
        element: <Navigate to="/" />,
      },
    ],
  },
  {
    path: "/public/chat",
    element: <ChatPublic />,
  },
  {
    path: "/",
    element: (
      <ProtectedRoute>
        <PostAuthLayout />
      </ProtectedRoute>
    ),
    children: [
      {
        path: "/projects",
        element: <Home />,
        children: [
          {
            index: true,
            path: "/projects/all",
            element: <AllProjects />,
          },

          {
            path: "/projects/create",
            element: <CreateProject />,
          },
        ],
      },
      {
        path: "/project/:projectId",
        element: <Project />,
        children: [
          {
            index: true,
            path: "/project/:projectId/dashboard",
            element: <Dashboard />,
          },
          {
            path: "/project/:projectId/models",
            element: <Models />,
            children: [
              {
                index: true,
                path: "/project/:projectId/models/list",
                element: <ModelList />,
              },
              {
                path: "/project/:projectId/models/create",
                element: <CreateModel />,
              },
              {
                path: "/project/:projectId/models/:modelId",
                element: <Model />,
                children: [
                  {
                    path: "/project/:projectId/models/:modelId/version",
                    element: <Version />,
                    children: [
                      {
                        path: "/project/:projectId/models/:modelId/version",
                        element: <VersionList />,
                      },
                      {
                        path: "/project/:projectId/models/:modelId/version/create/type",
                        element: <VersionCreateType />,
                      },
                    ],
                  },
                  {
                    path: "/project/:projectId/models/:modelId/demo",
                    element: <Demo />,
                  },
                  {
                    path: "/project/:projectId/models/:modelId/api",
                    element: <ModelAPI />,
                  },
                  {
                    path: "/project/:projectId/models/:modelId/setting",
                    element: <ModelSetting />,
                  },
                ],
              },
              {
                path: "/project/:projectId/models/:modelId/version/:versionId",
                element: <VersionDetail />,
                children: [
                  {
                    path: "/project/:projectId/models/:modelId/version/:versionId/overview",
                    element: <VersionOverview />,
                  },
                  {
                    path: "/project/:projectId/models/:modelId/version/:versionId/demo",
                    element: <VersionDemo />,
                  },
                  {
                    path: "/project/:projectId/models/:modelId/version/:versionId/api",
                    element: <VersionApi />,
                  },
                  {
                    path: "/project/:projectId/models/:modelId/version/:versionId/setting",
                    element: <VersionSetting />,
                  },
                ],
              },
            ],
          },
          {
            path: "/project/:projectId/models/:modelId/version/create",
            element: <CreateVersion />,
          },
          {
            path: "/project/:projectId/dataset",
            element: <Dataset />,
            children: [
              {
                index: true,
                path: "/project/:projectId/dataset/list",
                element: <DatasetList />,
              },
              {
                path: "/project/:projectId/dataset/create",
                element: <DataSetCreate />,
                children: [
                  {
                    path: "/project/:projectId/dataset/create/start",
                    element: <DatasetStart />,
                  },
                  {
                    path: "/project/:projectId/dataset/create/import-existing",
                    element: <DataSetImport />,
                  },
                  {
                    path: "/project/:projectId/dataset/create/start/step",
                    element: <DatasetSteps />,
                  },
                  {
                    path: "/project/:projectId/dataset/create/:datasetId/upload",
                    element: <DatasetUploadFile />,
                  },
                  {
                    path: "/project/:projectId/dataset/create/:datasetId/import",
                    element: <DatasetImportPip />,
                  },
                  {
                    path: "/project/:projectId/dataset/create/:datasetId/mapping",
                    element: <DatasetFileMapping />,
                  },
                  {
                    path: "/project/:projectId/dataset/create/:datasetId/cloud",
                    element: <DatasetConnectToCloud />,
                  },
                  {
                    path: "/project/:projectId/dataset/create/:datasetId/progress",
                    element: <DatasetProgress />,
                  },
                  {
                    path: "/project/:projectId/dataset/create/:datasetId/not-valid",
                    element: <NotValidDataSet />,
                  },
                ],
              },
              {
                path: "/project/:projectId/dataset/:datasetId",
                element: <DatasetDetail />,
                children: [
                  /* {
                    index: true,
                    path: '/project/:projectId/dataset/:datasetId/data',
                    element: <DatasetData />,
                  }, */
                  {
                    path: "/project/:projectId/dataset/:datasetId/items",
                    element: <DatasetItemsLayout />,
                    children: [
                      {
                        path: "/project/:projectId/dataset/:datasetId/items",
                        element: <DatasetItems />,
                      },
                      {
                        path: "/project/:projectId/dataset/:datasetId/items/no-item-page",
                        element: <DatasetItemsNoItemsPage />,
                      },
                      {
                        path: "/project/:projectId/dataset/:datasetId/items/new-prompt-response-pair",
                        element: <DatasetItemsNewItemPromptResponsePair />,
                      },
                      {
                        path: "/project/:projectId/dataset/:datasetId/items/new-conversation",
                        element: <DatasetItemsNewItemConversation />,
                      },
                    ],
                  },
                  {
                    path: "/project/:projectId/dataset/:datasetId/settings",
                    element: <DatasetSetting />,
                  },
                ],
              },
              {
                path: "/project/:projectId/dataset/*",
                element: <Navigate to="/project/:projectId/dataset/list" />,
              },
            ],
          },

          {
            path: "/project/:projectId/api-key",
            element: <ApiKey />,
          },
          {
            path: "/project/:projectId/settings",
            element: <ProjectSettings />,
          },
          {
            path: "/project/:projectId/deployments",
            element: <Deployments />,
            children: [
              {
                path: "/project/:projectId/deployments/list",
                element: <DeploymentsList />,
              },
              {
                path: "/project/:projectId/deployments/create",
                element: <DeploymentCreate />,
              },
            ],
          },
          {
            path: "/project/:projectId/knowledge-base",
            element: <KnowledgeBase />,
            children: [
              {
                path: "/project/:projectId/knowledge-base/list",
                element: <KnowledgeBaseList />,
              },
              {
                path: "/project/:projectId/knowledge-base/create",
                element: <KnowledgeBaseCreate />,
              },
              {
                path: "/project/:projectId/knowledge-base/:knowledgeBaseId",
                element: <KnowledgeBaseTab />,
                children: [
                  {
                    // index: true,
                    path: "/project/:projectId/knowledge-base/:knowledgeBaseId/files",
                    element: <KnowledgeBaseFiles />,
                  },
                  {
                    path: "/project/:projectId/knowledge-base/:knowledgeBaseId/settings",
                    element: <KnowledgeBaseSettings />,
                  },
                ],
              },

              {
                path: "/project/:projectId/knowledge-base/:knowledgeBaseId/files/upload",
                element: <KnowledgeBaseFilesUpload />,
              },
            ],
          },
        ],
      },
      {
        path: "/project/update/:projectId",
        element: <UpdateProject />,
      },
      {
        path: "/settings",
        element: <Settings />,
        children: [
          {
            path: "/settings/profile",
            element: <Profile />,
          },
          {
            path: "/settings/teams",
            element: <Teams />,
            children: [
              {
                index: true,
                path: "/settings/teams/list",
                element: <TeamList />,
              },
              {
                path: "/settings/teams/create",
                element: <CreateTeam />,
              },
              {
                path: "/settings/teams/:teamId/update",
                element: <UpdateTeam />,
              },
              {
                path: "/settings/teams/:teamId",
                element: <Team />,
                children: [
                  {
                    index: true,
                    path: "/settings/teams/:teamId/members",
                    element: <Members />,
                  },
                  {
                    path: "/settings/teams/:teamId/billing",
                    element: <Billing />,
                  },
                  {
                    path: "/settings/teams/:teamId/settings",
                    element: <TeamSettings />,
                  },
                ],
              },
              {
                path: "*",
                element: <Navigate to="/settings/teams/list" />,
              },
            ],
          },
          {
            path: "/settings/billing",
            element: <BillingSideBar />,
          },
          // {
          //   path: "/settings/teams/:teamId",
          //   element: <Team />,
          //   children: [
          //     {
          //       path: "/settings/teams/:teamId/members",
          //       element: <Members />,
          //     },
          //   ],
          // },
          {
            path: "/settings/support",
            element: <Support />,
          },
        ],
      },
    ],
  },
  {
    path: "/project/:projectId/dataset/:datasetId/task/:taskId/labelling",
    element: (
      <ProtectedRoute>
        <LabelStudio />
      </ProtectedRoute>
    ),
  },
]);

export default router;
