import { Button, Input, LoadingOverlay, Modal, TextInput } from '@mantine/core';
import { IconSearch } from '@tabler/icons-react';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { copyDataset, getDataSetList } from '../../../../../../action/dataset';
import icons from '../../../../../../Assets/icons/icons';
import axios from '../../../../../../service/axios';

const DatasetItemsCopy = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const [opened, setOpened] = useState(false);
  const { list: datasetList, isLoading } = useSelector(
    (state) => state.dataset
  );
  const [totalItems, setTotalItems] = useState(0);
  const debounceRef = useRef(null);
  const { projectId, datasetId } = useParams();
  const [selectedDataset, setSelectedDataset] = useState(null);
  const [creatingNewDataset, setCreatingNewDataset] = useState(false);
  const [newDatasetInputValue, setNewDatasetInputValue] = useState('');
  const [copyApiInProgress, setCopyApiInProgress] = useState(false);

  useEffect(() => {
    dispatch(getDataSetList({ projectId }));
  }, [dispatch, projectId]);

  const handleInputChange = (e) => {
    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }
    debounceRef.current = setTimeout(() => {}, 300);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      if (debounceRef.current) {
        clearTimeout(debounceRef.current);
      }
    }
  };

  const handleDatasetSelection = (dataset) => {
    if (selectedDataset && selectedDataset.id === dataset.id) {
      setSelectedDataset(null);
    } else {
      setSelectedDataset(dataset);
    }
  };

  const handleCopy = async () => {
    setCopyApiInProgress(true);
    const action = await dispatch(
      copyDataset({
        datasetId,
        targetDatasetId: selectedDataset.id,
        // taskId: props.taskId,
        queries: props.searchQuery,
        tags: Object.keys(props.filteredTags).join(','),
      })
    );
    setCopyApiInProgress(false);
    if (
      copyDataset.fulfilled.match(action) ||
      copyDataset.rejected.match(action)
    ) {
      closeCopyModal();
    }
  };

  const handleCreateNewDataset = async () => {
    const payload = {
      project_id: Number(projectId),
      name: newDatasetInputValue,
      description: '',
      input_type: 'LANGUAGE',
      action_type: 'LANGUAGE_CONVERSATIONAL',
    };
    // const action = await dispatch(createDataSet(payload));
    // if (
    //   createDataSet.fulfilled.match(action) ||
    //   createDataSet.rejected.match(action)
    // ) {
    // }
    // TODO: temporarily commenting out this code because of some unwanted rendered causing the modal to close.
    let newDataset;
    try {
      const { data } = await axios.post(`/datasets/v1/dataset`, payload);
      newDataset = data;
      setCreatingNewDataset(false);
      dispatch(getDataSetList({ projectId }));
    } catch (e) {}
  };

  useImperativeHandle(ref, () => ({
    openCopyModal(_totalItems) {
      setTotalItems(_totalItems);
      setOpened(true);
    },
  }));

  const resetCopyModal = () => {
    setCreatingNewDataset(false);
    setNewDatasetInputValue('');
    setSelectedDataset(null);
  };

  const closeCopyModal = () => {
    resetCopyModal();
    setOpened(false);
  };

  return (
    <Modal.Root
      opened={opened}
      onClose={() => setOpened(false)}
      centered
      size="md">
      <Modal.Overlay />

      <Modal.Content>
        <Modal.Header>
          <h1 className="text-xl font-medium">
            Copy <b>{totalItems} items</b> to
          </h1>
        </Modal.Header>
        <Modal.Body>
          <LoadingOverlay
            visible={isLoading}
            overlayBlur={1}
          />
          <div className="flex flex-col w-full gap-4">
            <TextInput
              className="min-w-[400px]"
              icon={<IconSearch />}
              placeholder="Search"
              radius="md"
              size="md"
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
            />
            <div className="flex flex-col gap-2 p-2 border border-gray-30 rounded-md max-h-[200px] overflow-y-auto">
              {datasetList &&
                datasetList.map((ds) => {
                  return (
                    <div
                      key={ds.id}
                      onClick={() => handleDatasetSelection(ds)}
                      className={`rounded-md px-4 py-1 cursor-pointer ${
                        selectedDataset && selectedDataset.id === ds.id
                          ? 'bg-[#F8ECFF] text-[#8830C0]'
                          : ''
                      }`}>
                      <div>{ds.name}</div>
                      <div className="text-gray-400 text-xs pl-1">
                        {ds.description}
                      </div>
                    </div>
                  );
                })}
            </div>

            <div className="flex-1"></div>

            <div>
              {creatingNewDataset ? (
                <div>
                  <Input
                    value={newDatasetInputValue}
                    placeholder="Enter name of new dataset"
                    onChange={(e) => setNewDatasetInputValue(e.target.value)}
                  />
                  <div className="flex flex-row justify-end items-center gap-4 mt-2 px-1">
                    <button
                      className="bg-green-200 p-2 rounded-full"
                      onClick={() => handleCreateNewDataset()}>
                      <img
                        src={icons.check}
                        alt="Rename tag"
                        className="w-4 h-4"
                      />
                    </button>
                    <button
                      className="bg-red-200 p-2 rounded-full"
                      onClick={() => {
                        setCreatingNewDataset(false);
                        setNewDatasetInputValue('');
                      }}>
                      <img
                        src={icons.close}
                        alt="Cancel rename tag"
                        className="w-4 h-4"
                      />
                    </button>
                  </div>
                </div>
              ) : (
                <Button
                  variant="subtle"
                  onClick={() => setCreatingNewDataset(true)}
                  styles={(theme) => ({
                    root: {
                      color: '#8830C0 !important',
                    },
                  })}>
                  + New Dataset
                </Button>
              )}
            </div>

            <div className="flex flex-row justify-end items-center gap-6 px-4 mt-2">
              <Button
                uppercase
                variant="subtle"
                styles={(theme) => ({
                  root: {
                    color: '#EA6669 !important',
                  },
                })}
                onClick={() => closeCopyModal()}>
                CANCEL
              </Button>
              <Button
                disabled={!selectedDataset}
                uppercase
                onClick={() => handleCopy()}
                loading={copyApiInProgress}
                styles={(theme) => ({
                  root: {
                    backgroundColor: '#000 !important',
                  },
                })}
                color="red">
                YES
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
});

export default DatasetItemsCopy;
