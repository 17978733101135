import { createSlice } from "@reduxjs/toolkit";
import {
  deleteModelById,
  getModelById,
  getModelsList,
  getPublicModelsList,
} from "../action/model";

const initialState = {
  isLoading: true,
  models: null,
  error: null,
  model: {
    isLoading: false,
    data: null,
    error: null,
  },
  publicModel: {
    isLoading: false,
    list: null,
    metadata: { page_size: 0, page: 0, total: 0, total_pages: 0 },
    error: null,
  },
};

const modelSlice = createSlice({
  name: "model",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    /* -------------------------------------------------------------------------- */
    /*                               GET ALL MODELS                               */
    /* -------------------------------------------------------------------------- */
    builder.addCase(getModelsList.pending, (state) => {
      state.isLoading = true;
      state.models = null;
      state.error = null;
    });
    builder.addCase(getModelsList.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.models = payload;
    });

    builder.addCase(getModelsList.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.models = null;
      state.error = payload;
    });

    /* -------------------------------------------------------------------------- */
    /*                             DELETE MODEL BY ID                             */
    /* -------------------------------------------------------------------------- */
    builder.addCase(deleteModelById.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteModelById.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.models = state.models.filter((model) => model.id !== payload);
    });

    builder.addCase(deleteModelById.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    });

    /* -------------------------------------------------------------------------- */
    /*                               GET MODEL BY ID                              */
    /* -------------------------------------------------------------------------- */

    builder.addCase(getModelById.pending, (state, { payload }) => {
      state.model.isLoading = true;
      state.model.data = null;
      state.model.error = null;
    });
    builder.addCase(getModelById.fulfilled, (state, { payload }) => {
      state.model.isLoading = false;
      state.model.data = payload;
    });

    builder.addCase(getModelById.rejected, (state, { payload }) => {
      state.model.isLoading = false;
      state.model.data = null;
      state.model.error = payload;
    });

    /* -------------------------------------------------------------------------- */
    /*                              GET PUBLIC MODEL                              */
    /* -------------------------------------------------------------------------- */
    builder.addCase(getPublicModelsList.pending, (state, { payload }) => {
      state.publicModel.isLoading = true;
      state.publicModel.list = null;
      state.publicModel.error = null;
    });
    builder.addCase(getPublicModelsList.fulfilled, (state, { payload }) => {
      state.publicModel.isLoading = false;
      state.publicModel.list = payload;
      state.publicModel.metadata = payload.metadata;
    });

    builder.addCase(getPublicModelsList.rejected, (state, { payload }) => {
      state.publicModel.isLoading = false;
      state.publicModel.list = null;
      state.publicModel.error = payload;
    });
  },
});

// export const {} = modelSlice.actions;

export default modelSlice.reducer;
