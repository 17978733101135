import apiKeyEmpty from "./api_key_empty.svg";
import dataSetEmpty from "./datasets_empty.svg";
import dummyProfile from "./dummy_profile.svg";
import knowledgeBaseEmpty from "./knowledge_base_empty.svg";
import modelsEmpty from "./models_empty.svg";
import profilePlaceholder from "./profile-placeholder.png";
import projectEmpty from "./project_empty.svg";
import teamPlaceholder from "./team-placeholder.jpg";
import userDummy from "./user_dummy.svg";

const icons = {
  projectEmpty,
  modelsEmpty,
  apiKeyEmpty,
  dummyProfile,
  userDummy,
  dataSetEmpty,
  profilePlaceholder,
  teamPlaceholder,
  knowledgeBaseEmpty,
};
export default icons;
