import { Select as MantineSelect, createStyles } from "@mantine/core";
import React from "react";
import images from "../../Assets/images";
import Loader from "../Loader/Loader";
import Title from "../Title/Title";

const Select = ({
  parentClass = "",
  error,
  name = "",
  label = "",
  inputClass = "",
  isLoading = false,
  isPro = false,
  ...props
}) => {
  const { classes } = useStyles();

  if (isLoading) return <Loader height="fit" />;

  return (
    <div className={`input_container relative pb-5 ${parentClass}`}>
      <div className="flex items-center justify-start gap-2 w-fit">
        {label && (
          <label htmlFor={name} className="font-[500]">
            {label}
          </label>
        )}
        {isPro && <Title className="text-xs text-shadow">PRO</Title>}
      </div>
      <MantineSelect
        className={classes.select}
        placeholder="Pick one"
        data={[{ value: "S3", label: "AWS S3" }]}
        rightSection={<img src={images.icons.sortDown} alt="drop down icon" />}
        {...props}
      />
      {error && (
        <div className="absolute bottom-0 text-xs text-red-500 error">
          {error}
        </div>
      )}
    </div>
  );
};
export default Select;

const useStyles = createStyles((theme) => ({
  select: {
    ".mantine-Input-wrapper": {
      input: {
        paddingTop: "3px",
        paddingBottom: "3px",
        height: "auto",
        fontFamily: `"DM Sans", sans-serif`,
        borderColor: "#ECECEC",
        borderWidth: "1px",
        borderRadius: "6px",

        "&:focus, &:focus-within": {
          borderColor: "#000 !important",
        },
      },
    },
  },
}));
