import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../service/axios";

export const getProjects = createAsyncThunk(
  "project/projectsList",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`/projects/v1/projects`);

      return data.projects;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createProject = async (payload) => {
  try {
    const { data } = await axios.post(`/projects/v1/project`, payload);

    return data;
  } catch (error) {
    return { error: true };
  }
};

/* -------------------------------------------------------------------------- */
/*                              GET PROJECT BY ID                             */
/* -------------------------------------------------------------------------- */
export const getProjectById = createAsyncThunk(
  "project/project_by_id",
  async (projectId, { rejectWithValue }) => {
    if (!projectId)
      return rejectWithValue({ message: "Project Id is required" });
    let pid = null;
    try {
      pid = parseInt(projectId);
    } catch (e) {
      return rejectWithValue({ message: "Project Id is required" });
    }

    if (isNaN(pid))
      return rejectWithValue({ message: "Project Id is required" });
    try {
      const { data } = await axios.get(`/projects/v1/project/${pid}`);

      return data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

/* -------------------------------------------------------------------------- */
/*                            DELETE PROJECT BY ID                            */
/* -------------------------------------------------------------------------- */

export const deleteProjectById = createAsyncThunk(
  "project/project_delete_by_id",
  async (projectId, { rejectWithValue }) => {
    try {
      const { data } = await axios.delete(`/projects/v1/project/${projectId}`);
      return projectId;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

/* -------------------------------------------------------------------------- */
/*                               UPDATE PROJECT                               */
/* -------------------------------------------------------------------------- */
export const updateProject = createAsyncThunk(
  "project/update",
  async ({ projectId, payload }, { rejectWithValue }) => {
    try {
      const { data } = await axios.patch(
        `/projects/v1/project/${projectId}`,
        payload
      );

      return data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
