import { createSlice } from "@reduxjs/toolkit";
import {
  deleteKnowledgeBase,
  getKnowledgeBase,
  getKnowledgeBaseFiles,
  getKnowledgeBaseList,
  updateKnowledgeBase,
} from "../action/knowledgeBase";

const initialState = {
  isLoading: false,
  list: null,
  error: null,
  metadata: {},
  knowledgeBase: {
    isLoading: false,
    data: null,
    error: null,
  },
  files: {
    isLoading: false,
    list: null,
    metadata: {},
    error: null,
  },
};

const knowledgeBaseSlice = createSlice({
  name: "knowledge_base",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    /* -------------------------------------------------------------------------- */
    /*                                 GET ALL KB                                 */
    /* -------------------------------------------------------------------------- */
    builder.addCase(getKnowledgeBaseList.pending, (state, { payload }) => {
      state.isLoading = true;
      state.list = null;
      state.error = null;
    });
    builder.addCase(getKnowledgeBaseList.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.list = payload.list;
      state.metadata = payload.metadata;
    });

    builder.addCase(getKnowledgeBaseList.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.list = null;
      state.error = payload;
    });

    /* -------------------------------------------------------------------------- */
    /*                                GET KB BY ID                                */
    /* -------------------------------------------------------------------------- */
    builder.addCase(getKnowledgeBase.pending, (state, { payload }) => {
      state.knowledgeBase.isLoading = true;
      state.knowledgeBase.data = null;
      state.knowledgeBase.error = null;
    });
    builder.addCase(getKnowledgeBase.fulfilled, (state, { payload }) => {
      state.knowledgeBase.isLoading = false;
      state.knowledgeBase.data = payload;
    });

    builder.addCase(getKnowledgeBase.rejected, (state, { payload }) => {
      state.knowledgeBase.isLoading = false;
      state.knowledgeBase.data = null;
      state.knowledgeBase.error = payload;
    });

    /* -------------------------------------------------------------------------- */
    /*                               DELETE KB BY ID                              */
    /* -------------------------------------------------------------------------- */
    builder.addCase(deleteKnowledgeBase.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteKnowledgeBase.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.list = state.list.filter((kb) => kb.id !== payload);
    });

    builder.addCase(deleteKnowledgeBase.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    });

    /* -------------------------------------------------------------------------- */
    /*                               UPDATE KB BY ID                              */
    /* -------------------------------------------------------------------------- */

    builder.addCase(updateKnowledgeBase.pending, (state) => {
      state.knowledgeBase.isLoading = true;
    });
    builder.addCase(updateKnowledgeBase.fulfilled, (state, { payload }) => {
      state.knowledgeBase.isLoading = false;
      state.knowledgeBase.data = payload;
    });

    builder.addCase(updateKnowledgeBase.rejected, (state, { payload }) => {
      state.knowledgeBase.isLoading = false;
      state.knowledgeBase.error = payload;
    });

    /* -------------------------------------------------------------------------- */
    /*                                GET KB FILES                                */
    /* -------------------------------------------------------------------------- */
    builder.addCase(getKnowledgeBaseFiles.pending, (state, { payload }) => {
      state.files.isLoading = true;
      state.files.list = null;
      state.files.error = null;
    });
    builder.addCase(getKnowledgeBaseFiles.fulfilled, (state, { payload }) => {
      state.files.isLoading = false;
      state.files.list = payload.list;
      state.files.metadata = payload.metadata;
    });

    builder.addCase(getKnowledgeBaseFiles.rejected, (state, { payload }) => {
      state.files.isLoading = false;
      state.files.list = null;
      state.files.error = payload;
    });
  },
});

// export const {} = knowledgeBaseSlice.actions;

export default knowledgeBaseSlice.reducer;
