import React from "react";
import FileUpload from "../../../Components/FileUpload/FileUpload";
import Input from "../../../Components/Input/Input";

const Form = ({ form, handleSubmit }) => {
  const { errors } = form;

  return (
    <>
      <div className="text-center">
        <h3 className="my-4 text-3xl font-bold">Upload your Propulsion JSON</h3>
        <p className="w-2/3 mx-auto text-sm mb-9">
          Use the{" "}
          <span className="font-bold underline">
            Propulsion Dataset Converter
          </span>{" "}
          library to convert your dataset format into Propulsion JSON
        </p>
      </div>
      <form
        className="w-3/4 mx-auto mb-9"
        onSubmit={form.onSubmit(handleSubmit)}
      >
        <div className="grid grid-cols-1 gap-4">
          <Input
            placeholder="Dataset Name"
            label="Dataset Name"
            name="name"
            {...form.getInputProps("name")}
            error={errors.name}
          />
          <FileUpload
            placeholder="Upload or Specify JSON path"
            name="file"
            // {...form.getInputProps("file")}
            error={errors.file}
          />
          <button
            type="submit"
            className="bg-gray-800 py-[10px] text-white text-sm rounded-md  w-full"
          >
            UPLOAD
          </button>
        </div>
      </form>
      <p className="text-sm text-[#BC7100] text-center">
        Please ensure the JSON contains pre-signed URLs for file paths.
      </p>
    </>
  );
};

export default Form;
