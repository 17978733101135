// import LabelStudio from "@heartexlabs/label-studio";
// import "@heartexlabs/label-studio/build/static/css/main.css";
import React, { useLayoutEffect, useRef } from "react";
import { ErrorBoundary } from "react-error-boundary";

function ErrorFallback() {
  return (
    <div>
      <h2>Something went wrong.</h2>
      <p>Please try again later.</p>
    </div>
  );
}

const LabelStudioReact = (props) => {
  const labelStudioContainerRef = useRef();
  const labelStudioRef = useRef();
  let date = Date.now().toString();

  useLayoutEffect(() => {
    const prepareState = async () => {
      const initLabelStudio = () => {
        date = Date.now().toString();
        //  setDate(Date.now().toString())
        labelStudioRef.current.on("labelStudioLoad", (LS) => {
          // Perform an action when Label Studio is loaded
          const c = LS.annotationStore.addAnnotation({
            userGenerate: true,
          });
          LS.annotationStore.selectAnnotation(c.id);
        });

        labelStudioRef.current.on("submitAnnotation", (LS, annotation) => {
          console.log(
            "🚀 ~ file: Studio.jsx:35 ~ labelStudioRef.current.on ~ annotation:",
            annotation
          );
          console.log(annotation.serializeAnnotation());
          props.onTaskComplete(annotation.serializeAnnotation());
        });
        labelStudioRef.current.on("updateAnnotation", (LS, annotation) => {
          console.log(
            "🚀 ~ file: Studio.jsx:43 ~ labelStudioRef.current.on ~ annotation:",
            annotation
          );

          console.log(annotation.serializeAnnotation());
          props.onTaskComplete(annotation.serializeAnnotation());
        });
        labelStudioRef.current.on("skipTask", (LS, annotation) => {
          props.onTaskComplete(annotation.serializeAnnotation());
        });
      };

      // if (labelStudioContainerRef.current && !labelStudioRef.current) {
      //   labelStudioRef.current = new LabelStudio(
      //     labelStudioContainerRef.current,
      //     props
      //   );
      //   initLabelStudio();
      // }
    };
    prepareState();
  }, [props]);

  console.log("🚀 ~ file: Studio.jsx:21 ~ LabelStudioReact ~ date:", date);

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <div id="label-studio" key={"lsf-studio"} ref={labelStudioContainerRef} />
    </ErrorBoundary>
  );
};

export default LabelStudioReact;
