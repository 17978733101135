import { ActionIcon, CopyButton } from "@mantine/core";
import { IconCheck, IconCopy } from "@tabler/icons-react";
import React from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useMatch, useParams } from "react-router-dom";
import TrainingStatus from "../../../Components/Status/TrainingStatus/TrainingStatus";
import Title from "../../../Components/Title/Title";
import Tabs from "../Tabs/Tabs";

const OverViewTopTab = ({ children }) => {
  const location = useLocation();
  const { projectId } = useParams();
  const { data: modelData } = useSelector((state) => state.model.model);
  const { data } = useSelector((state) => state.version.version);

  const isOnOverViewPage = useMatch(
    "/project/:projectId/models/:modelId/version/:versionId/overview"
  );

  const sections = location.pathname.split("/");
  const section = sections.slice(sections.length - 1, sections.length);

  return (
    <div>
      <div>
        <div className="text-xs bread text-[#999999]">
          <Link to={`/project/${projectId}/models/list`}>Model</Link>
          <span className="mx-1">/</span>
          <Link
            to={`/project/${projectId}/models/${modelData?.id}/version`}
            className="capitalize"
          >
            {modelData?.name}
          </Link>
          <span className="mx-1">/</span>
          <span className="capitalize">{data?.tag}</span>
          <span className="mx-1">/</span>
          <span className="capitalize">{section}</span>
        </div>
        <div className="flex flex-wrap justify-between mb-3 min-h-[36px]">
          <div className="flex items-center gap-2">
            <Title>
              <div className="flex items-center justify-start">
                <span className="text-2xl">Version Code: {data?.code}</span>
                <CopyButton value={data?.code} timeout={2000}>
                  {({ copied, copy }) => (
                    <ActionIcon
                      color={copied ? "teal" : "gray"}
                      variant="subtle"
                      onClick={copy}
                    >
                      {copied ? (
                        <IconCheck size={14} />
                      ) : (
                        <IconCopy size={14} />
                      )}
                    </ActionIcon>
                  )}
                </CopyButton>
              </div>
            </Title>
            {!isOnOverViewPage && (
              <TrainingStatus
                status={data?.training?.status ? data?.training?.status : null}
              />
            )}
          </div>
          <div className="text-xs font-bold actions">{children}</div>
        </div>
        <Tabs />
      </div>
    </div>
  );
};

export default OverViewTopTab;
