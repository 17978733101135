import { notifications } from "@mantine/notifications";
import Axios from "axios";

// const BASE_URL = "https://api.propulsionhq.com";
const BASE_URL = "https://api.nonprod.propulsionhq.com";
// const BASE_URL = "http://localhost:2999";

const axios = Axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json; charset=utf-8",
  },
});

const withOutTokenUrls = [
  "/auth/v1/login",
  "/auth/v1/signup/trigger",
  "/auth/v1/signup/complete",
];

axios.interceptors.request.use(
  (config) => {
    if (window.location.hostname === "app.propulsionhq.com") {
      config.baseURL = `https://api.propulsionhq.com`;
    } else {
      config.baseURL = `https://api.nonprod.propulsionhq.com`;
    }

    if (withOutTokenUrls.includes(config.url)) return config;

    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("user_id");

    if (token && userId) {
      if (!config.removeHeader) {
        config.headers["x-access-token"] = token;
        config.headers["x-access-user"] = userId;
      }
    }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    console.log("🚀 ~ error:", error);
    let body = {};
    if (Axios.isAxiosError(error)) {
      if (error.response) {
        const { status, data } = error.response;
        if (status === 401) {
          await localStorage.clear();
          window.location.href = "/";
        }
        body = {
          type: "api",
          status,
          message: data.message,
        };
      } else if (error.request) {
        body = { type: "network", message: "Network Error" };
      } else {
        body = { type: "network", message: "An error occurred" };
      }
    } else {
      body = { type: "network", message: "An unexpected error occurred" };
    }

    notifications.show({
      color: "red",
      message: body.message,
    });
    return Promise.reject(body);
  }
);

export default axios;
