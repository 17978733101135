import { createSlice } from "@reduxjs/toolkit";
import {
  deleteProjectById,
  getProjectById,
  getProjects,
  updateProject,
} from "../action/project";

const initialState = {
  isLoading: false,
  projects: null,
  error: null,
  selectedProject: null,
  project: {
    isLoading: false,
    data: null,
    error: null,
  },
};

const projectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    selectProject: (state, { payload }) => {
      state.selectedProject = payload;
    },
    updateProjectList: (state, { payload }) => {
      state.projects = payload;
    },
  },
  extraReducers: (builder) => {
    /* -------------------------------------------------------------------------- */
    /*                              GET PROJECT LIST                              */
    /* -------------------------------------------------------------------------- */
    builder.addCase(getProjects.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getProjects.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.projects = payload;
    });

    builder.addCase(getProjects.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    });

    /* -------------------------------------------------------------------------- */
    /*                            DELETE PROJECT BY ID                            */
    /* -------------------------------------------------------------------------- */

    builder.addCase(deleteProjectById.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteProjectById.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.projects = state.projects.filter(
        (project) => project.id !== payload
      );
    });

    builder.addCase(deleteProjectById.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    });

    /* -------------------------------------------------------------------------- */
    /*                              GET PROJECT BY ID                             */
    /* -------------------------------------------------------------------------- */

    builder.addCase(getProjectById.pending, (state, { payload }) => {
      state.project.isLoading = true;
      state.project.data = null;
      state.project.error = null;
    });
    builder.addCase(getProjectById.fulfilled, (state, { payload }) => {
      state.project.isLoading = false;
      state.project.data = payload;
    });

    builder.addCase(getProjectById.rejected, (state, { payload }) => {
      state.project.isLoading = false;
      state.project.data = null;
      state.project.error = payload;
    });

    /* -------------------------------------------------------------------------- */
    /*                             UDATE PROJECT BY ID                            */
    /* -------------------------------------------------------------------------- */
    builder.addCase(updateProject.pending, (state, { payload }) => {
      state.project.isLoading = true;
    });
    builder.addCase(updateProject.fulfilled, (state, { payload }) => {
      state.project.isLoading = false;
      state.project.data = payload;
    });

    builder.addCase(updateProject.rejected, (state, { payload }) => {
      state.project.isLoading = false;
      state.project.error = payload;
    });
  },
});

export const { selectProject, updateProjectList } = projectSlice.actions;

export default projectSlice.reducer;
