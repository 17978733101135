import React from 'react';
import { NavLink, useMatch, useParams } from 'react-router-dom';

const DatasetDetailToggle = () => {
  const { projectId, datasetId } = useParams();

  const path = `/project/${projectId}/dataset/${datasetId}`;

  const isDataActive = useMatch(`${path}/data`);
  const isItemsActive = useMatch(`${path}/items`);
  const isSettingActive = useMatch(`${path}/settings`);

  return (
    <ul className="flex gap-6 mb-6 font-medium">
      {/* <li>
        <NavLink
          to={`${path}/data`}
          className={`${isDataActive ? 'text-black' : 'text-[#999999]'}`}>
          Data
        </NavLink>
      </li> */}
      <li>
        <NavLink
          to={`${path}/items`}
          className={`${isItemsActive ? 'text-black' : 'text-[#999999]'}`}>
          Items
        </NavLink>
      </li>
      <li>
        <NavLink
          to={`${path}/settings`}
          className={`${isSettingActive ? 'text-black' : 'text-[#999999]'}`}>
          Settings
        </NavLink>
      </li>
    </ul>
  );
};

export default DatasetDetailToggle;
