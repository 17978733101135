import React from 'react'

const Header = ({ list, }) => {


    return (
        <div className="grid grid-cols-12 gap-2 px-2 pb-3 text-sm font-bold text-center">
            <div className="col-span-6">ID</div>
            <div className="col-span-6">TASK</div>
        </div>
    )
}

export default Header