import { useForm, yupResolver } from "@mantine/form";
import React, { useState } from "react";

import Form from "./Form";
import UploadPercent from "./UploadPercent";
import { initialValues, schema } from "./constants";

const DataSetImport = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const form = useForm({
    initialValues,
    validate: yupResolver(schema),
  });

  const handleSubmit = (values) => {
    console.log("values", values);
    setIsSubmitting(true);
  };

  const handleCancel = () => {
    setIsSubmitting(false);
  };

  return (
    <div className="w-2/3 mx-auto">
      {!isSubmitting ? (
        <Form form={form} handleSubmit={handleSubmit} />
      ) : (
        <UploadPercent handleCancel={handleCancel} />
      )}
    </div>
  );
};

export default DataSetImport;
