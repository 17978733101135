import { notifications } from "@mantine/notifications";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../service/axios";

/* -------------------------------------------------------------------------- */
/*                               GET ALL MODELS                               */
/* -------------------------------------------------------------------------- */
export const getVersionList = createAsyncThunk(
  "version/versions_list",
  async ({ modelId, page = "1", size = "10" }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `/models/v1/model/${modelId}/versions?page=${page}&page_size=${size}`
      );
      return { data: data.versions, metadata: data.pagination_meta };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

/* -------------------------------------------------------------------------- */
/*                               DELETE VERSION                               */
/* -------------------------------------------------------------------------- */
export const deleteVersionById = createAsyncThunk(
  "version/delete_version_by_id",
  async (versionId, { rejectWithValue, getState }) => {
    try {
      const { data } = await axios.delete(`/models/v1/version/${versionId}/`);

      return versionId;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

/* -------------------------------------------------------------------------- */
/*                              GET VERSION BY ID                             */
/* -------------------------------------------------------------------------- */
export const getVersionById = createAsyncThunk(
  "version/version_by_id",
  async (versionId, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`/models/v1/version/${versionId}`);
      return data?.version;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

/* -------------------------------------------------------------------------- */
/*                         GET VERSION WITHOUT RELOAD                         */
/* -------------------------------------------------------------------------- */
export const getVersionByIdWithoutLoading = createAsyncThunk(
  "version/version_by_id_without_loading",
  async (versionId, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`/models/v1/version/${versionId}`);
      return data.version;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

/* -------------------------------------------------------------------------- */
/*                               CREATE VERSION                               */
/* -------------------------------------------------------------------------- */
export const createVersion = createAsyncThunk(
  "version/create_version",
  async ({ modelId, payload }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `/models/v1/model/${modelId}/version`,
        payload
      );
      return data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

/* -------------------------------------------------------------------------- */
/*                               UPDATE VERSION                               */
/* -------------------------------------------------------------------------- */
export const updateVersion = createAsyncThunk(
  "version/update_version_by_id",
  async ({ versionId, payload }, { rejectWithValue }) => {
    try {
      const { data } = await axios.patch(
        `/models/v1/version/${versionId}`,
        payload
      );
      return data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

/* -------------------------------------------------------------------------- */
/*                         GET TRAINING BY VERSION ID                         */
/* -------------------------------------------------------------------------- */
export const getVersionTrainings = createAsyncThunk(
  "version/version_training_by_id",
  async (versionId, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `/models/v1/version/${versionId}/trainings`
      );
      return data.trainings;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

/* -------------------------------------------------------------------------- */
/*                               START TRAINING                               */
/* -------------------------------------------------------------------------- */
export const startTrainings = createAsyncThunk(
  "version/start_version_training_by_id",
  async (versionId, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `/models/v1/version/${versionId}/training`
      );
      notifications.show({
        message: data.message,
      });
      return data.message;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

/* -------------------------------------------------------------------------- */
/*                               ABORT TRAINING                               */
/* -------------------------------------------------------------------------- */
export const abortTrainings = createAsyncThunk(
  "version/abort_version_training_by_id",
  async (versionId, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `/models/v1/version/${versionId}/abort`
      );
      notifications.show({
        message: data.message,
      });
      return data.message;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

/* -------------------------------------------------------------------------- */
/*                             GET HARDWARES LIST                             */
/* -------------------------------------------------------------------------- */
export const getModelVersionHardware = createAsyncThunk(
  "version/get_model_version_hardware",
  async ({ sizeId = "", withSizeId = true }, { rejectWithValue }) => {
    let path = `/hardwares/v1/hardwares`;

    if (withSizeId) {
      path = `${path}?model_size_id=${sizeId}`;
    }

    try {
      const { data } = await axios.get(path);

      return data.hardwares;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getVersionTrainingLogs = createAsyncThunk(
  "version/get_version_training_logs",
  async (code, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`/models/v1/code/${code}/logs`, {
        params: { stream: "true" },
        responseType: "stream",
      });
      // return data.hardwares;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

/* -------------------------------------------------------------------------- */
/*                              GET CONFIG STATUS                             */
/* -------------------------------------------------------------------------- */
export const getVersionConfigStatus = createAsyncThunk(
  "version/get_version_config_status",
  async (code, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`/demo/v1/${code}/status`);

      return data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

/* -------------------------------------------------------------------------- */
/*                                 WARN CONFIG                                */
/* -------------------------------------------------------------------------- */
export const getVersionConfigWarm = createAsyncThunk(
  "version/get_version_config_warm",
  async (code, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`/demo/v1/${code}/warm`);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

/* -------------------------------------------------------------------------- */
/*                                 GET CONFIG                                 */
/* -------------------------------------------------------------------------- */
export const getVersionConfig = createAsyncThunk(
  "version/get_version_config",
  async (code, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`/demo/v1/${code}/config`);
      return data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

/* -------------------------------------------------------------------------- */
/*                             TRIGGER CONFIG CALL                            */
/* -------------------------------------------------------------------------- */
export const triggerConfigCall = createAsyncThunk(
  "version/trigger_config_call",
  async ({ url, method, headers, payload }, { rejectWithValue }) => {
    // return
    try {
      // const axiosInstance = new axios({
      //   baseURL: url,
      //   method: method,
      //   headers,
      // });

      const { data } = await axios({
        url,
        method,
        headers,
        data: payload,
        // removeHeader: true,
      });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

/* -------------------------------------------------------------------------- */
/*                             UPLOAD VERSION FILE                            */
/* -------------------------------------------------------------------------- */

export const uploadVersionFile = createAsyncThunk(
  "version/upload_version_file",
  async (formData, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(`/models/v1/version/upload`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      return data.urls;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
