import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../service/axios";

export const getTotalBalance = createAsyncThunk(
  "billing/get_total_fund",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`/billing/v1/billing/balance?type=card`);

      return data.balance;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getBillingMethods = createAsyncThunk(
  "billing/billing_methods",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`/billing/v1/billing/methods?type=card`);

      return data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getBillingUsage = createAsyncThunk(
  "billing/billing_usage",
  async ({ page = "1", size = "10" }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `/billing/v1/billing/usage?type=card?page=${page}&page_size=${size}`
      );

      return { data: data.usage.data, metadata: data.usage.pagination_meta };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getBillingHistory = createAsyncThunk(
  "billing/billing_history",
  async ({ page = "1", size = "10" }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `/billing/v1/billing/transactions?type=card?page=${page}&page_size=${size}`
      );

      // const sortedData = sortByCreatedOnDescending(data.data);
      let lastPaymentData = "";

      return {
        data: data.data,
        metadata: data.pagination_meta,
        lastPaymentData,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addFund = createAsyncThunk(
  "billing/billing_add_fund",
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `/billing/v1/billing/balance?type=card`,
        payload
      );

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addCard = createAsyncThunk(
  "billing/billing_add_card",
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(`/billing/v1/billing/method`, payload);

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const removeCard = createAsyncThunk(
  "billing/billing_remove_card",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await axios.delete(`/billing/v1/billing/method/${id}`);

      return id;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const inviteMember = createAsyncThunk(
  "billing/invite_member",
  async ({ teamId, payload }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `/teams/v1/team/${teamId}/invite`,
        payload
      );
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getInvoice = createAsyncThunk(
  "billing/get_invoice",
  async (code, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `/billing/v1/billing/transaction/${code}`
      );
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
