import { Button, LoadingOverlay, Modal } from '@mantine/core';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { updateDatasetItems } from '../../../../../../action/dataset';

const DatasetItemsArchive = forwardRef((props, ref) => {
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [opened, setOpened] = useState(false);
  const dispatch = useDispatch();
  const { datasetId } = useParams();
  const [isArchivingApiInProgress, setIsArchivingApiInProgress] =
    useState(false);

  const handleArchiveItems = async () => {
    setIsArchivingApiInProgress(true);
    await dispatch(
      updateDatasetItems({
        datasetId: datasetId,
        action: 'archive',
        queries: props.searchQuery,
        tags: Object.keys(props.filteredTags).join(','),
        // taskId: props.taskId,
      })
    );
    setIsArchivingApiInProgress(false);
    setOpened(false);
  };

  useImperativeHandle(ref, () => ({
    openArchiveModal(_totalItems) {
      setTotalItems(_totalItems);
      setOpened(true);
    },
  }));

  useEffect(() => {
    if (props.filteredTags?.length > 0 || props.searchQuery) {
      setIsFilterApplied(true);
    } else {
      setIsFilterApplied(false);
    }
  }, [props.filteredTags, props.searchQuery]);

  return (
    <Modal.Root
      opened={opened}
      onClose={() => setOpened(false)}
      centered
      size="md">
      <Modal.Overlay />

      <Modal.Content>
        <Modal.Header>
          <h1 className="text-xl font-medium">Archive {totalItems} items?</h1>
        </Modal.Header>
        <Modal.Body>
          <div>
            <p>
              {isFilterApplied
                ? 'All filtered items will get archived. Are you sure you want to proceed?'
                : 'All items will get archived. Are you sure you want to proceed?'}
            </p>

            <div className="flex flex-row justify-end items-center gap-6 px-4 mt-2">
              <Button
                uppercase
                variant="subtle"
                color="dark"
                onClick={() => setOpened(false)}>
                CANCEL
              </Button>
              <Button
                uppercase
                onClick={() => handleArchiveItems()}
                styles={(theme) => ({
                  root: {
                    backgroundColor: '#fa5252 !important',
                  },
                })}
                color="red">
                YES
              </Button>
            </div>
          </div>
          <LoadingOverlay
            visible={isArchivingApiInProgress === true}
            overlayBlur={2}
          />
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
});

export default DatasetItemsArchive;
