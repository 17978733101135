import { createSlice } from "@reduxjs/toolkit";
import { getDeploymentsList, updateDeployment } from "../action/deployments";

const initialState = {
  isLoading: false,
  deployments: null,
  error: null,
  metadata: {},
};

const deploymentSlice = createSlice({
  name: "deployments",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getDeploymentsList.pending,
      (state, { payload, ...props }) => {
        state.isLoading = true;
      }
    );
    builder.addCase(getDeploymentsList.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.deployments = payload.data;
      state.metadata = payload.metadata;
    });

    builder.addCase(getDeploymentsList.rejected, (state, { payload }) => {
      state.error = payload;
    });

    builder.addCase(updateDeployment.pending, (state, { payload, meta }) => {
      const { deploymentId } = meta.arg;
      const updatedDeploymentIndex = state.deployments.findIndex(
        (d) => d.id === deploymentId
      );
      if (updatedDeploymentIndex !== -1) {
        state.deployments[updatedDeploymentIndex] = {
          ...state.deployments[updatedDeploymentIndex],
          isLoading: true,
        };
      }
    });
    builder.addCase(updateDeployment.fulfilled, (state, { payload, meta }) => {
      const { deploymentId } = meta.arg;
      const updatedDeploymentIndex = state.deployments.findIndex(
        (d) => d.id === deploymentId
      );
      if (updatedDeploymentIndex !== -1) {
        state.deployments[updatedDeploymentIndex] = {
          ...state.deployments[updatedDeploymentIndex],
          isLoading: false,
          ...payload,
        };
      }
    });
    builder.addCase(updateDeployment.rejected, (state, { payload, meta }) => {
      const { deploymentId } = meta.arg;
      const updatedDeploymentIndex = state.deployments.findIndex(
        (d) => d.id === deploymentId
      );
      if (updatedDeploymentIndex !== -1) {
        state.deployments[updatedDeploymentIndex] = {
          ...state.deployments[updatedDeploymentIndex],
          isLoading: false,
        };
      }
    });
  },
});

export default deploymentSlice.reducer;
