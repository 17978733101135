import { Loader as MantineLoader } from "@mantine/core";
import { cva } from "class-variance-authority";
import React from "react";

const loader = cva("flex justify-center items-center", {
  variants: {
    intent: {},
    height: {
      fit: "h-fit",
      screen: "h-screen",
      small: "h-[25vh]",
      half: "h-[50vh]",
      quarter: "h-[75vh]",
    },
  },
  defaultVariants: {
    height: "screen",
  },
});

const Loader = ({ height, size }) => {
  return (
    <div className={loader({ height })}>
      <MantineLoader color="#EA6669" size={size} />
    </div>
  );
};

export default Loader;
