import { Button, LoadingOverlay, Modal } from '@mantine/core';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { deleteTag, getAllTags } from '../../../../../../action/dataset';

const DatasetItemsDeleteTag = forwardRef((props, ref) => {
  const [tag, setTag] = useState(null);
  const [totalItems, setTotalItems] = useState(0);
  const [opened, setOpened] = useState(false);
  const { deleteStatus } = useSelector((state) => state.dataset.tags);
  const dispatch = useDispatch();
  const { datasetId } = useParams();

  const handleDeleteTag = () => {
    dispatch(
      deleteTag({
        datasetId,
        tagId: tag.id,
      })
    );
  };

  useEffect(() => {
    if (deleteStatus === 'fulfilled') {
      dispatch(getAllTags({ datasetId }));
      setOpened(false);
    }
  }, [deleteStatus]);

  useImperativeHandle(ref, () => ({
    openDeleteTagModal(_tag, _totalItems) {
      setTag(_tag);
      setTotalItems(_totalItems);
      setOpened(true);
    },
  }));

  return (
    <Modal.Root
      opened={opened}
      onClose={() => setOpened(false)}
      centered
      size="md">
      <Modal.Overlay />

      <Modal.Content>
        <Modal.Header>
          <h1 className="text-xl font-medium">
            Delete tag <b>{tag?.label}</b>?
          </h1>
        </Modal.Header>
        <Modal.Body>
          <div>
            <p className="my-4">
              {totalItems} items labelled as <b>{tag?.label}</b> will get
              untagged. Are you sure you want to proceed?
            </p>
            <div className="flex flex-row justify-end items-center gap-6 px-4 mt-2">
              <Button
                uppercase
                variant="subtle"
                color="dark"
                onClick={() => setOpened(false)}>
                CANCEL
              </Button>
              <Button
                uppercase
                onClick={() => handleDeleteTag()}
                styles={(theme) => ({
                  root: {
                    backgroundColor: '#fa5252 !important',
                  },
                })}
                color="red">
                YES
              </Button>
            </div>
          </div>
          <LoadingOverlay
            visible={deleteStatus === 'pending'}
            overlayBlur={2}
          />
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
});

export default DatasetItemsDeleteTag;
