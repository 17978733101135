import React, { useEffect } from "react";
import { MdOutlineFileUpload } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useParams } from "react-router-dom";
import Button from "../../../../../Components/Button/Button";
import Loader from "../../../../../Components/Loader/Loader";
import DatasetHeader from "../../../../../Compositions/Dataset/DatasetDetails/DatasetHeader/DatasetHeader";
import { getDataSetById } from "../../../../../action/dataset";

const DatasetDetail = () => {
  const dispatch = useDispatch();
  const { projectId, datasetId } = useParams();
  const { isLoading, data, error } = useSelector(
    (state) => state.dataset.dataset
  );

  useEffect(() => {
    const prepareState = async () => {
      if (!datasetId) return;
      dispatch(getDataSetById(datasetId));
    };
    prepareState();
  }, [dispatch, datasetId]);

  if (isLoading || !data || error) return <Loader height="half" />;

  return (
    <div>
      <DatasetHeader>
        <Button
          intent="outlined"
          href={`/project/${projectId}/dataset/create/${datasetId}/upload`}
          state={{ existingDataSet: true }}
        >
          <MdOutlineFileUpload className="mr-2" />
          Upload
        </Button>
      </DatasetHeader>
      <Outlet />
    </div>
  );
};

export default DatasetDetail;
