import { cva } from "class-variance-authority";
import React from "react";
import Loader from "../Loader/Loader";

const fallBack = cva("flex items-center justify-center w-full", {
  variants: {
    size: {
      half: "h-[50vh]",
      quarter: "h-[75vh]",
      full: "h-screen",
    },
  },
  compoundVariants: [],
  defaultVariants: {
    size: "full",
  },
});

const FallBack = ({ size }) => {
  return (
    <div className={fallBack({ size })}>
      <Loader />
    </div>
  );
};

export default FallBack;
