import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../service/axios";

export const getTeamsList = createAsyncThunk(
  "team/team_list",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get("/teams/v1/teams");
      return data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getTeamsById = createAsyncThunk(
  "team/teamById",
  async (teamId, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`/teams/v1/team/${teamId}`);
      return data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createTeam = async (payload) => {
  try {
    const { data } = await axios.post("/teams/v1/team", payload);
    return data;
  } catch (error) {
    return { error: true };
  }
};

export const updateTeam = createAsyncThunk(
  "team/update_team",
  async ({ teamId, payload }, { rejectWithValue }) => {
    try {
      const { data } = await axios.patch(`/teams/v1/team/${teamId}`, payload);
      return data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

/* -------------------------------------------------------------------------- */
/*                              GET TEAM MEMBERS                              */
/* -------------------------------------------------------------------------- */

export const getTeamMembers = createAsyncThunk(
  "team/get_team_members",
  async (teamId, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`/teams/v1/team/${teamId}/members`);
      return data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const postInviteTeamMember = createAsyncThunk(
  "team/invite_team_member",
  async ({ teamId, payload }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `/teams/v1/team/${teamId}/invite`,
        payload
      );
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const postAcceptInviteTeamMember = createAsyncThunk(
  "team/accept_invite_team_member",
  async (token, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(`/teams/v1/invite/callback`, {
        token,
        status: "ACCEPTED",
      });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
