import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useMatch, useParams } from "react-router-dom";
import StartTraining from "../../../../../../../Components/Button/StartTraining/StartTraining";
import Error from "../../../../../../../Components/Error/Error";
import Loader from "../../../../../../../Components/Loader/Loader";
import OverViewTopTab from "../../../../../../../Compositions/Version/OverViewTopTab/OverViewTopTab";
import { getModelById } from "../../../../../../../action/model";
import { getVersionById } from "../../../../../../../action/version";
import { cn } from "../../../../../../../utils/cn";

const VersionDetail = () => {
  const dispatch = useDispatch();
  const { modelId, versionId } = useParams();
  const { isLoading, error, data } = useSelector(
    (state) => state.version.version
  );
  const isVersionOverView = useMatch(
    "/project/:projectId/models/:modelId/version/:versionId/overview"
  );

  useEffect(() => {
    const prepareState = async () => {
      dispatch(getModelById(modelId));
      dispatch(getVersionById(versionId));
    };
    prepareState();
  }, [dispatch, modelId, versionId]);

  if (isLoading) return <Loader />;

  if (error || data === null) return <Error height="quarter" />;

  return (
    <div>
      <div className={cn(isVersionOverView && "px-4 xl:px-8 py-5 pb-0")}>
        <OverViewTopTab>
          <StartTraining />
        </OverViewTopTab>
      </div>
      <div className={cn(isVersionOverView && "p-0")}>
        <Outlet />
      </div>
    </div>
  );
};

export default VersionDetail;
