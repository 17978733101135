export const DATASET_CATEGORIES = {
  IMAGE: "IMAGE",
  VIDEO: "VIDEO",
  AUDIO: "AUDIO",
  LANGUAGE: "LANGUAGE",
  TABULAR: "TABULAR",
};

export const DATASET_CATEGORIES_INNER = {
  IMAGE: {
    IMAGE_OBJECT_DETECTION: "IMAGE_OBJECT_DETECTION",
    IMAGE_CLASSIFICATION: "IMAGE_CLASSIFICATION",
    IMAGE_INSTANCE_SEGMENTATION: "IMAGE_INSTANCE_SEGMENTATION",
    IMAGE_SEMANTIC_SEGMENTATION: "IMAGE_SEMANTIC_SEGMENTATION",
    IMAGE_CHARACTER_RECOGNITION: "IMAGE_CHARACTER_RECOGNITION",
  },
  VIDEO: {
    VIDEO_CLASSIFICATION: "VIDEO_CLASSIFICATION",
    VIDEO_OBJECT_DETECTION: "VIDEO_OBJECT_DETECTION",
  },
  AUDIO: {
    AUDIO_TRANSCRIPTION: "AUDIO_TRANSCRIPTION",
    AUDIO_GENERATION: "AUDIO_GENERATION",
  },
  LANGUAGE: {
    LANGUAGE_CONVERSATIONAL: "LANGUAGE_CONVERSATIONAL",
    LANGUAGE_GENERATION: "LANGUAGE_GENERATION",
  },
  TABULAR: {
    TABULAR_CLASSIFICATION: "TABULAR_CLASSIFICATION",
    TABULAR_REGRESSION: "TABULAR_REGRESSION",
  },
};
