import { Text, TextInput } from "@mantine/core";
import React from "react";
import { cn } from "../../utils/cn";

const Input = ({
  parentClass = "",
  error,
  name = "",
  label = "",
  inputClass = "",
  type = "text",
  withLabel = true,
  ...props
}) => {
  return (
    <div className={cn(`input_container relative pb-5`, parentClass)}>
      {withLabel && (
        <Text
          component="label"
          htmlFor={name}
          className="font-[500] capitalize"
        >
          {label}
        </Text>
      )}
      <TextInput
        type={type}
        id={name}
        placeholder=""
        className={`${inputClass}`}
        classNames={{
          input: "py-[2px] h-auto border-[#ECECEC] rounded-md",
        }}
        {...props}
      />
      {error && (
        <div className="absolute bottom-0 text-xs text-red-500 error">
          {error}
        </div>
      )}
    </div>
  );
};

export default Input;
