import favIcon from "../favicon/favicon.svg";
import editIconColored from "./EditIcon.svg";
import stop from "./Stop.svg";
import add from "./add.svg";
import addCircle from "./add_circle.svg";
import archive from "./archive.svg";
import cancelCircle from "./cancel.svg";
import check from "./check.svg";
import close from "./close.svg";
import copy from "./copy.svg";
import billing_dark from "./dark/billing.svg";
import documentation_dark from "./dark/documentation.svg";
import logout_dark from "./dark/logout.svg";
import profile_dark from "./dark/profile.svg";
import support_dark from "./dark/support.svg";
import teams_dark from "./dark/teams.svg";
import audio from "./dataset/audio.svg";
import classification from "./dataset/classification.svg";
import connectCloud from "./dataset/connect_cloud.svg";
import create from "./dataset/create.svg";
import image from "./dataset/image.svg";
import importIcon from "./dataset/import.svg";
import instanceSegmentation from "./dataset/instance_segmentation.svg";
import language from "./dataset/language.svg";
import object from "./dataset/object.svg";
import semanticSegmentation from "./dataset/semantic_segmentation.svg";
import dataImport from "./dataset/steps/data_import.svg";
import format from "./dataset/steps/format.svg";
import label from "./dataset/steps/label.svg";
import name from "./dataset/steps/name.svg";
import operation from "./dataset/steps/operation.svg";
import steps from "./dataset/steps/steps.svg";
import tabular from "./dataset/tabular.svg";
import upload from "./dataset/upload.svg";
import uploadIconColored from "./dataset/uploadIconColored.svg";
import video from "./dataset/video.svg";
import { deployment } from "./deployments/deployment";
import downloadIcon from "./download_icon.svg";
import draft from "./draft.svg";
import editIcon2 from "./edit2.svg";
import editIcon from "./edit_icon.svg";
import envelope from "./envelope.svg";
import errorCircle from "./error.svg";
import errorIcon from "./error_icon.svg";
import externalList from "./external_ink.svg";
import fileWhite from "./file_white_icon.svg";
import csv from "./files/csv.svg";
import doc from "./files/doc.svg";
import docx from "./files/docx.svg";
import html from "./files/html.svg";
import imageType from "./files/image_type.svg";
import md from "./files/md.svg";
import pdf from "./files/pdf.svg";
import ppt from "./files/ppt.svg";
import rtf from "./files/rtf.svg";
import txt from "./files/txt.svg";
import url from "./files/url.svg";
import xlsx from "./files/xlsx.svg";
import filterBar from "./filter-bars.svg";
import gears from "./gear.svg";
import drawerIcon from "./labelling/drawer_icon.svg";
import drawerIconActive from "./labelling/drawer_icon_active.svg";
import billing_light from "./light/billing.svg";
import documentation_light from "./light/documentation.svg";
import logout_light from "./light/logout.svg";
import profile_light from "./light/profile.svg";
import support_light from "./light/support.svg";
import teams_light from "./light/teams.svg";
import masterCard from "./mastercard_logo.svg";
import menuHorizontal from "./menu_horizontal.svg";
import moreHorizontal from "./more_horiz.svg";
import path from "./path.svg";
import pending from "./pending.svg";
import playCircle from "./play_circle.svg";
import rename from "./rename.svg";
import schedule from "./schedule.svg";
import apiKeyActive from "./sidebar/api_key_active.svg";
import apiKeyInactive from "./sidebar/api_key_inactive.svg";
import dashboardActive from "./sidebar/dashboard_active.svg";
import dashboardInactive from "./sidebar/dashboard_inactive.svg";
import dataSetActive from "./sidebar/dataset_active.svg";
import dataSetInactive from "./sidebar/dataset_inactive.svg";
import deploymentsActive from "./sidebar/deployments_active.svg";
import deploymentsInActive from "./sidebar/deployments_inactive.svg";
import knowledgeBaseActive from "./sidebar/knowledge_base_active.svg";
import knowledgeBaseInactive from "./sidebar/knowledge_base_inactive.svg";
import modelsActive from "./sidebar/models_active.svg";
import modelsInactive from "./sidebar/models_inactive.svg";
import settingsActive from "./sidebar/settings_active.svg";
import settingsInactive from "./sidebar/settings_inactive.svg";
import sortDown from "./sort_down.svg";
import split from "./split.svg";
import failed from "./status/failed.svg";
import inProgress from "./status/in_progress.svg";
import readyToTrain from "./status/ready_train.svg";
import readyToUse from "./status/ready_use.svg";
import substractCircle from "./substract_minus.svg";
import tag from "./tag.svg";
import tickIcon from "./tick.svg";
import trash from "./trash.png";
import trashRed from "./trash.svg";
import unArchive from "./unarchive.svg";
import untag from "./untag.svg";
import uploadFile from "./upload.svg";
import done from "./version/done.svg";
import existingIcon from "./version/existingIcon.svg";
import processing from "./version/processing.svg";
import scratchIcon from "./version/scratchIcon.svg";
import visaCard from "./visa_card_logo.svg";
import conversation from "./conversation.svg";
import custom from "./custom.svg";
import promptResponsePair from "./prompt-response-pair.svg";
import removeRed from "./remove-red.svg";
import columns from './columns.svg';

const icons = {
  profile: profile_light,
  profileActive: profile_dark,
  profileInActive: profile_light,
  teams: teams_light,
  teamActive: teams_dark,
  teamInActive: teams_light,
  billing: billing_light,
  billingActive: billing_dark,
  billingInActive: billing_light,
  documentation: documentation_light,
  documentationActive: documentation_dark,
  documentationInActive: documentation_light,
  support: support_light,
  supportActive: support_dark,
  supportInActive: support_light,
  logout: logout_light,
  logoutActive: logout_dark,
  logoutInActive: logout_light,
  columns,
  dashboardActive,
  dashboardInactive,
  modelsInactive,
  modelsActive,
  settingsActive,
  settingsInactive,
  apiKeyActive,
  apiKeyInactive,
  dataSetActive,
  dataSetInactive,
  masterCard,
  envelope,
  audio,
  classification,
  connectCloud,
  create,
  image,
  importIcon,
  instanceSegmentation,
  language,
  object,
  tabular,
  upload,
  video,
  semanticSegmentation,
  name,
  format,
  label,
  operation,
  dataImport,
  steps,
  trash,
  sortDown,
  menuHorizontal,
  gears,
  editIcon,
  editIcon2,
  visaCard,
  errorIcon,
  processing,
  done,
  failed,
  inProgress,
  readyToTrain,
  readyToUse,
  copy,
  pending,
  uploadFile,
  drawerIcon,
  drawerIconActive,
  editIconColored,
  downloadIcon,
  existingIcon,
  scratchIcon,
  uploadIconColored,
  tickIcon,
  deployment,
  deploymentsActive,
  deploymentsInActive,
  playCircle,
  cancelCircle,
  externalList,
  xlsx,
  csv,
  doc,
  docx,
  html,
  md,
  pdf,
  ppt,
  rtf,
  txt,
  url,
  imageType,
  trashRed,
  favIcon,
  filterBar,
  archive,
  unArchive,
  path,
  tag,
  untag,
  addCircle,
  substractCircle,
  downArrow: sortDown,
  split,
  moreHorizontal,
  rename,
  check,
  close,
  knowledgeBaseActive,
  knowledgeBaseInactive,
  fileWhite,
  draft,
  schedule,
  add,
  promptResponsePair,
  conversation,
  custom,
  removeRed,
  stop,
  errorCircle,
};

export default icons;
