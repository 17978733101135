import React from "react";

const FileUpload = ({
  parentClass = "",
  error,
  name = "",
  label = "",
  inputClass = "",
  ...props
}) => {
  return (
    <div className={`input_container relative pb-5 ${parentClass}`}>
      <label htmlFor={name} className="font-[500]">
        {label}
      </label>
      <input
        type="file"
        id={name}
        placeholder="john@example.com"
        className={`border w-full py-2 px-5 rounded-md  border-[#ECECEC] ${inputClass}`}
        {...props}
      />
      {error && (
        <div className="absolute bottom-0 text-xs text-red-500 error">
          {error}
        </div>
      )}
    </div>
  );
};

export default FileUpload;
