import React from "react";

const UploadPercent = ({ handleCancel }) => {
  return (
    <div className="">
      <div className="text-center">
        <h3 className="my-4 text-3xl font-bold">Uploading your dataset...</h3>
        <p className="w-2/3 mx-auto text-sm mb-9">
          Please wait while your file uploads.
        </p>
      </div>

      <div className="relative flex items-center justify-center py-32">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="155"
          height="155"
          viewBox="0 0 155 155"
          fill="none"
        >
          <circle
            cx="77.5"
            cy="77.5"
            r="77.5"
            fill="url(#paint0_linear_483_2903)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_483_2903"
              x1="47"
              y1="-7.5"
              x2="121"
              y2="171"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#8830C0" />
              <stop offset="1" stopColor="#EA6669" />
            </linearGradient>
          </defs>
        </svg>
        <svg
          className="absolute z-10 -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
          xmlns="http://www.w3.org/2000/svg"
          width="129"
          height="129"
          viewBox="0 0 129 129"
          fill="none"
        >
          <circle cx="64.5" cy="64.5" r="64.5" fill="white" />
        </svg>
        <h3 className="absolute z-10 font-bold -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
          100%
        </h3>
      </div>
      <div className="flex items-center justify-center">
        <button
          className="bg-white py-[10px] px-8  text-black border-2 w-fit border-black font-bold text-sm rounded-md"
          onClick={handleCancel}
        >
          CANCEL UPLOAD
        </button>
      </div>
    </div>
  );
};

export default UploadPercent;
