import { Slider as MantineSlider, createStyles } from "@mantine/core";
import React from "react";

const Slider = ({ ...props }) => {
  const { classes } = useStyles();
  return (
    <div>
      <MantineSlider {...props} className={classes.slider} />
    </div>
  );
};

export default Slider;

const useStyles = createStyles((theme) => ({
  slider: {
    ".mantine-Slider-bar": {
      background: "linear-gradient(to right, #8830c0 0%, #ea6669 100%);",
    },
    ".mantine-Slider-thumb": {
      background: "linear-gradient(to right, #8830c0 0%, #ea6669 100%);",
      border: "2px solid white",
    },
  },
}));
