import React from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Title from "../../../../Components/Title/Title";
import DatasetDetailToggle from "../DatasetDetailToggle/DatasetDetailToggle";

const DatasetHeader = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { data } = useSelector((state) => state.dataset.dataset);
  const { projectId } = useParams();

  const navigateToTeams = () => navigate(`/project/${projectId}/dataset/list`);

  const sections = location.pathname.split("/");
  const section = sections.slice(sections.length - 1, sections.length);

  return (
    <div>
      <div className="text-xs bread text-[#999999]">
        <button onClick={navigateToTeams}>Dataset</button>
        <span className="mx-1">/</span>
        <span className="capitalize">{section}</span>
      </div>
      <div className="flex justify-between mb-3 min-h-[36px]">
        <Title>{data?.name}</Title>

        <div className="text-xs font-bold actions">{children}</div>
      </div>
      <DatasetDetailToggle />
    </div>
  );
};

export default DatasetHeader;
