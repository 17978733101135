import { createSlice } from "@reduxjs/toolkit";
import {
  getBillingHistory,
  getBillingMethods,
  getBillingUsage,
  getTotalBalance,
  removeCard,
} from "../action/billing";

const initialState = {
  isLoading: false,
  total: null,
  error: null,
  lastTransaction: null,
  methods: {
    isLoading: false,
    data: null,
    error: null,
  },
  usage: {
    isLoading: false,
    list: null,
    metadata: { page_size: 0, page: 0, total: 0, total_pages: 0 },
    error: null,
  },
  history: {
    isLoading: false,
    list: null,
    metadata: {},
    error: null,
  },
};

const billingSlice = createSlice({
  name: "billing",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    /* -------------------------------------------------------------------------- */
    /*                              GET TOTAL BALANCE                             */
    /* -------------------------------------------------------------------------- */
    builder.addCase(getTotalBalance.pending, (state, { payload }) => {
      state.isLoading = true;
      state.total = null;
      state.error = null;
    });
    builder.addCase(getTotalBalance.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.total = payload;
    });

    builder.addCase(getTotalBalance.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.total = null;
      state.error = payload;
    });
    /* -------------------------------------------------------------------------- */
    /*                             GET BILLING METHODS                            */
    /* -------------------------------------------------------------------------- */
    builder.addCase(getBillingMethods.pending, (state, { payload }) => {
      state.methods.isLoading = true;
      state.methods.data = null;
      state.methods.error = null;
    });
    builder.addCase(getBillingMethods.fulfilled, (state, { payload }) => {
      state.methods.isLoading = false;
      state.methods.data = payload;
    });

    builder.addCase(getBillingMethods.rejected, (state, { payload }) => {
      state.methods.isLoading = false;
      state.methods.data = null;
      state.methods.error = payload;
    });

    /* -------------------------------------------------------------------------- */
    /*                              GET BILLING USAGE                             */
    /* -------------------------------------------------------------------------- */
    builder.addCase(getBillingUsage.pending, (state, { payload }) => {
      state.usage.isLoading = true;
    });
    builder.addCase(getBillingUsage.fulfilled, (state, { payload }) => {
      state.usage.isLoading = false;
      state.usage.list = payload.data;
      state.usage.metadata = payload.metadata;
    });

    builder.addCase(getBillingUsage.rejected, (state, { payload }) => {
      state.usage.isLoading = false;
      state.usage.error = payload;
    });
    /* -------------------------------------------------------------------------- */
    /*                             GET BILLING HISTORY                            */
    /* -------------------------------------------------------------------------- */
    builder.addCase(getBillingHistory.pending, (state, { payload }) => {
      state.history.isLoading = true;
    });
    builder.addCase(getBillingHistory.fulfilled, (state, { payload }) => {
      state.history.isLoading = false;
      state.history.list = payload.data;
      state.history.metadata = payload.metadata;

      if (payload.metadata.page === 1) {
        state.lastTransaction = payload.data[0];
      }
    });

    builder.addCase(getBillingHistory.rejected, (state, { payload }) => {
      state.history.isLoading = false;
      state.history.error = payload;
    });

    /* -------------------------------------------------------------------------- */
    /*                                 REMOVE CARD                                */
    /* -------------------------------------------------------------------------- */
    builder.addCase(removeCard.pending, (state, { payload }) => {
      state.methods.isLoading = true;
    });
    builder.addCase(removeCard.fulfilled, (state, { payload }) => {
      state.methods.isLoading = false;
      state.methods.data = state.methods.data.filter(
        (item) => item.id !== payload
      );
    });

    builder.addCase(removeCard.rejected, (state, { payload }) => {
      state.methods.isLoading = false;
      state.methods.error = payload;
    });
  },
});

// export const {} = billingSlice.actions;

export default billingSlice.reducer;
