import { LoadingOverlay } from "@mantine/core";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../../Components/Loader/Loader";
import LabellingHeader from "../../../Compositions/Dataset/DatasetDetails/Labelling/LabellingHeader/LabellingHeader";
import {
  getDataSetById,
  getDataSetData,
  getTaskById,
} from "../../../action/dataset";
import { updateTask } from "../../../action/task";
import { default as getConfig } from "./LabellingInterface/Config";
import LabelStudioReact from "./LabellingInterface/Studio";

const LabelStudio = () => {
  const [isLoading, setLoading] = useState(false);
  const [task, setTask] = useState(null);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const { dataset } = useSelector((state) => state.dataset);
  const { datasetId, taskId } = useParams();

  useEffect(() => {
    const prepareState = async () => {
      dispatch(getDataSetById(datasetId));
      dispatch(getDataSetData({ datasetId }));
    };
    prepareState();
  }, [datasetId, dispatch]);

  useEffect(() => {
    const prepareState = async () => {
      // if (!taskId)
      try {
        setLoading(true);
        const action = await dispatch(getTaskById(taskId));
        console.log("🚀 ~ prepareState ~ action:", action);
        if (getTaskById.fulfilled.match(action)) {
          setTask({
            ...action.payload,
            id: Number(action.payload.sequence || 1),
          });
        }
        if (getTaskById.rejected.match(action)) {
          setError(action.payload);
        }
      } catch (err) {
        setError(err);
      }
      setLoading(false);
    };
    prepareState();
  }, [dispatch, taskId]);

  return (
    <div className="">
      <LabellingHeader />
      <Content isLoading={isLoading} task={task} dataset={dataset.data} />
    </div>
  );
};

export default LabelStudio;

const Content = ({ isLoading, task: _task, dataset }) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { projectId, datasetId, taskId } = useParams();
  const { isLoading: isLoadingDataset, data } = useSelector(
    (state) => state.dataset.dataset
  );
  const { list } = useSelector((state) => state.dataset.data);

  const handleTaskComplete = async (values) => {
    setIsUpdating(true);
    try {
      const payload = {
        annotations: {},
      };
      if (_task?.data?.image) {
        payload.annotations = values;
      } else {
        values.forEach((value) => {
          payload.annotations[value.from_name] = value.value.text[0];
        });
      }

      const action = await dispatch(updateTask({ taskId, payload }));

      if (updateTask.fulfilled.match(action)) {
        const currentTaskIndex = list.findIndex((task) => task.id === taskId);
        const nextTask = list[currentTaskIndex + 1];

        const nextTaskId = nextTask.id;

        navigate(
          `/project/${projectId}/dataset/${datasetId}/task/${nextTaskId}/labelling`
        );
      }
    } catch (error) {}
    setIsUpdating(false);
  };
  const task = useMemo(() => {
    if (!_task) return _task;
    if (!_task.data) return _task;
    let file_path = _task.data.file || _task.data.path || _task.data.image;
    if (file_path) {
      if (!file_path.startsWith("http")) {
        _task.data.image = `https://cdn.propulsionhq.com/static${file_path}`;
        _task.data.imageCrossOrigin = "anonymous";

        if (_task.annotations.length > 0) {
          _task.annotations = [
            {
              id: "index",
              result: _task.annotations,
            },
          ];
        } else if (_task.data.annotations) {
          let data_ann = [];
          for(let i in _task.data.annotations){
            data_ann.push({
              id: _task.data.annotations[i].id || i,
              to_name: _task.data.annotations[i].to_name || "image",
              from_name: _task.data.annotations[i].from_name || "label",
              ..._task.data.annotations[i]
            })
          }
          _task.annotations = [
            {
              id: "index",
              result: data_ann
            },
          ];
        }else {
          _task.annotations = [
            {
              id: "index",
              result: _task.data.annotations
                ? _task.data?.annotations.map((value, index) => ({
                    ...value,
                    id: index,
                    from_name: "label",
                    to_name: "image",
                  }))
                : [],
            },
          ];
        }
      }
      // console.log(_task, "_task");
      delete _task.data.annotations;
    }

    return _task;
  }, [_task]);

  let config_view = useMemo(() => {
    if (!data || !_task) return "";

    return getConfig(_task, data?.metadata?.labels, dataset);
  }, [data, _task, dataset]);

  if (isLoading || !_task || isLoadingDataset) return <Loader height="half" />;

  return (
    <>
      <LoadingOverlay visible={isUpdating} />
      <LabelStudioReact
        key={_task.id}
        config={config_view}
        task={task}
        onTaskComplete={handleTaskComplete}
        onSubmitAnnotation={(data) => console.log("onSubmitAnnotation", data)}
        submitAnnotation={(data) => console.log("submitAnnotation", data)}
        onUpdateAnnotation={(data) => console.log("onUpdateAnnotation", data)}
        updateAnnotation={(data) => console.log("updateAnnotation", data)}
        onDeleteAnnotation={(data) => console.log("onDeleteAnnotation", data)}
        deleteAnnotation={(data) => console.log("deleteAnnotation", data)}
        selectAnnotation={(data) => console.log("selectAnnotation", data)}
        groundTruth={(data) => console.log("groundTruth", data)}
        entityCreate={(data) => console.log("entityCreate", data)}
        entityDelete={(data) => console.log("entityDelete", data)}
        prevTask={(data) => console.log("prevTask", data)}
        skipTask={(data) => console.log("skipTask", data)}
        unskipTask={(data) => console.log("unskipTask", data)}
        submitDraft={(data) => console.log("submitDraft", data)}
        user={{
          pk: "1",
          id: "1",
          firstName: "James",
          lastName: "Dean",
        }}
        interfaces={[
          "panel",
          "topbar",
          "controls",
          "edit-history",
          "side-column",
          "annotations:delete",
          "instructions",
          // "predictions:menu",
          "submit",
          "update",
          "skip",
          "infobar",
        ]}
      />
    </>
  );
};
