import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../service/axios";

/* -------------------------------------------------------------------------- */
/*                               GET ALL MODELS                               */
/* -------------------------------------------------------------------------- */
export const updateTask = createAsyncThunk(
  "dataset/update_task",
  async ({ taskId, payload }, { rejectWithValue }) => {
    try {
      const { data } = await axios.patch(
        `/datasets/v1/task/${taskId}`,
        payload
      );
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
