import { faker } from "@faker-js/faker";
import clsx from "clsx";
import React, { useLayoutEffect, useRef, useState } from "react";
import ChatDummy from "./ChatDummy/ChatDummy";
import ChatInput from "./ChatInput/ChatInput";
import ChatItem from "./ChatItem/ChatItem";

const Chat = ({ height = "85vh", inverted = false, privateChat = false }) => {
  const [chatList, setChatList] = useState([]);
  const chatBodyRef = useRef();
  const haveChat = chatList.length > 0;

  useLayoutEffect(() => {
    scrollToBottom();
  }, [chatList]);

  const handleSendChant = async (value) => {
    try {
      setChatList([
        ...chatList,
        {
          value,
          isSent: true,
        },
        {
          isLoading: true,
          isSent: false,
        },
      ]);

      const result = await new Promise((resolve) => {
        setTimeout(() => resolve(dummyChatHtml()), 2000);
      });
      if (result) {
        setChatList((prev) => {
          return prev.map((chat) => {
            if (chat.isLoading)
              //  return { ...chat, value: result };
              return { ...chat, isLoading: false, value: result };

            return chat;
          });
        });
      }
    } catch (error) {}
  };

  const scrollToBottom = () => {
    if (chatBodyRef.current) {
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
    }
  };

  const containerClassName = clsx(
    inverted ? "bg-[#F8F8F8]" : "",
    privateChat && "p-6 rounded-md"
  );

  const className = clsx(
    ` h-[${height}]  flex pb-4`,
    haveChat ? "items-end" : "items-center"
  );

  return (
    <div className={containerClassName}>
      <div className={className}>
        {haveChat ? (
          <div
            className="flex flex-col flex-1 h-full overflow-y-auto"
            ref={chatBodyRef}
          >
            {chatList.map((chat, index) => (
              <ChatItem key={index} inverted={inverted} {...chat} />
            ))}
          </div>
        ) : (
          <ChatDummy />
        )}
      </div>
      <ChatInput onSubmit={handleSendChant} />
    </div>
  );
};

export default Chat;

const dummyChatHtml = () => `
  <div class="text-sm">
    <p class="mb-2">${faker.lorem.sentence()}</p>
    <ul class="mb-4 list-outside list-disc pl-5">
      ${Array(Math.floor(Math.random() * 20))
        .fill(1)
        .map((value) => `<li>${faker.lorem.sentence()}</li>`)
        .join("")}    
    </ul>
    <p>${faker.lorem.sentence(50)}</p>
  </div>
`;
