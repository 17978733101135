import React, { useState } from "react";
import { AiFillPlayCircle } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getVersionById, startTrainings } from "../../../action/version";
import Button from "../Button";

const StartTraining = () => {
  const [isStartTrainingLoading, setIsStartTrainingLoading] = useState(false);
  const dispatch = useDispatch();
  const { versionId } = useParams();
  const { data } = useSelector((state) => state.version.version);

  const handleStartTraining = async () => {
    setIsStartTrainingLoading(true);
    const action = await dispatch(startTrainings(versionId));

    if (startTrainings.fulfilled.match(action)) {
      dispatch(getVersionById(versionId));
    }

    setIsStartTrainingLoading(false);
  };

  return (
    <Button
      intent="outlined"
      onClick={handleStartTraining}
      isLoading={isStartTrainingLoading}
      disabled={!!data?.training}
    >
      <AiFillPlayCircle size={18} className="mr-2" />
      START TRAINING
    </Button>
  );
};

export default StartTraining;
