import add from "./add.svg";
import cancel from "./cancel.svg";
import controller from "./controller.svg";
import emergency from "./emergency_home.svg";
import Failed from "./failed.svg";
import idle from "./idle.svg";
import remove from "./remove.svg";
import Running from "./running.svg";
import Starting from "./start.svg";
import Stopped from "./stopped.svg";
import timeLapse from "./timelapse.svg";
import Updating from "./updating.svg";

export const deployment = {
  Running,
  Starting,
  Stopped,
  controller,
  add,
  remove,
  Failed,
  Updating,
  timeLapse,
  cancel,
  idle,
  emergency,
};
