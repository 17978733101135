import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../service/axios";

/* -------------------------------------------------------------------------- */
/*                               GET ALL MODELS                               */
/* -------------------------------------------------------------------------- */
export const getModelsList = createAsyncThunk(
  "model/models_list",
  async (projectId, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `/models/v1/project/${projectId}/models`
      );
      return data.models;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

/* -------------------------------------------------------------------------- */
/*                             DELETE MODEL BY ID                             */
/* -------------------------------------------------------------------------- */
export const deleteModelById = createAsyncThunk(
  "model/models_delete_by_id",
  async (modelId, { rejectWithValue }) => {
    try {
      const { data } = await axios.delete(`/models/v1/model/${modelId}/`);
      return modelId;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
/* -------------------------------------------------------------------------- */
/*                              GET PUBLIC MODELS                             */
/* -------------------------------------------------------------------------- */
export const getPublicModelsList = createAsyncThunk(
  "model/public_models_list",
  async ({ query = "", page = "1", size = "10" }, { rejectWithValue }) => {
    try {
      // `/models/v1/models?page=${page}&page_size=${size}&q=${query}`
      const { data } = await axios.get(`/models/v1/models?q=${query}`);
      // return { data: data.data, metadata: data.pagination_meta };
      return data.data;
    } catch (error) {
      console.log("🚀 ~ file: model.js:46 ~ error:", error);
      return rejectWithValue(error);
    }
  }
);

/* -------------------------------------------------------------------------- */
/*                                CREATE MODEL                                */
/* -------------------------------------------------------------------------- */
export const createModel = createAsyncThunk(
  "model/create_model",
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(`/models/v1/model`, payload);
      return data.model;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

/* -------------------------------------------------------------------------- */
/*                                UPDATE MODEL                                */
/* -------------------------------------------------------------------------- */
export const updateModel = createAsyncThunk(
  "model/create_model",
  async ({ modelId, payload }, { rejectWithValue }) => {
    try {
      const { data } = await axios.patch(
        `/models/v1/model/${modelId}`,
        payload
      );
      return data.model;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

/* -------------------------------------------------------------------------- */
/*                               GET MODEL BY ID                              */
/* -------------------------------------------------------------------------- */
export const getModelById = createAsyncThunk(
  "model/model_by_id",
  async (modelId, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`/models/v1/model/${modelId}`);
      return data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
