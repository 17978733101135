import { UnstyledButton } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import React, { useMemo } from "react";
import { MdChevronRight, MdOutlineChevronLeft } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import icons from "../../../../../Assets/icons/icons";
import images from "../../../../../Assets/images";
import Button from "../../../../../Components/Button/Button";
import Loader from "../../../../../Components/Loader/Loader";
import { getDataSetData } from "../../../../../action/dataset";
import TaskDrawer from "../TaskDrawer/TaskDrawer";

const LabellingHeader = () => {
  const [opened, { open, close }] = useDisclosure(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { projectId, datasetId } = useParams();
  const { isLoading, list, metadata } = useSelector(
    (state) => state.dataset.data
  );
  const { taskId } = useParams();

  const currentTaskIndex = useMemo(() => {
    if (!isLoading) {
      if (list) {
        const index = list.findIndex((task) => task.id === taskId);
        if (index) return (index + 1) * metadata?.page || 1;
      }

      return 1;
    }
  }, [isLoading, list, metadata?.page, taskId]);
  const handleGetPrevTask = () => {
    const previousTask = list[currentTaskIndex - 2];
    if (!previousTask) return;

    const path = `/project/${projectId}/dataset/${datasetId}/task/${previousTask.id}/labelling`;

    navigate(path);
  };

  const handleGetNextTask = async () => {
    let nextTask = list[currentTaskIndex];
    if (!nextTask) {
      let payload = { datasetId, page: metadata.page + 1, size: 10 };
      const action = await dispatch(getDataSetData(payload));
      const newCurrentTaskIndex = list.findIndex((task) => task.id === taskId);

      if (getDataSetData.fulfilled.match(action)) {
        nextTask = action.payload.list[newCurrentTaskIndex + 1];
      }
    }
    if (nextTask) {
      const path = `/project/${projectId}/dataset/${datasetId}/task/${nextTask.id}/labelling`;

      navigate(path);
    }
  };

  const previousBtnDisabled = currentTaskIndex === 1;

  const nextBtnDisabled = currentTaskIndex === Number(metadata?.total);

  return (
    <>
      <TaskDrawer opened={opened} open={open} close={close} />
      <div className="px-4 py-2 border-b border-[#0000001a] flex gap-4 items-center justify-between">
        <div className="flex items-center gap-4 ">
          <Link to={`/projects/all`}>
            <img src={images.logoIcon} alt="" />
          </Link>
          <UnstyledButton onClick={open}>
            <img
              src={opened ? icons.drawerIconActive : icons.drawerIcon}
              alt=""
            />
          </UnstyledButton>
        </div>
        {isLoading ? (
          <Loader height="fit" />
        ) : (
          <div className="flex justify-center gap-4 item-center">
            <Button
              intent={previousBtnDisabled ? "disabled" : "outlined"}
              disabled={previousBtnDisabled}
              onClick={handleGetPrevTask}
            >
              <MdOutlineChevronLeft />
              Prev
            </Button>
            {/* {current}/{metadata?.total} */}
            <Button
              intent={nextBtnDisabled ? "disabled" : "outlined"}
              disabled={nextBtnDisabled}
              onClick={handleGetNextTask}
            >
              Next
              <MdChevronRight />
            </Button>
          </div>
        )}
        <Button
          href={`/project/${projectId}/dataset/${datasetId}/data`}
          intent="danger"
        >
          Exit
        </Button>
      </div>
    </>
  );
};

export default LabellingHeader;
