import { cva } from "class-variance-authority";
import React from "react";
import images from "../../Assets/images";
import Button from "../Button/Button";
import Title from "../Title/Title";
const { icons } = images;

const error = cva("flex justify-center items-center flex-col gap-6", {
  variants: {
    intent: {},
    height: {
      screen: "h-screen",
      small: "h-[25vh]",
      half: "h-[50vh]",
      quarter: "h-[75vh]",
    },
  },
  defaultVariants: {
    height: "screen",
  },
});

const Error = ({
  height,
  label = "INTERNAL SERVER ERROR",
  onclick = () => window.location.reload(),
}) => {
  return (
    <div className={error({ height })}>
      <img src={icons.errorIcon} alt="Error" className="max-w-[75px]" />
      <Title>{label}</Title>
      <Button onClick={onclick}>Reload</Button>
    </div>
  );
};

export default Error;
