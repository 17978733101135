import { createContext, useState } from 'react';

export const DatasetItemsContext = createContext();

export const DatasetOperationModes = Object.freeze({
  BULK: 'BULK',
  SINGLE: 'SINGLE',
});

export const DatasetItemsProvider = ({ children }) => {
  const [tagModalOpened, setTagModalOpened] = useState(false);
  const [tagModalOperationMode, setTagModalOperationMode] = useState(
    DatasetOperationModes.BULK
  );

  return (
    <DatasetItemsContext.Provider
      value={{
        tagModalOpened,
        setTagModalOpened,
        tagModalOperationMode,
        setTagModalOperationMode,
      }}>
      {children}
    </DatasetItemsContext.Provider>
  );
};
