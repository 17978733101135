import { createSlice } from "@reduxjs/toolkit";
import { getTeamMembers, getTeamsById, getTeamsList } from "../action/teams";

const initialState = {
  isLoading: false,
  teams: null,
  error: null,
  team: {
    isLoading: false,
    data: null,
    error: null,
  },
  members: {
    isLoading: false,
    data: null,
    error: null,
  },
};

const teamSlice = createSlice({
  name: "team",
  initialState,
  reducers: {
    updateTeamLocal: (state, { payload }) => {
      state.team.data = payload;
    },
  },
  extraReducers: (builder) => {
    /* -------------------------------------------------------------------------- */
    /*                                GET TEAM LIST                               */
    /* -------------------------------------------------------------------------- */
    builder.addCase(getTeamsList.pending, (state, { payload }) => {
      state.isLoading = true;
      state.teams = null;
      state.error = null;
    });
    builder.addCase(getTeamsList.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.teams = payload;
    });

    builder.addCase(getTeamsList.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.teams = null;
      state.error = payload;
    });

    /* -------------------------------------------------------------------------- */
    /*                               GET TEAM BY ID                               */
    /* -------------------------------------------------------------------------- */
    builder.addCase(getTeamsById.pending, (state, { payload }) => {
      state.team.isLoading = true;
      state.team.data = null;
      state.team.error = null;
    });
    builder.addCase(getTeamsById.fulfilled, (state, { payload }) => {
      state.team.isLoading = false;
      state.team.data = payload;
    });

    builder.addCase(getTeamsById.rejected, (state, { payload }) => {
      state.team.isLoading = false;
      state.team.data = null;
      state.team.error = payload;
    });

    /* -------------------------------------------------------------------------- */
    /*                              GET TEAM MEMBERS                              */
    /* -------------------------------------------------------------------------- */
    builder.addCase(getTeamMembers.pending, (state, { payload }) => {
      state.members.isLoading = true;
      state.members.data = null;
      state.members.error = null;
    });
    builder.addCase(getTeamMembers.fulfilled, (state, { payload }) => {
      state.members.isLoading = false;
      state.members.data = payload;
    });

    builder.addCase(getTeamMembers.rejected, (state, { payload }) => {
      state.members.isLoading = false;
      state.members.data = null;
      state.members.error = payload;
    });
  },
});

export const { updateTeamLocal } = teamSlice.actions;

export default teamSlice.reducer;
