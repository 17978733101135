import { Tooltip } from '@mantine/core';
import { useTimeout } from '@mantine/hooks';
import React, { useState } from 'react';
import icons from '../../../../../../Assets/icons/icons';

function CopyToClipboard({ content }) {
  const [tooltipOpened, setTooltipOpened] = useState(false);
  const [buttonPressed, setButtonPressed] = useState(false);
  const { start: startTooltipTimeout } = useTimeout(
    () => setTooltipOpened(false),
    1500
  );

  const handleButtonClick = async () => {
    setButtonPressed(true);
    await navigator.clipboard.writeText(content);
    setTimeout(() => {
      setButtonPressed(false);
      setTooltipOpened(true);
      startTooltipTimeout();
    }, 200);
  };

  return (
    <Tooltip
      opened={tooltipOpened}
      label="Content copied to clipboard"
      withArrow
      position="top"
      color="green">
      <button
        className={`relative inline-flex items-center mt-2 justify-center bg-transparent rounded-full focus:outline-none transition-transform ${
          buttonPressed ? 'transform scale-90' : 'transform scale-100'
        }`}
        onClick={handleButtonClick}>
        <img
          src={icons.copy}
          alt="copy prompt"
          className="w-4 h-4"
        />
      </button>
    </Tooltip>
  );
}

export default CopyToClipboard;
