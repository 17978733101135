import { Text, Tooltip } from '@mantine/core';
import clsx from 'clsx';
import React from 'react';
import { Link, useParams } from 'react-router-dom';

const List = ({ list = [], }) => {
    const { projectId, datasetId, taskId } = useParams()

    return (
        list &&
        list.map(({ id, }) => (
            <div
                key={id}
                className={clsx("grid grid-cols-12 gap-2 py-3 px-2 mb-2 text-sm text-center rounded-md border border-[#EBEBEB] items-center",
                    taskId === id && "border-red-500"
                )}
            >
                <Link to={`/project/${projectId}/dataset/${datasetId}/task/${id}/labelling`} className="col-span-6 font-medium" > <Tooltip
                    label={id}
                    multiline
                    w={420}
                    withArrow
                    transitionProps={{ duration: 200 }}>
                    <Text lineClamp={1}>
                        {id}
                    </Text>
                </Tooltip></Link>
                <div className="col-span-6">120/2300</div>

            </div>
        ))
    );
}

export default List