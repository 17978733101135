import { Tooltip } from "@mantine/core";
import { cva } from "class-variance-authority";
import React from "react";
import images from "../../../Assets/images";

const { icons } = images;

const training = cva("flex  text-xs gap-2 border-2 rounded-md font-bold ", {
  variants: {
    intent: {
      purple: "text-[#8830C0] border-[#8830C0] bg-[#F8ECFF]",
      green: "text-[#1DCE00] border-[#1DCE00] bg-[#DAFFD4]",
      blue: "text-[#295EA5] border-[#295EA5] bg-[#CDE2FF]",
      red: "text-[#D0272B] border-[#D0272B] bg-[#FFDEDF]",
      yellow: "text-[#FFCB12] border-[#FFCB12] bg-[#FFF9E5]",
    },
    padding: { none: "px-0 py-0", xs: "px-2 py-1", sm: "" },
    outlined: {
      true: "border-transparent bg-transparent",
    },
    justify: {
      center: "justify-center",
      left: "justify-start",
      end: "justify-end",
    },
    align: {
      center: "items-center",
      left: "items-start",
      end: "items-end",
    },
  },
  compoundVariants: [
    {
      intent: true,
      outlined: true,
    },
  ],
  defaultVariants: {
    intent: "purple",
    padding: "xs",
    justify: "center",
    align: "center",
  },
});

const TrainingStatus = ({
  intent,
  outlined,
  padding,
  align,
  justify,
  status,
  icon = false,
}) => {
  const statusObj = getTrainingStatus(status);
  const renderIcon = () => {
    if (icon)
      return (
        <Tooltip label={statusObj?.label ? statusObj?.label : ""}>
          <span>
            <img src={statusObj.icon} alt="Training Status" />
          </span>
        </Tooltip>
      );

    return <img src={statusObj.icon} alt="" />;
  };

  return (
    <div
      className={training({
        intent: statusObj.intent,
        outlined,
        padding,
        justify,
        align,
      })}
    >
      {renderIcon()}
      {!icon && statusObj.label}
    </div>
  );
};

export default TrainingStatus;

export const getTrainingStatus = (status) => {
  switch (status) {
    case "ONGOING":
    case "RUNNING":
      return {
        label: "IN PROGRESS",
        icon: icons.inProgress,
        intent: "blue",
      };

    case "FAILED":
      return {
        label: "FAILED",
        icon: icons.failed,
        intent: "red",
      };

    case "SUCCESSFUL":
      return {
        label: "READY TO USE",
        icon: icons.readyToUse,
        intent: "green",
      };

    case "PENDING":
      return {
        label: "PENDING",
        icon: icons.pending,
        intent: "yellow",
      };

    default:
      return {
        label: "READY TO TRAIN",
        icon: icons.readyToTrain,
        intent: "purple",
      };
  }
};
