import React from "react";
import Title from "../../../Components/Title/Title";

const ChatDummy = () => {
  return (
    <div>
      <h4 className="text-2xl font-medium">
        Chat with Llama 2 on PropulsionAI
      </h4>
      <Title className="mt-5 text-xs mb-11">Free evaluation version</Title>
      <p className="mb-1 text-md">Hi there! I'm your AI assistant.</p>
      <p className="mb-4 text-md">
        Need <span className="underline"> punny dog names</span> or{" "}
        <span className="underline">witty jokes</span>? I've got 'em! Want to
        chat about life? I'm game!
      </p>
      <p className="mb-1 text-md">Just drop me a message to get started!</p>
    </div>
  );
};

export default ChatDummy;
