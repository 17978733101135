import { Checkbox, Input, Menu, Popover, TextInput } from '@mantine/core';
import {
  IconChevronLeft,
  IconChevronRight,
  IconSearch,
} from '@tabler/icons-react';
import React, { useContext, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import icons from '../../../../../../Assets/icons/icons';
import Button from '../../../../../../Components/Button/Button';
import { DatasetItemsContext } from './DatasetItemsContext';

function DatasetItemsHeader({
  isLoading,
  page,
  setPage,
  totalPage,
  onPageChange,
  handleSearch,
  handleUntagItems,
  handleCopyItems,
  handleArchiveItems,
  handleUnarchiveItems,
  handleSplitItems,
  filteredTags,
  setFilteredTags,
}) {
  const debounceRef = useRef(null);
  const { tagModalOpened, setTagModalOpened } = useContext(DatasetItemsContext);
  const { data: tags } = useSelector((state) => state.dataset.tags);
  const [tempSelectedTags, setTempSelectedTags] = useState({});
  const [filtersOpened, setFiltersOpened] = useState(false);
  const filterPopoverRef = useRef();
  const openFilterPopover = () => setFiltersOpened(true);
  const closeFilterPopover = () => setFiltersOpened(false);

  const initSelectedTags = () => {
    const tempSelection = {};
    Object.keys(filteredTags).forEach((tag) => {
      tempSelection[tag] = filteredTags[tag];
    });
    setTempSelectedTags(tempSelection);
  };

  const handleInputChange = (e) => {
    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }

    debounceRef.current = setTimeout(() => {
      handleSearch(e.target.value);
    }, 300);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      if (debounceRef.current) {
        clearTimeout(debounceRef.current);
      }
      handleSearch(e.target.value);
    }
  };

  const handleFilterTagChange = (tag) => {
    if (tempSelectedTags[tag.id]) {
      setTempSelectedTags((prevTags) => {
        const { [tag.id]: _, ...updatedTags } = prevTags;
        return updatedTags;
      });
    } else {
      setTempSelectedTags((prevTags) => ({ ...prevTags, [tag.id]: tag }));
    }
  };

  const handleApplyBtnClick = () => {
    setFilteredTags(tempSelectedTags);
    closeFilterPopover();
  };

  const handlePageChange = (event) => {
    const inputValue = event.target.value;
    if (
      inputValue === '' ||
      (!isNaN(inputValue) &&
        Number(inputValue) >= 0 &&
        Number(inputValue) <= totalPage)
    ) {
      setPage(inputValue);
    } else {
      setPage(1);
    }
  };

  return (
    <div className="flex flex-row items-center gap-8 flex-wrap justify-end">
      <TextInput
        className="min-w-[350px] md:min-w-[450px]"
        icon={<IconSearch />}
        placeholder="Search"
        radius="md"
        size="md"
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
      />

      <div className="flex-1"></div>

      {/* Filters */}
      <Popover
        trapFocus
        position="bottom"
        withArrow
        shadow="md"
        opened={filtersOpened}
        onClose={closeFilterPopover}
        onOpen={() => initSelectedTags()}>
        <Popover.Target>
          <button
            ref={filterPopoverRef}
            onClick={openFilterPopover}>
            <div className="flex flex-row items-center gap-3 border border-gray-800 rounded py-2 px-3">
              <div>Filters</div>
              <img
                src={icons.filterBar}
                alt="Filter Popover"
                className="w-5"
              />
            </div>
          </button>
        </Popover.Target>
        <Popover.Dropdown>
          <div className="flex flex-col">
            <div className="flex flex-row justify-between">
              <div className="flex flex-col gap-4 overflow-y-auto h-96 pr-5">
                <div>FILTER BY TAG</div>
                {tags &&
                  tags.map((t) => {
                    return (
                      <Checkbox
                        key={t.id}
                        value={t.id}
                        size="sm"
                        onChange={(value) => handleFilterTagChange(t)}
                        checked={tempSelectedTags[t.id] ?? false}
                        label={
                          <div className="pl-1 flex flex-row gap-2 items-center">
                            <div
                              className={`border rounded w-5 h-5`}
                              style={{ backgroundColor: t.color }}></div>
                            <div>{t.label}</div>
                          </div>
                        }
                      />
                    );
                  })}
              </div>

              <div className="w-[1.5px] h-auto bg-gray-200 mr-6"></div>

              <div className="flex flex-col gap-4 pr-4">
                <div>FILTER BY FIELD</div>

                <Checkbox
                  value="Prompt"
                  label="Prompt"
                />

                <Checkbox
                  value="Response"
                  label="Response"
                />
              </div>
            </div>
            <div className="flex flex-row justify-between items-center gap-10 mt-10 px-1">
              <Button
                intent="subtle"
                onClick={() => setTempSelectedTags({})}>
                CLEAR ALL
              </Button>
              <Button onClick={() => handleApplyBtnClick()}>APPLY</Button>
            </div>
          </div>
        </Popover.Dropdown>
      </Popover>

      {/* Bulk Action */}
      <Menu
        shadow="md"
        width={200}>
        <Menu.Target>
          <button>
            <div className="flex flex-row items-center gap-3 border border-gray-800 rounded py-2 px-3">
              <div>Bulk Actions</div>
              <img
                src={icons.downArrow}
                alt="tag items"
                className="w-5"
              />
            </div>
          </button>
        </Menu.Target>

        <Menu.Dropdown>
          <Menu.Item onClick={() => setTagModalOpened(true)}>
            <div className="flex flex-row items-center gap-4">
              <img
                src={icons.tag}
                alt="untag items"
                className="w-4"
              />
              <div>Tag Items</div>
            </div>
          </Menu.Item>

          <Menu.Item onClick={() => handleUntagItems()}>
            <div className="flex flex-row items-center gap-4">
              <img
                src={icons.untag}
                alt="untag items"
                className="w-4"
              />
              <div>Untag Items</div>
            </div>
          </Menu.Item>

          <Menu.Item onClick={() => handleCopyItems()}>
            <div className="flex flex-row items-center gap-4">
              <img
                src={icons.copy}
                alt="copy items"
                className="w-4"
              />
              <div>Copy</div>
            </div>
          </Menu.Item>
          <Menu.Item onClick={() => handleArchiveItems()}>
            <div className="flex flex-row items-center gap-4">
              <img
                src={icons.archive}
                alt="archive items"
                className="w-4"
              />
              <div>Archive</div>
            </div>
          </Menu.Item>
          <Menu.Item onClick={() => handleUnarchiveItems()}>
            <div className="flex flex-row items-center gap-4">
              <img
                src={icons.unArchive}
                alt="Restore archive items"
                className="w-4"
              />
              <div>Restore Archive</div>
            </div>
          </Menu.Item>
          <Menu.Item onClick={() => handleSplitItems()}>
            <div className="flex flex-row items-center gap-4">
              <img
                src={icons.split}
                alt="split items"
                className="w-4"
              />
              <div>Split</div>
            </div>
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>

      {/* Pagination */}
      <div className="flex items-center gap-2">
        {/* <span className="w-4">{page}</span> */}
        <Input
          radius="md"
          size="md"
          style={{
            textAlign: 'center',
            maxWidth: '80px',
          }}
          disabled={isLoading}
          value={page}
          onChange={handlePageChange}
          aria-label="current page"
        />
        <span>of</span>
        <span>{totalPage}</span>
        <button
          className="disabled:cursor-not-allowed disabled:text-gray-400"
          disabled={page === 1}
          onClick={() => onPageChange(Number(page) - 1)}>
          <IconChevronLeft />
        </button>
        <button
          className="disabled:cursor-not-allowed disabled:text-gray-400"
          disabled={page === totalPage}
          onClick={() => onPageChange(Number(page) + 1)}>
          <IconChevronRight />
        </button>
      </div>
    </div>
  );
}

export default DatasetItemsHeader;
