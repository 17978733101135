import { Button, LoadingOverlay, Modal, Radio } from '@mantine/core';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { deleteTag, getAllTags } from '../../../../../../action/dataset';

const DatasetItemsUntag = forwardRef((props, ref) => {
  const { isFilterApplied } = props;
  const [totalItems, setTotalItems] = useState(0);
  const [opened, setOpened] = useState(false);
  const { deleteStatus } = useSelector((state) => state.dataset.tags);
  const dispatch = useDispatch();
  const { datasetId } = useParams();
  const [tagOperation, setTagOperation] = useState('allTags');

  const handleUntagItems = () => {
    dispatch(deleteTag({ datasetId }));
  };

  useEffect(() => {
    if (deleteStatus === 'fulfilled') {
      dispatch(getAllTags({ datasetId }));
      setOpened(false);
    }
  }, [deleteStatus]);

  useImperativeHandle(ref, () => ({
    openUntagModal(_totalItems) {
      setTagOperation('allTags');
      setTotalItems(_totalItems);
      setOpened(true);
    },
  }));

  return (
    <Modal.Root
      opened={opened}
      onClose={() => setOpened(false)}
      centered
      size="md">
      <Modal.Overlay />

      <Modal.Content>
        <Modal.Header>
          <h1 className="text-xl font-medium">Untag</h1>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Radio.Group
              className="flex flex-row items-center justify-between px-3 mb-10 mt-2"
              value={tagOperation}
              onChange={setTagOperation}>
              <Radio
                label={`Untag (${totalItems} items)`}
                value="allTags"
                color="red"
              />
              <Radio
                label="Select a tag to Untag"
                value="selectedTagsOnly"
                color="red"
              />
            </Radio.Group>

            <div></div>

            <div className="flex flex-row justify-end items-center gap-6 px-4 mt-2">
              <Button
                uppercase
                variant="subtle"
                color="dark"
                onClick={() => setOpened(false)}>
                CANCEL
              </Button>
              <Button
                uppercase
                onClick={() => handleUntagItems()}
                styles={(theme) => ({
                  root: {
                    backgroundColor: '#fa5252 !important',
                  },
                })}
                color="red">
                YES
              </Button>
            </div>
          </div>
          <LoadingOverlay
            visible={deleteStatus === 'pending'}
            overlayBlur={2}
          />
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
});

export default DatasetItemsUntag;
