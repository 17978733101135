import { createSlice } from "@reduxjs/toolkit";
import { getCurrentUser, login, updateProfile, resetPasswordTrigger, resetPassword } from "../action/auth";

const initialState = {
  isLoading: false,
  isAuthenticated: false,
  token: null,
  isUserLoading: true,
  user: null,
  error: null,
  isProfileUpdating: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    signOut: (state) => {
      localStorage.clear();
      state.isAuthenticated = false;
      state.user = null;
      state.token = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    /* -------------------------------------------------------------------------- */
    /*                           RESET PASSWORD TRIGGER                           */
    /* -------------------------------------------------------------------------- */
    builder.addCase(resetPasswordTrigger.pending, (state, {payload}) => {
      state.isLoading = true;
    });

    builder.addCase(resetPasswordTrigger.fulfilled, (state, {payload}) => {
      state.isLoading = false;
    });

    builder.addCase(resetPasswordTrigger.rejected, (state, {payload}) => {
      state.isLoading = false;
    });

    /* -------------------------------------------------------------------------- */
    /*                               RESET PASSWORD                               */
    /* -------------------------------------------------------------------------- */
    builder.addCase(resetPassword.pending, (state, {payload}) => {
      state.isLoading = true;
    });

    builder.addCase(resetPassword.fulfilled, (state, {payload}) => {
      state.isLoading = false;
    });

    builder.addCase(resetPassword.rejected, (state, {payload}) => {
      state.isLoading = false;
    });

    /* -------------------------------------------------------------------------- */
    /*                                    LOGIN                                   */
    /* -------------------------------------------------------------------------- */
    builder.addCase(login.pending, (state, { payload }) => {
      state.isLoading = true;
      state.isAuthenticated = false;
      state.isUserLoading = true;
      state.user = null;
      state.token = null;
    });
    builder.addCase(login.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.isAuthenticated = true;
      state.isUserLoading = false;
      state.user = {
        account_id: payload.account_id,
        name: payload.name,
      };
      state.token = payload.token;
    });

    builder.addCase(login.rejected, (state, { payload }) => {
      state.error = payload;
      state.isAuthenticated = false;
      state.isUserLoading = false;
      state.user = null;
      state.token = null;
    });
    /* -------------------------------------------------------------------------- */
    /*                                CURRENT USER                                */
    /* -------------------------------------------------------------------------- */
    builder.addCase(getCurrentUser.pending, (state, { payload }) => {
      state.isUserLoading = true;
    });
    builder.addCase(getCurrentUser.fulfilled, (state, { payload }) => {
      state.isAuthenticated = true;
      state.isUserLoading = false;
      state.user = payload.user;
      state.token = payload.token;
    });

    builder.addCase(getCurrentUser.rejected, (state, { payload }) => {
      state.error = payload;
      state.isAuthenticated = false;
      state.isUserLoading = false;
      state.user = null;
      state.token = null;
    });

    /* -------------------------------------------------------------------------- */
    /*                               UPDATE PROFILE                               */
    /* -------------------------------------------------------------------------- */

    builder.addCase(updateProfile.pending, (state, { payload }) => {
      state.isProfileUpdating = true;
    });
    builder.addCase(updateProfile.fulfilled, (state, { payload }) => {
      state.isProfileUpdating = false;
      state.user = payload;
    });

    builder.addCase(updateProfile.rejected, (state, { payload }) => {
      state.isProfileUpdating = false;
      state.error = payload;
    });
  },
});

export const { signOut } = authSlice.actions;

export default authSlice.reducer;
