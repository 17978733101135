import { Input, Menu, Modal } from '@mantine/core';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  createTag,
  getAllTags,
  updateDatasetItems,
  updateTag,
} from '../../../../../../action/dataset';
import icons from '../../../../../../Assets/icons/icons';
import Button from '../../../../../../Components/Button/Button';
import {
  DatasetItemsContext,
  DatasetOperationModes,
} from './DatasetItemsContext';

function DatasetItemsTagModal({
  handleDeleteTag,
  taskId,
  searchQuery,
  filteredTags,
  fetchDataset,
}) {
  const dispatch = useDispatch();
  const { datasetId } = useParams();
  const {
    createStatus,
    updateStatus,
    data: tags,
  } = useSelector((state) => state.dataset.tags);
  const { tagModalOpened, setTagModalOpened, tagModalOperationMode } =
    useContext(DatasetItemsContext);
  const [newTag, setNewTag] = useState('');
  const [updateTagInputValue, setUpdateTagInputValue] = useState('');
  const [editingTagId, setEditingTagId] = useState(null);
  const [selectedTag, setSelectedTag] = useState(null);
  const [selectingTag, setSelectingTag] = useState(false);

  const handleCreateTag = async () => {
    dispatch(createTag({ datasetId, label: newTag, color: '#8BD79C' }));
  };

  const handleUpdateTag = async (tag, newLabel, color) => {
    dispatch(
      updateTag({
        datasetId,
        label: newLabel,
        color: color || tag.color,
        id: tag.id,
      })
    );
  };

  const handleRenameTag = async (tag) => {
    setUpdateTagInputValue(tag.label);
    setEditingTagId(tag.id);
  };

  const handleSelectTag = async () => {
    if (!selectedTag) {
      return;
    }

    setSelectingTag(true);

    const payload =
      tagModalOperationMode === DatasetOperationModes.BULK
        ? {
            datasetId: datasetId,
            action: 'add_tag',
            tagId: selectedTag.id,
            queries: searchQuery,
            tags: Object.keys(filteredTags).join(','),
            ...(tagModalOperationMode === DatasetOperationModes.SINGLE &&
              {
                // taskId: taskId,
              }),
          }
        : {
            datasetId: datasetId,
            action: 'add_tag',

            taskId,
            tagId: selectedTag.id,
          };
    const action = await dispatch(updateDatasetItems(payload));

    setSelectingTag(false);

    if (updateDatasetItems.fulfilled.match(action)) {
      fetchDataset();
      setTagModalOpened(false);
    }
  };

  const handleCheckUncheckTag = (tag) => {
    selectedTag && tag.id === selectedTag.id
      ? setSelectedTag(null)
      : setSelectedTag(tag);
  };

  // Handle changes after update tag.
  useEffect(() => {
    if (updateStatus === 'fulfilled') {
      setEditingTagId(null);
      setEditingTagId('');
      dispatch(getAllTags({ datasetId }));
    }
  }, [updateStatus]);

  // Reset control after create tag API
  useEffect(() => {
    if (createStatus === 'rejected') {
    }

    if (createStatus === 'fulfilled') {
      setNewTag('');
      dispatch(getAllTags({ datasetId }));
      setTagModalOpened(false);
    }
  }, [createStatus, setNewTag, dispatch, datasetId]);

  // Load all the tags
  useEffect(() => {
    dispatch(getAllTags({ datasetId }));
  }, [datasetId, dispatch]);

  return (
    <Modal.Root
      opened={tagModalOpened}
      onClose={() => setTagModalOpened(false)}
      centered
      size="280px">
      <Modal.Overlay />

      <Modal.Content>
        <Modal.Header>
          <h1>SELECT OR ADD NEW TAG</h1>
          <Modal.CloseButton />
        </Modal.Header>

        <Modal.Body>
          <div className="flex flex-col gap-4">
            <Input
              value={newTag}
              placeholder="New Tag"
              onChange={(e) => setNewTag(e.target.value)}
            />

            <div className="flex flex-col w-full gap-1 overflow-y-auto max-h-96">
              {tags &&
                tags.map((tag) => {
                  if (editingTagId && tag.id === editingTagId) {
                    return (
                      <div>
                        <Input
                          value={updateTagInputValue}
                          placeholder="Update Tag"
                          onChange={(e) =>
                            setUpdateTagInputValue(e.target.value)
                          }
                        />
                        <div className="flex flex-row justify-end items-center gap-4 mt-2 px-1">
                          <button
                            className="bg-green-200 p-2 rounded-full"
                            onClick={() =>
                              handleUpdateTag(tag, updateTagInputValue)
                            }>
                            <img
                              src={icons.check}
                              alt="Rename tag"
                              className="w-4 h-4"
                            />
                          </button>
                          <button
                            className="bg-red-200 p-2 rounded-full"
                            onClick={() => setEditingTagId(null)}>
                            <img
                              src={icons.close}
                              alt="Cancel rename tag"
                              className="w-4 h-4"
                            />
                          </button>
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div
                        key={tag.id}
                        className={`flex flex-row gap-2 items-center hover:bg-[#f1ebf4] p-2 cursor-pointer rounded-md ${
                          selectedTag && selectedTag.id === tag.id
                            ? 'bg-[#F8ECFF] text-[#8830C0]'
                            : ''
                        }`}>
                        <div
                          onClick={() => handleCheckUncheckTag(tag)}
                          style={{ backgroundColor: tag.color || 'black' }}
                          className="min-w-[16px] min-h-[16px] rounded"></div>
                        <div onClick={() => handleCheckUncheckTag(tag)}>
                          {tag.label}
                        </div>
                        <div
                          className="flex-1 min-h-[24px]"
                          onClick={() => handleCheckUncheckTag(tag)}></div>
                        <Menu
                          shadow="md"
                          width={170}>
                          <Menu.Target>
                            <button className="select-none min-w-[20px]">
                              <img
                                src={icons.moreHorizontal}
                                alt="tag"
                                className=" h-5 w-5"
                              />
                            </button>
                          </Menu.Target>

                          <Menu.Dropdown>
                            <Menu.Item
                              onClick={() => handleRenameTag(tag)}
                              icon={
                                <img
                                  src={icons.rename}
                                  alt="tag"
                                  className="w-5"
                                />
                              }>
                              Rename
                            </Menu.Item>
                            <Menu.Item
                              onClick={() => {
                                setTagModalOpened(false);
                                setTimeout(() => {
                                  handleDeleteTag(tag);
                                }, 500);
                              }}
                              icon={
                                <img
                                  src={icons.trash}
                                  alt="tag"
                                  className="w-5"
                                />
                              }>
                              Delete
                            </Menu.Item>
                            <Menu.Label sx={{ marginTop: '1rem' }}>
                              COLORS
                            </Menu.Label>
                            <div className="grid grid-cols-4 gap-2 px-2 my-2 justify-between">
                              <Menu.Item
                                sx={{
                                  margin: '0px !important',
                                  padding: '0px !important',
                                }}
                                icon={
                                  <div className="bg-[#FFBFCB] w-6 h-6 rounded"></div>
                                }
                                onClick={() =>
                                  handleUpdateTag(tag, tag.label, '#FFBFCB')
                                }
                              />
                              <Menu.Item
                                sx={{
                                  margin: '0px !important',
                                  padding: '0px !important',
                                }}
                                icon={
                                  <div className="bg-[#FFD8BD] w-6 h-6 rounded"></div>
                                }
                                onClick={() =>
                                  handleUpdateTag(tag, tag.label, '#FFD8BD')
                                }
                              />
                              <Menu.Item
                                sx={{
                                  margin: '0px !important',
                                  padding: '0px !important',
                                }}
                                icon={
                                  <div className="bg-[#FFFEE3] w-6 h-6 rounded"></div>
                                }
                                onClick={() =>
                                  handleUpdateTag(tag, tag.label, '#FFFEE3')
                                }
                              />
                              <Menu.Item
                                sx={{
                                  margin: '0px !important',
                                  padding: '0px !important',
                                }}
                                icon={
                                  <div className="bg-[#8BD79C] w-6 h-6 rounded"></div>
                                }
                                onClick={() =>
                                  handleUpdateTag(tag, tag.label, '#8BD79C')
                                }
                              />
                              <Menu.Item
                                sx={{
                                  margin: '0px !important',
                                  padding: '0px !important',
                                }}
                                icon={
                                  <div className="bg-[#DBFFFF] w-6 h-6 rounded"></div>
                                }
                                onClick={() =>
                                  handleUpdateTag(tag, tag.label, '#DBFFFF')
                                }
                              />
                              <Menu.Item
                                sx={{
                                  margin: '0px !important',
                                  padding: '0px !important',
                                }}
                                icon={
                                  <div className="bg-[#74D7DE] w-6 h-6 rounded"></div>
                                }
                                onClick={() =>
                                  handleUpdateTag(tag, tag.label, '#74D7DE')
                                }
                              />
                              <Menu.Item
                                sx={{
                                  margin: '0px !important',
                                  padding: '0px !important',
                                }}
                                icon={
                                  <div className="bg-[#DABFD6] w-6 h-6 rounded"></div>
                                }
                                onClick={() =>
                                  handleUpdateTag(tag, tag.label, '#DABFD6')
                                }
                              />
                              <Menu.Item
                                sx={{
                                  margin: '0px !important',
                                  padding: '0px !important',
                                }}
                                icon={
                                  <div className="bg-[#CD8AF8] w-6 h-6 rounded"></div>
                                }
                                onClick={() =>
                                  handleUpdateTag(tag, tag.label, '#CD8AF8')
                                }
                              />
                              <Menu.Item
                                sx={{
                                  margin: '0px !important',
                                  padding: '0px !important',
                                }}
                                icon={
                                  <div className="bg-[#7694FF] w-6 h-6 rounded"></div>
                                }
                                onClick={() =>
                                  handleUpdateTag(tag, tag.label, '#7694FF')
                                }
                              />
                              <Menu.Item
                                sx={{
                                  margin: '0px !important',
                                  padding: '0px !important',
                                }}
                                icon={
                                  <div className="bg-[#E47C7C] w-6 h-6 rounded"></div>
                                }
                                onClick={() =>
                                  handleUpdateTag(tag, tag.label, '#E47C7C')
                                }
                              />
                              <Menu.Item
                                sx={{
                                  margin: '0px !important',
                                  padding: '0px !important',
                                }}
                                icon={
                                  <div className="bg-[#C7C4C4] w-6 h-6 rounded"></div>
                                }
                                onClick={() =>
                                  handleUpdateTag(tag, tag.label, '#C7C4C4')
                                }
                              />
                            </div>
                          </Menu.Dropdown>
                        </Menu>
                      </div>
                    );
                  }
                })}
            </div>
            <Button
              isLoading={createStatus === 'pending' || selectingTag === true}
              width="full"
              onClick={() => (newTag ? handleCreateTag() : handleSelectTag())}>
              {newTag ? 'CREATE' : 'SELECT'}
            </Button>
          </div>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
}

export default DatasetItemsTagModal;
