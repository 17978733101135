import {
  DATASET_CATEGORIES,
  DATASET_CATEGORIES_INNER,
} from "../../../../reducer/datasetSlice/constants";

function UnsupportedConfig(task) {
  return `
    <View>
      <Label value="Unsupported task" background="#D4380D"/>
    </View> 
  `;
}

function ConversationalConfig(task) {
  if (!task) return "";

  let prompt = "";
  let query = "";
  let response = "";
  let history = "";

  if (task.data.prompt)
    prompt = ` 
        <View className="prompt">        
            <Text name="prompt-label" value="Prompt" />
        </View>
        <View className="answer-input">
            <TextArea name="prompt" value="$prompt" toName="prompt-label" maxSubmissions="1" required="true"
            requiredMessage="You must provide the response to the prompt" placeholder="Type your answer here..."
            rows="5" />
        </View> 
    `;

  if (task.data.query)
    query = ` 
      <View className="query">
        <Text name="query-label" value="Query" />
        <TextArea name="query" value="$query" toName="query-label" maxSubmissions="1" required="true"
          requiredMessage="You must provide the response to the query" placeholder="Type your answer here..."
          rows="5" />
      </View>
    `;

  if (task.data.response)
    response = ` 
      <View className="response">
        <Text name="response-label" value="Response" />
        <TextArea name="response" value="$response" toName="response-label" maxSubmissions="1" required="true"
          requiredMessage="You must provide the response to the response" placeholder="Type your answer here..."
          rows="5" />
      </View>
    `;

  if (task.data.history)
    history = ` 
      <View className="history">
        <Text name="history-label" value="History" />
        <TextArea name="history" value="$history" toName="history-label" maxSubmissions="1" required="true"
          requiredMessage="You must provide the response to the history" placeholder="Type your answer here..."
          rows="5" />
      </View>
    `;

  return `
    <View className="root">
      <Style>
        .root {
          font-family: 'Roboto', sans-serif;
          line-height: 1.6;
          background-color: #f0f0f0;
        }

        .lsf-main-view__annotation {
          width: 70%;
          margin: 0 auto;
        }

        .container {
          margin: 0;
          padding: 0;
          background: #fff;
          max-width: none;
          box-shadow: none;
        }

        .prompt {
          background: transparent;
          color: #000;
          box-shadow: none;
        }

        svg.relations-overlay {}

        .lsf-main-view {
          background: #fff;
        }

        .answer-input,
        .answer-input:hover {
          background: #fff;
          box-shadow: none;
        }

        textarea.ant-input.is-search {
          border-color: #ECECEC;
          border-radius: 8px;
          resize: none;
        }

        .ant-form-item-control-input-content button {
          background: #000;
          border-color: #000;
          border-radius: 8px;
        }

        .lsf-wrapper {
          max-height: calc(100vh - 115px);
          min-height: calc(100vh - 115px);
          grid-template-columns: 1fr;
          overflow-y: auto;
        }

        .lsf-richtext__line:hover {
          background: unset;
        }

        .lsf-topbar__section.lsf-topbar__section_flat {
          width: 200px;
        }

        .lsf-topbar .lsf-controls {
          padding: 5px 10px;
        }

        .lsf-topbar .lsf-controls .lsf-button[aria-label="skip-task"] {
          background: transparent;
          border: none;
          color: #000;
          font-size: 14px;
          display: none;
        }

        .lsf-topbar .lsf-controls .lsf-button[aria-label="submit"] {
          background: #000;
          font-weight: 400;
          font-size: 14px;
          border-radius: 8px;
          max-width: 50%;
          margin-left: auto;
          margin-right: 0;
          display: block;
        }

        .lsf-topbar__section.lsf-topbar__section_flat {
          border: none;
        }
      </Style>
      <View className="container">
        ${prompt}
        ${query}
        ${response}
        ${history}      
      </View>
    </View>
  `;
}

function OCRConfig(task, metadata = []) {
  if (!task || !task.data) return "";
  let labels = "";
  metadata.forEach((value) => {
    labels = `${labels} <Label value="${value.name || value.label}" background="${value.color}"/>`;
  });

  return `
    <View>
      <Style>
        .root {
          font-family: 'Roboto', sans-serif;
          line-height: 1.6;
          background-color: #f0f0f0;
        }     
      </Style>
      
      <Image name="image" value="$image" crossOrigin="anonymous" />
      
      <Rectangle name="bbox" toName="image" strokeWidth="3"/>
      <Polygon name="poly" toName="image" strokeWidth="3"/>
      
      <Labels name="label" toName="image">
        ${labels}
      </Labels>

      <TextArea name="transcription" toName="image"
            editable="true"
            perRegion="true"
            required="true"
            maxSubmissions="1"
            rows="5"
            placeholder="Recognized Text"
            displayMode="region-list"
      />
    </View> 
  `;
}

function ObjectDetectionConfig(task, metadata = []) {
  if (!task || !task.data) return "";

  let labels = "";
  metadata.forEach((value) => {
    labels = `${labels} <Label value="${value.name || value.label}" background="${value.color}"/>`;
  });

  return `
    <View>
      <Style>
        .root {
          font-family: 'Roboto', sans-serif;
          line-height: 1.6;
          background-color: #f0f0f0;
        }     
      </Style>
      <Image name="image" value="$image" crossOrigin="anonymous" />
      <RectangleLabels name="label" toName="image">      
        ${labels}
      </RectangleLabels>
    </View> 
  `;
}

function ClassificationConfig(task, metadata = []) {
  if (!task || !task.data) return "";
  let choices = "";

  metadata.forEach((value) => {
    choices = `${choices} <Choice value="${value.label}"/>`;
  });

  return `
    <View>
      <Style>
        .root {
          font-family: 'Roboto', sans-serif;
          line-height: 1.6;
          background-color: #f0f0f0;
        }     
      </Style>
      <Image horizontalAlignment="center" verticalAlignment="center" defaultZoom="original" negativeZoom="true" name="image" max-width="700px" value="$image" crossOrigin="anonymous"/>
      <Choices name="label" toName="image" showInline="true">
        ${choices}
      </Choices>
    </View> 
  `;
}

function TabularCommonConfig(task, metadata = []) {
  if (!task || !task.data) return "";

  let t1 = `<Text name="text-1" value="${metadata.columns.join(" | ")}" granularity="word" highlightColor="#ff0000" />`
  let t2 = `<Text name="text-2" value="${metadata.target}" granularity="word" highlightColor="#ff0000" />`
  return `
    <View>
      <Style>
        .root {
          font-family: 'Roboto', sans-serif;
          line-height: 1.6;
          background-color: #f0f0f0;
        }     
      </Style>
      <Header>Tabular data is already annotated.</Header>
      <Header>Target Column</Header>
      ${t2}
      <Header>Column List (${metadata.columns.length})</Header>
      ${t1}
    </View> 
  `;
}

export default function getConfig(task, metadata = [], dataset) {
  const IMAGE_CASE = (type) => {
    switch (type) {
      case DATASET_CATEGORIES_INNER.IMAGE.IMAGE_OBJECT_DETECTION:
        return ObjectDetectionConfig(task, metadata);
      case DATASET_CATEGORIES_INNER.IMAGE.IMAGE_CLASSIFICATION:
        return ClassificationConfig(task, metadata);
        case DATASET_CATEGORIES_INNER.IMAGE.IMAGE_CHARACTER_RECOGNITION:
          return OCRConfig(task, metadata);
      default:
        return UnsupportedConfig(null);
    }
  };

  const LANGUAGE_CASE = (type) => {
    switch (type) {
      case DATASET_CATEGORIES_INNER.LANGUAGE.LANGUAGE_CONVERSATIONAL:
        return ConversationalConfig(task, metadata);
      default:
        return UnsupportedConfig(null);
    }
  };

  // MAIN
  console.log(task, "----dataset.action_type")
  switch (dataset.input_type) {
    case DATASET_CATEGORIES.IMAGE:
      return IMAGE_CASE(dataset.action_type);

    case DATASET_CATEGORIES.LANGUAGE:
      return LANGUAGE_CASE(dataset.action_type);

    case DATASET_CATEGORIES.TABULAR:
      return TabularCommonConfig(task, dataset.metadata);
    default:
      return UnsupportedConfig(null);
  }
}
