import { createAsyncThunk } from "@reduxjs/toolkit";
import { updateProgress } from "../reducer/datasetSlice/datasetSlice";
import axios from "../service/axios";

export const getDataSetList = createAsyncThunk(
  "dataset/dataset_list",
  async ({ projectId, page = "1", size = "10" }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `/datasets/v1/datasets/project/${projectId}?page=${page}&page_size=${size}`
      );

      return { data: data.datasets, metadata: data.pagination_meta };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getDataSetListAll = createAsyncThunk(
  "dataset/dataset_list",
  async (data, { rejectWithValue }) => {
    let { projectId, page = "1", size = "1000" } = data;

    try {
      const { data } = await axios.get(
        `/datasets/v1/datasets/project/${projectId}?page=${page}&page_size=${size}`
      );

      return { data: data.datasets, metadata: data.pagination_meta };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const testDataSetConnection = async (payload) => {
  try {
    const response = await axios.post(
      `/datasources/v1/datasource/pre/test`,
      payload
    );

    return response.data.data;
  } catch (error) {
    return { error: true };
  }
};

/* -------------------------------------------------------------------------- */
/*                               CREATE DATASET                               */
/* -------------------------------------------------------------------------- */
export const createDataSet = createAsyncThunk(
  "dataset/dataset_create",
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(`/datasets/v1/dataset`, payload);

      return data.dataset;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

/* -------------------------------------------------------------------------- */
/*                              GET DATASET BY ID                             */
/* -------------------------------------------------------------------------- */
export const getDataSetById = createAsyncThunk(
  "dataset/get_dataset_by_id",
  async (datasetId, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`/datasets/v1/dataset/${datasetId}`);

      return data.datasets;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
/* -------------------------------------------------------------------------- */
/*                              GET DATASET DATA                              */
/* -------------------------------------------------------------------------- */
export const getDataSetData = createAsyncThunk(
  "dataset/get_dataset_data",
  async (
    {
      datasetId,
      page = "1",
      size = "10",
      isFreshCall = false,
      searchQuery = "",
      filteredTags = "",
    },
    { rejectWithValue, getState }
  ) => {
    const { dataset } = getState();
    const list = dataset.data.list;
    try {
      const { data } = await axios.get(
        `/datasets/v1/dataset/${datasetId}/tasks?page=${page}&page_size=${size}` +
          (searchQuery ? `&q=${searchQuery}` : "") +
          (filteredTags ? `&tags=${filteredTags}` : "")
      );

      let result = {};
      if (!isFreshCall)
        result = { list: [...list, ...data.data], metadata: data.metadata };

      result = { list: data.data, metadata: data.metadata };
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

/* -------------------------------------------------------------------------- */
/*                             GET DATASET CONFIG                             */
/* -------------------------------------------------------------------------- */

// const generateCategories = (categories) => {
//   let defaultCategories = {};

//   categories.forEach((category) => {
//     category.actions.forEach((innerCategory) => {
//       // defaultCategories[category.code][innerCategory.code] = innerCategory.code;
//       defaultCategories[category.code] = {
//         ...defaultCategories[category.code],
//         [innerCategory.code]: innerCategory.code,
//       };
//     });
//   });

//   return defaultCategories;
// };
export const getDataSetConfig = createAsyncThunk(
  "dataset/dataset_config",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`/datasets/v1/config`);

      return data.categories;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

/* -------------------------------------------------------------------------- */
/*                           GET DATASET UPLOAD FILE                          */
/* -------------------------------------------------------------------------- */

export const uploadDatasetFile = createAsyncThunk(
  "dataset/dataset_upload_file",
  async (
    { datasetId, formData },
    { getState, rejectWithValue, requestId, dispatch }
  ) => {
    try {
      const totalDataSize = formData.get("files").size;

      const { data } = await axios.post(
        `/datasets/v1/dataset/${datasetId}/files/upload`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },

          onUploadProgress: (progressEvent) => {
            const loadedDataSize = progressEvent.loaded;
            const progress = Math.round((loadedDataSize * 100) / totalDataSize);

            if (requestId === getState().dataset.uploadFileRequestID) {
              dispatch(updateProgress(progress));
            }
          },
        }
      );
      return data.urls;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const postDatasetFile = createAsyncThunk(
  "dataset/dataset_post_file",
  async ({ datasetId, payload }, { rejectWithValue }) => {
    if (!payload) {
      return;
    }
    let new_payload = JSON.parse(JSON.stringify(payload));
    for (let i in new_payload.files) {
      if (
        new_payload.files[i].type === "JPG" ||
        new_payload.files[i].type === "PNG"
      ) {
        new_payload.files[i].type = "IMAGE";
      }
    }

    try {
      const { data } = await axios.post(
        `/datasets/v1/dataset/${datasetId}/files`,
        new_payload
      );
      return data.categories;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

/* -------------------------------------------------------------------------- */
/*                               GET TASK BY ID                               */
/* -------------------------------------------------------------------------- */

export const getTaskById = createAsyncThunk(
  "dataset/get_task_by_id",
  async (taskId, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`/datasets/v1/task/${taskId}`);
      return data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

/* -------------------------------------------------------------------------- */
/*                            UPDATE DATASET BY ID                            */
/* -------------------------------------------------------------------------- */

export const updateDatasetById = createAsyncThunk(
  "dataset/update_dataset_by_id",
  async ({ datasetId, payload }, { rejectWithValue }) => {
    try {
      const { data } = await axios.patch(
        `/datasets/v1/dataset/${datasetId}`,
        payload
      );
      return data.dataset;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

/* -------------------------------------------------------------------------- */
/*                            DELETE DATASET BY ID                            */
/* -------------------------------------------------------------------------- */

export const deleteDatasetById = createAsyncThunk(
  "dataset/datasets_delete_by_id",
  async (datasetId, { rejectWithValue }) => {
    try {
      const { data } = await axios.delete(`/datasets/v1/dataset/${datasetId}/`);
      return datasetId;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getAllTags = createAsyncThunk(
  "dataset/get_all_tags",
  async (
    { datasetId, page = "1", size = "500" },
    { rejectWithValue, getState }
  ) => {
    try {
      const { data } = await axios.get(
        `/datasets/v1/dataset/${datasetId}/tags?page=${page}&page_size=${size}`
      );

      return data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createTag = createAsyncThunk(
  "dataset/create_tag",
  async ({ datasetId, color, label }, { rejectWithValue, getState }) => {
    try {
      await axios.post(`/datasets/v1/dataset/${datasetId}/tag`, {
        color,
        label,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateTag = createAsyncThunk(
  "dataset/update_tag",
  async ({ datasetId, color, label, id }, { rejectWithValue, getState }) => {
    try {
      await axios.patch(`/datasets/v1/dataset/${datasetId}/tag/${id}`, {
        color,
        label,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteTag = createAsyncThunk(
  "dataset/delete_tag",
  async ({ datasetId, tagId }, { rejectWithValue, getState }) => {
    try {
      await axios.delete(`/datasets/v1/dataset/${datasetId}/tag/${tagId}`);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const copyDataset = createAsyncThunk(
  "dataset/copy_dataset",
  async (
    { datasetId, targetDatasetId, /*  taskId, */ tags, queries },
    { rejectWithValue, getState }
  ) => {
    try {
      await axios.post(`/datasets/v1/dataset/${datasetId}/tasks/copy`, {
        target_dataset_id: targetDatasetId,
        search: {
          /*  task_id: taskId, */
          ...(tags && { tags: tags }),
          ...(queries && { q: queries }),
        },
        is_confirmed: true,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const splitDataset = createAsyncThunk(
  "dataset/split_dataset",
  async (
    {
      datasetId,
      targetDatasetId,
      splitType,
      splitPercentage,
      splitTags,
      // taskId,
      // tags,
      // queries,
    },
    { rejectWithValue, getState }
  ) => {
    try {
      await axios.post(`/datasets/v1/dataset/${datasetId}/tasks/split`, {
        target_dataset_id: targetDatasetId,
        split_type: splitType,
        is_confirmed: true,
        ...(splitPercentage && { split_percentage: splitPercentage }),
        ...(splitTags && { split_tags: splitTags }),
        // search: {
        //   task_id: taskId,
        //   ...(tags && { tags: tags }),
        //   ...(queries && { q: queries }),
        // },
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateDatasetItems = createAsyncThunk(
  "dataset/updateDatasetItems",
  async (
    { datasetId, action, tagId, queries, tags, taskId },
    { rejectWithValue, getState }
  ) => {
    try {
      const payload = {
        search: {
          ...(taskId && { task_id: taskId }),
          ...(tags && { tags: tags }),
          ...(queries && { q: queries }),
        },
        action: {},
        is_confirmed: true,
      };

      switch (action) {
        case "add_tag":
          payload.action["add_tag"] = tagId;
          break;

        case "remove_tag":
          break;

        case 'archive':
          payload.action['archive'] = true;
          break;

        case "unarchive":
          payload.action["unarchive"] = true;
          break;

        case "delete":
          break;

        default:
          console.error("This is not be called");
      }

      await axios.patch(`/datasets/v1/dataset/${datasetId}/tasks`, payload);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

/**
 * This action will update dataset items messages.
 */
export const updateSingleDatasetItem = createAsyncThunk(
  'dataset/updateDatasetItems',
  async ({ datasetId, data }, { rejectWithValue, getState }) => {
    try {
      const payload = {
        data: data.data,
      };

      await axios.patch(`/datasets/v1/task/${data.id}`, payload);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createDatasetItem = createAsyncThunk(
  'dataset/createDatasetItem',
  async ({ data, datasetId, rejectWithValue }) => {
    try {
      const payload = {
        data,
        dataset_id: Number(datasetId),
      };
      await axios.post(`/datasets/v1/task`, payload);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
