import { Button, Modal } from '@mantine/core';
import React, {
  forwardRef,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import icons from '../../../../../../Assets/icons/icons';

const DatasetItemsNewItemTemplateSelection = forwardRef((props, ref) => {
  const [opened, setOpened] = useState(false);
  const [selectedTypeId, setSelectedTypeId] = useState(1);
  const dispatch = useDispatch();
  const { projectId, datasetId } = useParams();
  const navigate = useNavigate();

  const types = useMemo(
    () => [
      {
        id: 1,
        icon: icons.promptResponsePair,
        heading: 'Prompt/Response Pairs',
        subHeading: 'Create a single interaction with a prompt and response',
      },
      {
        id: 2,
        icon: icons.conversation,
        heading: 'Conversations',
        subHeading: 'Create a conversation',
      },
      {
        id: 3,
        icon: icons.custom,
        heading: 'Custom',
        subHeading: 'Choose the columns you want to include.',
      },
    ],
    []
  );

  useImperativeHandle(ref, () => ({
    openModal() {
      setOpened(true);
    },
  }));

  const handleSubmit = () => {
    if (selectedTypeId === 1) {
      const path = `/project/${projectId}/dataset/${datasetId}/items/new-prompt-response-pair`;
      navigate(path);
      // props.setCurrentSectionName('prompt-response-pair');
    } else if (selectedTypeId === 2) {
      const path = `/project/${projectId}/dataset/${datasetId}/items/new-conversation`;
      navigate(path);
    }
    setOpened(false);
  };

  return (
    <Modal.Root
      opened={opened}
      onClose={() => setOpened(false)}
      centered
      size="430px">
      <Modal.Overlay />

      <Modal.Content>
        <Modal.Header>
          <div className="flex flex-col gap-1">
            <h1 className="text-xl font-medium">Select a Template</h1>
            <p>Choose a template to add a new item your dataset</p>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="flex flex-col w-full gap-6">
              {types.map((type) => (
                <div
                  key={type.id}
                  className={`flex flex-row gap-4 items-center border-[2px] rounded-md px-4 py-2 cursor-pointer box-border select-none ${
                    type.id === selectedTypeId
                      ? 'border-gradient'
                      : 'border-gray-200'
                  }`}
                  onClick={() => setSelectedTypeId(type.id)}>
                  <img
                    src={type.icon}
                    alt="tag"
                    className="h-8"
                  />
                  <div className="flex flex-col w-full">
                    <p className="font-medium">{type.heading}</p>
                    <p className="text-xs text-gray-500">{type.subHeading}</p>
                  </div>
                </div>
              ))}
            </div>
            <div className="flex flex-row justify-end items-center gap-6 px-4 mt-6">
              <Button
                uppercase
                variant="subtle"
                color="dark"
                onClick={() => setOpened(false)}>
                GO BACK
              </Button>
              <Button
                uppercase
                onClick={() => handleSubmit()}
                styles={(theme) => ({
                  root: {
                    backgroundColor: '#000 !important',
                    color: '#fff !important',
                  },
                })}
                color="red">
                SELECT
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
});

export default DatasetItemsNewItemTemplateSelection;
