import React from "react";

const Title = ({ className = "", children, ...rest }) => {
  return (
    <div {...rest} className={`gradient-title ${className}`}>
      {children}
    </div>
  );
};

export default Title;
