import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  baseUrl: "",
  uiBaseUrl: "",
};

const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    updateBaseUrl: (state) => {
      let _thisUrl = `https://api.propulsionhq.com`;
      let _thisUIUrl = `https://app.propulsionhq.com`;
      if (window.location.hostname === "app.propulsionhq.com") {
        _thisUrl = `https://api.propulsionhq.com`;
        _thisUIUrl = `https://app.propulsionhq.com`;
      } else {
        _thisUrl = `https://api.nonprod.propulsionhq.com`;
        _thisUIUrl = `https://npapp.propulsionhq.com`;
      }

      state.baseUrl = _thisUrl;
      state.uiBaseUrl = _thisUIUrl;
    },
  },
});

export const { updateBaseUrl } = configSlice.actions;

export default configSlice.reducer;
