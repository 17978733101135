import { createSlice } from "@reduxjs/toolkit";
import {
  deleteVersionById,
  getModelVersionHardware,
  getVersionById,
  getVersionByIdWithoutLoading,
  getVersionConfig,
  getVersionList,
  getVersionTrainings,
} from "../action/version";

const initialState = {
  isLoading: false,
  versions: null,
  metadata: {},
  error: null,
  version: {
    isLoading: false,
    data: null,
    error: null,
  },
  training: {
    isLoading: false,
    list: null,
    error: null,
  },
  hardwares: {
    isLoading: false,
    list: null,
    error: null,
  },
  demo: {
    config: {
      isLoading: false,
      data: null,
      error: null,
    },
  },
};

const versionSlice = createSlice({
  name: "model",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    /* -------------------------------------------------------------------------- */
    /*                               GET ALL MODELS                               */
    /* -------------------------------------------------------------------------- */
    builder.addCase(getVersionList.pending, (state) => {
      state.isLoading = true;
      state.versions = null;
      state.error = null;
    });
    builder.addCase(getVersionList.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.versions = payload.data;
      state.metadata = payload.metadata;
    });

    builder.addCase(getVersionList.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.versions = null;
      state.error = payload;
    });

    /* -------------------------------------------------------------------------- */
    /*                            DELETE VERSION BY ID                            */
    /* -------------------------------------------------------------------------- */
    builder.addCase(deleteVersionById.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(deleteVersionById.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.versions = state.versions.filter(
        (version) => version.id !== payload
      );
    });

    builder.addCase(deleteVersionById.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    });

    /* -------------------------------------------------------------------------- */
    /*                               GET VERSION BY ID                              */
    /* -------------------------------------------------------------------------- */

    builder.addCase(getVersionById.pending, (state, { payload }) => {
      state.version.isLoading = true;
      state.version.data = null;
      state.version.error = null;
    });
    builder.addCase(getVersionById.fulfilled, (state, { payload }) => {
      state.version.isLoading = false;
      state.version.data = payload;
    });

    builder.addCase(getVersionById.rejected, (state, { payload }) => {
      state.version.isLoading = false;
      state.version.data = null;
      state.version.error = payload;
    });

    /* -------------------------------------------------------------------------- */
    /*                      GET VERSION BY ID WITHOUT LOADING                     */
    /* -------------------------------------------------------------------------- */

    builder.addCase(
      getVersionByIdWithoutLoading.fulfilled,
      (state, { payload }) => {
        state.version.data = payload;
      }
    );

    /* -------------------------------------------------------------------------- */
    /*                         GET TRAINING BY VERSION ID                         */
    /* -------------------------------------------------------------------------- */
    builder.addCase(getVersionTrainings.pending, (state, { payload }) => {
      state.training.isLoading = true;
      state.training.list = null;
      state.training.error = null;
    });
    builder.addCase(getVersionTrainings.fulfilled, (state, { payload }) => {
      state.training.isLoading = false;
      state.training.list = payload;
    });

    builder.addCase(getVersionTrainings.rejected, (state, { payload }) => {
      state.training.isLoading = false;
      state.training.list = null;
      state.training.error = payload;
    });

    /* -------------------------------------------------------------------------- */
    /*                      GET MODEL VERSION HARDWARES LIST                      */
    /* -------------------------------------------------------------------------- */
    builder.addCase(getModelVersionHardware.pending, (state, { payload }) => {
      state.hardwares.isLoading = true;
    });
    builder.addCase(getModelVersionHardware.fulfilled, (state, { payload }) => {
      state.hardwares.isLoading = false;
      state.hardwares.list = payload;
    });

    builder.addCase(getModelVersionHardware.rejected, (state, { payload }) => {
      state.hardwares.isLoading = false;
      state.hardwares.error = payload;
    });

    /* -------------------------------------------------------------------------- */
    /*                               GET DEMO CONFIG                              */
    /* -------------------------------------------------------------------------- */
    builder.addCase(getVersionConfig.pending, (state, { payload }) => {
      state.demo.config.isLoading = true;
    });
    builder.addCase(getVersionConfig.fulfilled, (state, { payload }) => {
      state.demo.config.isLoading = false;
      state.demo.config.data = payload;
    });

    builder.addCase(getVersionConfig.rejected, (state, { payload }) => {
      state.demo.config.isLoading = false;
      state.demo.config.error = payload;
    });
  },
});

// export const {} = modelSlice.actions;

export default versionSlice.reducer;
