import { Notifications } from "@mantine/notifications";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App.jsx";
import "./index.css";
// import reportWebVitals from "./reportWebVitals";
import store from "./utils/store.js";

const root = ReactDOM.createRoot(document.getElementById("root"));

// Disable console.log in production
if (process.env.NODE_ENV === "production")
  console.log = () => { };

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <Notifications position="bottom-right" />
    <App />
  </Provider>
  // </React.StrictMode>
);

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// function sendToAnalytics({ id, name, value }) {
//   ga('send', 'event', {
//     eventCategory: 'Web Vitals',
//     eventAction: name,
//     eventValue: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
//     eventLabel: id, // id unique to current page load
//     nonInteraction: true, // avoids affecting bounce rate
//   });
// }

// reportWebVitals(sendToAnalytics);
