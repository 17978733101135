export const allColumns = [
  {
    label: 'Prompt',
    name: '',
    fieldName: 'prompt',
    placeholder: `Enter user's prompt...`,
    color: '#CA4E27',
    required: false,
  },
  {
    label: 'Query',
    name: '',
    fieldName: 'query',
    placeholder: `Enter user's prompt...`,
    color: '',
    required: true,
  },
  {
    label: 'Response',
    name: '',
    fieldName: 'response',
    placeholder: `Enter assistant's response         ...`,
    color: '#FF44CB',
    required: true,
  },
  {
    label: 'History',
    name: '',
    fieldName: 'history',
    placeholder: `Enter any relevant history...`,
    color: '#289915',
    required: false,
  },
  {
    label: 'Messages',
    name: '',
    fieldName: 'messages',
    placeholder: `Enter user's prompt...`,
    color: '',
    required: false,
  },
  {
    label: 'System',
    name: '',
    fieldName: 'system',
    placeholder: `Enter user's prompt...`,
    color: '#FFA800',
    required: false,
  },
  {
    label: 'Tools',
    name: '',
    fieldName: 'tools',
    placeholder: `Enter user's prompt...`,
    color: '',
    required: false,
  },
  {
    label: 'Images',
    name: '',
    fieldName: 'images',
    placeholder: `Enter user's prompt...`,
    color: '',
    required: false,
  },
  {
    label: 'Chosen',
    name: '',
    fieldName: 'chosen',
    placeholder: `Enter user's prompt...`,
    color: '',
    required: false,
  },
  {
    label: 'Rejected',
    name: '',
    fieldName: 'rejected',
    placeholder: `Enter user's prompt...`,
    color: '',
    required: false,
  },
];
