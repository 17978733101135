import React from "react";
import Chat from "../../../Compositions/Chat/Chat";
import Setting from "../../../Compositions/Chat/Setting/Setting";
import ChatHeader from "../../../Compositions/ChatHeader/ChatHeader";

const ChatPublic = () => {
  return (
    <div>
      <ChatHeader />
      <div className="relative">
        <Setting />
        <div className="container max-w-5xl mx-auto ">
          <Chat />
        </div>
      </div>
    </div>
  );
};

export default ChatPublic;
