import logoMain from "./Logo_main.svg";
import apple from "./apple.svg";
import blurBottom from "./blur_bottom.svg";
import blurTop from "./blur_top.svg";
import checkIcon from "./check_icon.svg";
import git from "./git.svg";
import google from "./google.svg";
import icons from "./icons/icons";
import logoIcon from "./logo_icon.svg";
import placeholder from "./placeholder/placeholder";

const images = {
  icons,
  placeholder,
  logoMain,
  apple,
  git,
  google,
  blurTop,
  blurBottom,
  checkIcon,
  logoIcon,
};
export default images;
