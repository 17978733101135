import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../service/axios";

export const resetPasswordTrigger = createAsyncThunk(
  "auth/reset_password_trigger",
  async ({ email }, { rejectWithValue }) => {
    try {
      const response = await axios.post("auth/v1/reset_password_trigger", {
        email,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const resetPassword = createAsyncThunk(
  "auth/reset_password",
  async ({ token, password }, { rejectWithValue }) => {
    try {
      const response = await axios.post("auth/v1/reset_password", {
        token,
        password,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

/* -------------------------------------------------------------------------- */
/*                                    LOGIN                                   */
/* -------------------------------------------------------------------------- */
export const login = createAsyncThunk(
  "auth/login",
  async ({ email, password }, { rejectWithValue }) => {
    try {
      const response = await axios.post("/auth/v1/login ", { email, password });
      await localStorage.setItem("token", response.data.token);
      await localStorage.setItem("user_id", response.data.account_id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

/* -------------------------------------------------------------------------- */
/*                                  REGISTER                                  */
/* -------------------------------------------------------------------------- */
export const register = async ({ name, email, password }) => {
  try {
    const response = await axios.post("/auth/v1/signup/trigger", {
      name,
      email,
      password,
    });
    return response.data;
  } catch (error) {
    return { error: true };
  }
};

/* -------------------------------------------------------------------------- */
/*                            COMPLETE REGISTRATION                           */
/* -------------------------------------------------------------------------- */
export const completeRegistration = async ({ name, email, password, code }) => {
  try {
    const response = await axios.post("/auth/v1/signup/complete", {
      name,
      email,
      password,
      code,
    });
    return response.data;
  } catch (error) {
    return { error: true };
  }
};

/* -------------------------------------------------------------------------- */
/*                              GET CURRENT USER                              */
/* -------------------------------------------------------------------------- */
export const getCurrentUser = createAsyncThunk(
  "auth/current_user",
  async (token, { rejectWithValue }) => {
    try {
      const { data } = await axios.get("/users/v1/profile");

      return { user: data.data, token };
    } catch (error) {
      localStorage.clear();
      return rejectWithValue(error);
    }
  }
);

/* -------------------------------------------------------------------------- */
/*                            TRIGGER SOCIAL LOGIN                            */
/* -------------------------------------------------------------------------- */
export const triggerSocialLogin = createAsyncThunk(
  "auth/social_trigger",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post("/auth/v1/login", payload);

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

/* -------------------------------------------------------------------------- */
/*                               UPDATE PROFILE                               */
/* -------------------------------------------------------------------------- */

export const updateProfile = createAsyncThunk(
  "auth/update_profile",
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await axios.patch("/users/v1/profile", payload);

      return data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

/* -------------------------------------------------------------------------- */
/*                           UPDATE PROFILE PICTURE                           */
/* -------------------------------------------------------------------------- */

export const updateProfilePicture = createAsyncThunk(
  "auth/update_profile_picture",
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await axios.post("/users/v1/profile/picture", payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      return data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
