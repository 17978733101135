import React, { useState } from "react";

const ChatInput = ({ onSubmit }) => {
  const [value, setValue] = useState("");

  const handleChange = ({ target }) => {
    setValue(target.value);
  };
  const isActive = value.length > 0;

  const handleSubmit = () => {
    if (!isActive) return;

    onSubmit(value);
    setValue("");
  };

  return (
    <div className="flex items-center justify-between border bg-white border-[#ECECEC] rounded-md ">
      <input
        type="text"
        placeholder="Ask me anything"
        className="flex-1 px-4 py-2 text-sm rounded-md outline-none"
        value={value}
        onChange={handleChange}
      />

      <Button onClick={handleSubmit} isActive={isActive} />
    </div>
  );
};

export default ChatInput;

const Button = ({ onClick, isActive = false }) => {
  return (
    <button className="pr-4" onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="15"
        viewBox="0 0 16 15"
        fill="none"
      >
        <path
          d="M15.8132 0.10991C15.6742 -0.00209023 15.4822 -0.0310902 15.3142 0.0349098L0.314158 6.03491C0.129158 6.10891 0.00515804 6.28591 0.000158039 6.48591C-0.00484196 6.68591 0.109158 6.86891 0.291158 6.95291L5.00016 9.12691L15.5002 0.49891L7.00016 10.0499V14.9989L10.5482 11.6879L13.2902 12.9529C13.3572 12.9829 13.4292 12.9989 13.5002 12.9989C13.5802 12.9989 13.6612 12.9789 13.7332 12.9409C13.8722 12.8679 13.9672 12.7349 13.9932 12.5809L15.9932 0.58091C16.0222 0.40391 15.9542 0.22291 15.8132 0.10991Z"
          fill={`${isActive ? "#000" : "#B3B3B3"}`}
        />
      </svg>
    </button>
  );
};
