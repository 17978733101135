import { HoverCard, createStyles } from "@mantine/core";
import React, { useState } from "react";
import images from "../../../Assets/images";
import Slider from "../../../Components/Slider/Slider";

const { icons } = images;

const Setting = () => {
  const [sliderValue, setSliderValue] = useState({
    temperature: 0,
    maxToken: 0,
    topP: 0,
  });
  const { classes } = useStyles();

  const handleSliderChange = (type, value) => {
    setSliderValue({ ...sliderValue, [type]: value });
  };

  return (
    <div>
      <HoverCard shadow="md" width={400}>
        <HoverCard.Target>
          <button className="absolute px-2 py-2 border border-[#ECECEC] rounded-md right-6 top-6">
            <img src={icons.gears} alt="setting" />
          </button>
        </HoverCard.Target>

        <HoverCard.Dropdown className={classes.item}>
          <div className="mb-4">
            <div className="flex items-center justify-between mb-2 font-bold">
              <h4 className=" text-md">Temperature</h4>
              <div className="border border-[#EBEBEB] text-xs py-1 px-2 rounded-md min-w-[45px] text-center">
                {sliderValue.temperature}
              </div>
            </div>
            <Slider
              onChange={(value) => handleSliderChange("temperature", value)}
              max={10}
              step={0.1}
            />
            <p className="mt-2 text-sm">
              Adjusts randomness of outputs, greater than 1 is random and 0 is
              deterministic, 0.75 is a good starting value.
            </p>
          </div>
          <div className="mb-4">
            <div className="flex items-center justify-between mb-2 font-bold">
              <h4 className=" text-md">Max Tokens</h4>
              <div className="border border-[#EBEBEB] text-xs  py-1 px-2 rounded-md min-w-[45px] text-center">
                {sliderValue.maxToken}
              </div>
            </div>
            <Slider
              onChange={(value) => handleSliderChange("maxToken", value)}
              max={1000}
              step={10}
            />
            <p className="mt-2 text-sm">
              Maximum number of tokens to generate. A word is generally 2-3
              tokens.
            </p>
          </div>
          <div className="mb-4">
            <div className="flex items-center justify-between mb-2 font-bold">
              <h4 className="text-md">Top P</h4>
              <div className="border border-[#EBEBEB] text-xs py-1 px-2 rounded-md min-w-[45px] text-center">
                {sliderValue.topP}
              </div>
            </div>
            <Slider
              onChange={(value) => handleSliderChange("topP", value)}
              max={10}
              step={0.1}
            />
            <p className="mt-2 text-sm">
              When decoding text, samples from the top p percentage of most
              likely tokens; lower to ignore less likely tokens.
            </p>
          </div>
        </HoverCard.Dropdown>
      </HoverCard>
    </div>
  );
};

export default Setting;

const useStyles = createStyles((theme) => ({
  item: {
    ".mantine-Menu-item": {
      fontFamily: `"DM Sans", sans-serif`,
      fontWeight: 500,

      ".icon": {
        width: 15,
        objectFit: "contain",
      },
    },
  },
}));
