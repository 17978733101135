import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { NavLink, useMatch, useParams } from "react-router-dom";

const Tabs = () => {
  const { projectId, modelId, versionId } = useParams();
  const { version } = useSelector((state) => state.version);

  const path = `/project/${projectId}/models/${modelId}/version/${versionId}`;

  const isOverviewActive = useMatch(`${path}/overview`);
  const isDemoActive = useMatch(`${path}/demo`);
  const isApiActive = useMatch(`${path}/api`);
  const isSettingsActive = useMatch(`${path}/setting`);

  const demoDisabled = useMemo(() => {
    if (version.data?.training?.status === "SUCCESSFUL") return false;

    return true;
  }, [version.data]);

  return (
    <ul className="flex gap-6 mb-6 font-medium">
      <li>
        <NavLink
          to={`${path}/overview`}
          className={`${isOverviewActive ? "text-black" : "text-[#999999]"}`}
        >
          Overview
        </NavLink>
      </li>
      {/* <li>
        <NavLink
          // to={`${path}/demo`}
          to={`${path}/demo`}
          className={`${isDemoActive ? "text-black" : "text-[#999999]"}`}
        >
          Demo
        </NavLink>
      </li>
      <li>
        <NavLink
          // to={`${path}/demo`}
          to={`${path}/api`}
          className={`${isApiActive ? "text-black" : "text-[#999999]"}`}
        >
          API
        </NavLink>
      </li> */}

      <li>
        <NavLink
          to={`${path}/Setting`}
          className={`${isSettingsActive ? "text-black" : "text-[#999999]"}`}
        >
          Settings
        </NavLink>
      </li>
    </ul>
  );
};

export default Tabs;
