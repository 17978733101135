import React, { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import Button from '../../../../../../Components/Button/Button';
import DatasetItemsNewItemPromptResponsePair from './DatasetItemsNewItemPromptResponsePair';
import DatasetItemsNewItemTemplateSelection from './DatasetItemsNewItemTemplateSelection';

function DatasetItemsNoItemsPage() {
  const { projectId, datasetId } = useParams();
  const newItemTemplateSelectionRef = useRef();
  // TODO: to remove this if-else login as router is now working
  const [currentSectionName, setCurrentSectionName] = useState(
    'no-items-section'
    // 'prompt-response-pair'
  );

  const handleAddNewItemBtnClick = () => {
    if (newItemTemplateSelectionRef.current)
      newItemTemplateSelectionRef.current.openModal();
  };

  if (currentSectionName === 'no-items-section') {
    return (
      <div
        className="flex flex-col w-full items-center justify-center gap-4 border-t border-gray-200 pt-4"
        style={{ minHeight: 'calc(100dvh - 210px)' }}>
        <p className="text-3xl font-semibold">You don't have any items yet</p>
        <p className="text-normal font-medium">Let's fix that</p>
        <div className="flex flex-row gap-4 items-center justify-center mt-4">
          <Button
            href={`/project/${projectId}/dataset/create/${datasetId}/upload`}>
            Upload Dataset
          </Button>
          <p>OR</p>
          <Button
            intent="outlined"
            onClick={() => handleAddNewItemBtnClick()}>
            ADD NEW ITEM
          </Button>
        </div>
        <DatasetItemsNewItemTemplateSelection
          setCurrentSectionName={setCurrentSectionName}
          ref={newItemTemplateSelectionRef}
        />
      </div>
    );
  }

  if (currentSectionName === 'prompt-response-pair') {
    return <DatasetItemsNewItemPromptResponsePair />;
  }
}

export default DatasetItemsNoItemsPage;
