import { Accordion } from "@mantine/core";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import images from "../../../Assets/images";
import Button from "../../../Components/Button/Button";

const NotValidDataSet = () => {
  const navigate = useNavigate();
  const { projectId, datasetId } = useParams();

  const link = navigate(
    `/project/${projectId}/dataset/create/${datasetId}/upload`
  );

  return (
    <div className="flex flex-col items-center justify-between gap-4 min-h-[80vh]">
      <div className="">
        <div className="mb-10 text-center">
          <h2 className="text-3xl font-bold text-[#BC7100]">
            Validating your dataset...
          </h2>
          <p className="my-5 text-sm">
            Please wait while your file finishes processing.
          </p>
        </div>

        <Accordion
          variant="filled"
          defaultValue="customization"
          className="w-[800px]"
          radius={"md"}
          chevron={<img src={images.icons.sortDown} alt="drop down icon" />}
        >
          <Accordion.Item value="customization" className="bg-[#FFF6E9]">
            <Accordion.Control className="bg-[#FFF6E9] text-[#BC7100]">
              Error #1: The files couldn’t be accessed.
            </Accordion.Control>
            <Accordion.Panel className="bg-[#FFF6E9]">
              Colors, fonts, shadows and many other parts are customizable to
              fit your design needs
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>
      </div>
      <Button href={link} intent="outlined">
        UPLOAD AGAIN
      </Button>
    </div>
  );
};

export default NotValidDataSet;
