import { Drawer } from "@mantine/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Loader from "../../../../../Components/Loader/Loader";

import Header from "./Components/Header";
import List from "./Components/List";

const TaskDrawer = ({ opened, close, open }) => {
  const { datasetId } = useParams();
  const dispatch = useDispatch();

  //   useEffect(() => {
  //     const prepareState = async () => {
  //       if (!datasetId || !opened) return;
  //       dispatch(getDataSetData({ datasetId }));
  //     };
  //     prepareState();
  //   }, [dispatch, datasetId, opened]);

  return (
    <Drawer opened={opened} onClose={close}>
      <Content />
    </Drawer>
  );
};

export default TaskDrawer;

const Content = () => {
  const { isLoading, list, error } = useSelector((state) => state.dataset.data);

  if (isLoading || !list || error) return <Loader height="half" />;

  return (
    <div className="">
      <Header list={list} />
      <div className="h-[88vh] overflow-y-auto pr-2">
        <List list={list} />
      </div>
    </div>
  );
};
