import clsx from "clsx";
import React from "react";

const ChatItem = ({ value, isSent, isLoading, inverted }) => {
  const getClassNames = () => {
    if (inverted && !isSent) return "bg-white";

    if ((!inverted && !isSent) || (inverted && isSent)) return "bg-[#F8F8F8]";

    return "bg-white";
  };

  const className = clsx(`p-3 rounded-md flex w-full`, getClassNames());

  return (
    <div className="flex">
      {
        <div
          className={` min-w-[30px] flex justify-center ${
            isLoading ? "items-center" : "items-start  pt-4"
          }`}
        >
          {!isSent && <span className="dot-span"></span>}
        </div>
      }
      <div className={className}>
        {isLoading ? (
          <div className="">
            <Loader />
          </div>
        ) : isSent ? (
          <span className="text-md">{value}</span>
        ) : (
          <div dangerouslySetInnerHTML={{ __html: value }} />
        )}
      </div>
    </div>
  );
};

export default ChatItem;

const Loader = () => {
  return (
    <div className="lds-ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};
