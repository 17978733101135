import { Menu, createStyles } from "@mantine/core";
import React, { useState } from "react";
import { HiChevronDown, HiChevronUp } from "react-icons/hi";
import { Link, useNavigate } from "react-router-dom";
import images from "../../Assets/images";

const { icons } = images;

const ChatHeader = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const { classes } = useStyles();

  const handleChange = (value) => {
    setOpen(value);
  };

  const navigateToSupport = () => navigate("/settings/support");

  return (
    <div className="py-2 border-b">
      <div className="container px-5 mx-auto max-w-screen-2xl">
        <div className="flex items-center justify-between">
          <Link to="/projects/all" className="">
            <img src={images.logoIcon} alt="" />
          </Link>
          <Menu shadow="md" width={200} onChange={handleChange}>
            <Menu.Target>
              <button className="text-right">
                <div className="flex items-center row">
                  <div className="flex flex-col mr-4 column">
                    <h5 className="text-sm font-bold">Llama-2-7b-chat</h5>
                    <p className="text-xs text-[#838383] font-[500]">
                      Current Model
                    </p>
                  </div>
                  {open ? (
                    <HiChevronUp size={18} />
                  ) : (
                    <HiChevronDown size={18} />
                  )}
                </div>
              </button>
            </Menu.Target>

            <Menu.Dropdown className={classes.item}>
              <Menu.Item
                icon={
                  <img
                    className="icon"
                    src={icons.documentation}
                    alt="Documentation"
                  />
                }
              >
                Documentation
              </Menu.Item>
              <Menu.Item
                icon={
                  <img
                    className="icon"
                    src={icons.support}
                    alt="Contact Support"
                  />
                }
                onClick={navigateToSupport}
              >
                Contact Support
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </div>
      </div>
    </div>
  );
};

export default ChatHeader;

const useStyles = createStyles((theme) => ({
  item: {
    ".mantine-Menu-item": {
      fontFamily: `"DM Sans", sans-serif`,
      fontWeight: 500,

      ".icon": {
        width: 15,
        objectFit: "contain",
      },
    },
  },
}));
