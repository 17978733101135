import { Modal } from '@mantine/core';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import icons from '../../../../../../Assets/icons/icons';
import Button from '../../../../../../Components/Button/Button';
import { allColumns as allColumnsDefault } from './columnModel';

const allColumns = structuredClone(allColumnsDefault);

const ChooseColumns = forwardRef((props, ref) => {
  const [opened, setOpened] = useState(false);

  const [selectedColumns, setSelectedColumns] = useState({});

  const handleSelect = () => {
    const newSelectedColumns = Object.values(selectedColumns);
    props.setSelectedColumns(newSelectedColumns);
    setOpened(false);
  };

  const handleCheckUncheck = (col) => {
    if (selectedColumns[col.fieldName]) {
      setSelectedColumns((prevSelectedColumns) => {
        const updatedSelectedColumns = { ...prevSelectedColumns };
        delete updatedSelectedColumns[col.fieldName];
        return updatedSelectedColumns;
      });
    } else {
      setSelectedColumns((prevSelectedColumns) => {
        return { ...prevSelectedColumns, [col.fieldName]: col };
      });
    }
  };

  useImperativeHandle(ref, () => ({
    openChooseColumnsModal() {
      const tmpSelectedColumns = props.selectedColumns.reduce(
        (acc, elem) => ({ ...acc, [elem.fieldName]: elem }),
        {}
      );
      setSelectedColumns(tmpSelectedColumns);
      setOpened(true);
    },
  }));

  return (
    <Modal.Root
      opened={opened}
      onClose={() => setOpened(false)}
      centered
      size="sm">
      <Modal.Overlay />

      <Modal.Content>
        <Modal.Header>
          <h1 className="text-xl font-medium">Choose the columns</h1>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="flex flex-col w-full gap-3 mb-4">
              {allColumns.map((col) => {
                return (
                  <div
                    className="hover:!bg-[#edddf7] pl-4 rounded-md flex flex-row items-center justify-between py-2 gap-1 cursor-pointer select-none"
                    style={{
                      backgroundColor: selectedColumns[col.fieldName]
                        ? '#F8ECFF'
                        : 'inherit',
                    }}
                    key={col.fieldName}
                    onClick={() => handleCheckUncheck(col)}>
                    <p>{col.label}</p>
                    {selectedColumns[col.fieldName] && (
                      <img
                        src={icons.tickIcon}
                        alt="edit content"
                        className="w-5 mx-2"
                      />
                    )}
                  </div>
                );
              })}
            </div>

            <div className="flex flex-row justify-end items-center gap-6 px-4 mt-2">
              <Button
                uppercase
                intent="subtle"
                onClick={() => setOpened(false)}>
                CANCEL
              </Button>
              <Button
                uppercase
                onClick={() => handleSelect()}>
                YES
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
});

export default ChooseColumns;
