import { Input, Select } from '@mantine/core';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { createDatasetItem } from '../../../../../../action/dataset';
import icons from '../../../../../../Assets/icons/icons';
import Button from '../../../../../../Components/Button/Button';
import ChooseColumns from './ChooseColumns';
import { allColumns as allColumnsDefault } from './columnModel';

const generateValidationSchema = (columns) => {
  return Yup.object().shape({
    columns: Yup.array().of(Yup.object().shape({})),
  });
};

const roles = {
  ASSISTANT: 'assistant',
  USER: 'user',
  SYSTEM: 'system',
  TOOL: 'tool',
};

const messageRoleOptions = [
  { value: roles.ASSISTANT, label: 'ASSISTANT' },
  { value: roles.USER, label: 'USER' },
  { value: roles.SYSTEM, label: 'SYSTEM' },
  { value: roles.TOOL, label: 'TOOL' },
];

function DatasetItemsNewItemPromptResponsePair() {
  const chooseColumnsRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { datasetId, projectId } = useParams();
  const [
    isCreateDatasetItemApiCallIsInProgress,
    setIsCreateDatasetItemApiCallIsInProgress,
  ] = useState(false);
  const [columns, setColumns] = useState(() => {
    const defaultValue = [];
    ['system', 'query', 'prompt', 'response'].forEach((fieldName) => {
      const item = allColumnsDefault.find(
        (item) => item.fieldName === fieldName
      );
      if (item) {
        defaultValue.push(structuredClone(item));
      }
    });
    return defaultValue;
  });

  const formik = useFormik({
    initialValues: { columns },
    // validationSchema: generateValidationSchema(columns),
    onSubmit: async (values) => {
      setIsCreateDatasetItemApiCallIsInProgress(true);
      const action = await dispatch(
        createDatasetItem({
          datasetId,
          data: {
            query: null,
            ...values.columns.reduce((acc, currentItem) => {
              if (currentItem.fieldName === 'messages') {
                return { ...acc, messages: currentItem.msgArr };
              } else if (currentItem.fieldName === 'history') {
                return { history: currentItem.historyArr };
              } else {
                return { [currentItem.fieldName]: currentItem.name };
              }
            }, {}),
          },
        })
      );
      setIsCreateDatasetItemApiCallIsInProgress(false);
      if (createDatasetItem.fulfilled.match(action)) {
        navigate(`/project/${projectId}/dataset/${datasetId}/items`);
      }
    },
  });

  const {
    values,
    errors,
    setValues,
    setFieldValue,
    setFormikState,
    handleChange,
    handleSubmit,
  } = formik;

  useEffect(() => {
    // formik.setFormikState((prevState) => ({
    //   ...prevState,
    //   // validationSchema: generateValidationSchema(columns),
    // }));

    // Handle removed columns.
    const modifiedSet = new Set(columns.map((item) => item.fieldName));
    const removedItems = values.columns.filter(
      (item) => !modifiedSet.has(item.fieldName)
    );
    setValues((preValues) => {
      removedItems.forEach((removedCol) => {
        const index = preValues.columns.findIndex(
          (c) => c.fieldName === removedCol.fieldName
        );
        preValues.columns.splice(index, 1);
      });

      return preValues;
    });

    // Handle added columns.
    columns.forEach((column) => {
      const exist = values.columns?.find(
        (columnFormItem) => columnFormItem.fieldName === column.fieldName
      );
      if (!exist) {
        setValues((preValues) => {
          let valuesNew = { ...preValues };
          if (column.fieldName === 'messages') {
            preValues.columns.push({
              ...column,
              msgArr: [
                { role: roles.ASSISTANT, value: '' },
                { role: roles.USER, value: '' },
              ],
            });
          } else if (column.fieldName === 'history') {
            preValues.columns.push({
              ...column,
              historyArr: [['', '']],
            });
          } else {
            valuesNew.columns.push(column);
          }
          return valuesNew;
        });
      }
    });
  }, [columns, setValues]);


  const handleAddColumn = () => {
    if (chooseColumnsRef.current) {
      chooseColumnsRef.current.openChooseColumnsModal();
    }
  };

  const addNewMessage = () => {
    setValues((values) => {
      const updatedValues = { ...values };
      const msgArr = updatedValues.columns.find(
        (col) => col.fieldName === 'messages'
      ).msgArr;

      const newItem = { role: '', value: '' };
      if (msgArr.length > 0) {
        newItem.role = roles.ASSISTANT;
      } else {
        // if the last message item role is ASSISTANT, then newItem role can be USER.
        if (msgArr[msgArr.length - 1] === roles.ASSISTANT) {
          newItem.role = roles.USER;
        }
      }
      msgArr.push(newItem);

      return updatedValues;
    });
  };

  const removeMessage = (msgIndex) => {
    setValues((values) => {
      const updatedValues = { ...values };

      const msgArr = updatedValues.columns.find(
        (col) => col.fieldName === 'messages'
      ).msgArr;
      msgArr.splice(msgIndex, 1);

      return updatedValues;
    });
  };

  const addNewHistoryItem = () => {
    setValues((values) => {
      const updatedValues = { ...values };
      const historyArr = updatedValues.columns.find(
        (col) => col.fieldName === 'history'
      ).historyArr;

      const newItem = [['', '']];
      historyArr.push(newItem);

      return updatedValues;
    });
  };

  const removeHistoryItem = (historyItemIndex) => {
    setValues((values) => {
      const updatedValues = { ...values };

      const historyArr = updatedValues.columns.find(
        (col) => col.fieldName === 'history'
      ).historyArr;
      historyArr.splice(historyItemIndex, 1);

      return updatedValues;
    });
  };

  return (
    <div className="border-t border-gray-300 py-8">
      <ChooseColumns
        ref={chooseColumnsRef}
        selectedColumns={columns}
        setSelectedColumns={setColumns}
      />

      <header className="flex flex-row items-center gap-4 justify-end">
        <div className="flex flex-row items-center gap-4 rounded-lg px-4 py-3 border border-gray-300">
          <button>
            <img
              src={icons.tag}
              alt="tag"
              className="w-5 mx-2"
            />
          </button>
          <button>
            <img
              src={icons.archive}
              alt="archive"
              className="w-5 mx-2"
            />
          </button>
          <button onClick={() => handleAddColumn()}>
            <img
              src={icons.columns}
              alt="add"
              className="w-5 mx-2"
            />
          </button>
        </div>
        <Button
          isLoading={isCreateDatasetItemApiCallIsInProgress}
          type="submit"
          onClick={handleSubmit}
          form="newPromptResponsePairForm">
          SAVE
        </Button>
        <Button
          disabled={isCreateDatasetItemApiCallIsInProgress}
          intent="danger"
          color="red">
          CANCEL
        </Button>
      </header>

      <main
        className="overflow-y-auto mt-4"
        style={{ height: 'calc(100vh - 360px)' }}>
        <FormikProvider value={formik}>
          <form
            onSubmit={handleSubmit}
            id="newPromptResponsePairForm"
            className="pr-4">
            <FieldArray name="columns">
              {({ push, remove }) => (
                <div className="flex w-full flex-col gap-8 mt-4">
                  {values?.columns?.map((column, index) => {
                    if (column.fieldName === 'messages') {
                      return (
                        <FieldArray
                          name={`columns[${index}].msgArr`}
                          key={`columns[${index}].msgArr`}>
                          {({ push: nestedPush, remove: nestedRemove }) => (
                            <div>
                              <div className="flex flex-row items-center gap-2">
                                <div
                                  className="uppercase font-medium"
                                  style={{ color: column.color }}>
                                  {column.label}
                                </div>
                                <img
                                  onClick={() => addNewMessage()}
                                  src={icons.addSquareOutline}
                                  alt="add new message"
                                  className="w-5 h-5 cursor-pointer"
                                />
                              </div>
                              <div>
                                {column.msgArr.map((msg, msgIndex) => {
                                  return (
                                    <div
                                      className="flex flex-col gap-4 mt-4"
                                      key={`columns[${index}].msgArr[${msgIndex}].role`}>
                                      <div className="flex flex-row gap-2 items-center">
                                        <div>{msgIndex}</div>
                                        <div>&gt;</div>
                                        <div>
                                          <Select
                                            data={messageRoleOptions}
                                            size="xs"
                                            name={`columns[${index}].msgArr[${msgIndex}].role`}
                                            value={
                                              values.columns[index].msgArr[
                                                msgIndex
                                              ].role
                                            }
                                            onChange={(value) => {
                                              setFieldValue(
                                                `columns[${index}].msgArr[${msgIndex}].role`,
                                                value
                                              );
                                            }}
                                          />
                                        </div>
                                        <img
                                          onClick={() =>
                                            removeMessage(msgIndex)
                                          }
                                          src={icons.removeRed}
                                          alt="add new message"
                                          className="w-5 h-5 cursor-pointer"
                                        />
                                      </div>
                                      <div className="pl-[34px] mb-4">
                                        <Input
                                          name={`columns[${index}].msgArr[${msgIndex}].value`}
                                          value={
                                            values.columns[index].msgArr[
                                              msgIndex
                                            ].value
                                          }
                                          onChange={handleChange}
                                        />
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          )}
                        </FieldArray>
                      );
                    } else if (column.fieldName === 'history') {
                      return (
                        <FieldArray
                          name={`columns[${index}].historyArr`}
                          key={`columns[${index}].historyArr`}>
                          {({
                            push: historyArrPush,
                            remove: historyArrRemove,
                          }) => (
                            <div>
                              <div className="flex flex-row items-center gap-2">
                                <div
                                  className="uppercase font-medium"
                                  style={{ color: column.color }}>
                                  {column.label}
                                </div>
                                <img
                                  onClick={() => addNewHistoryItem()}
                                  src={icons.addSquareOutline}
                                  alt="add new history item"
                                  className="w-5 h-5 cursor-pointer"
                                />
                              </div>
                              <div className="flex flex-col gap-6">
                                {column.historyArr.map((msg, historyIndex) => {
                                  return (
                                    <div
                                      className="flex flex-col gap-4 mt-4"
                                      key={`columns[${index}].msgArr[${historyIndex}].role`}>
                                      <div className="flex flex-row gap-0 items-start">
                                        <div className="flex flex-row gap-2 mt-2">
                                          <div>{historyIndex}</div>
                                          <div>&gt;</div>
                                          <img
                                            onClick={() =>
                                              removeHistoryItem(historyIndex)
                                            }
                                            src={icons.removeRed}
                                            alt="add new message"
                                            className="w-5 h-5 cursor-pointer"
                                          />
                                        </div>
                                        <div className="flex flex-col w-full gap-4">
                                          <div className="flex flex-row gap-2 items-center w-full">
                                            <div className="pl-8 w-28 text-gray-500">
                                              Prompt
                                            </div>
                                            <Input
                                              classNames={{
                                                wrapper: `w-full`,
                                              }}
                                              name={`columns[${index}].historyArr[${historyIndex}][0]`}
                                              value={
                                                values.columns[index]
                                                  .historyArr[historyIndex][0]
                                              }
                                              onChange={handleChange}
                                            />
                                          </div>
                                          <div className="flex flex-row items-center gap-2 w-full">
                                            <div className="pl-8 w-28 text-gray-500">
                                              Response
                                            </div>

                                            <Input
                                              classNames={{
                                                wrapper: `w-full`,
                                              }}
                                              name={`columns[${index}].historyArr[${historyIndex}][1]`}
                                              value={
                                                values.columns[index]
                                                  .historyArr[historyIndex][1]
                                              }
                                              onChange={handleChange}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          )}
                        </FieldArray>
                      );
                    } else {
                      return (
                        <div
                          className="flex flex-col w-full gap-2"
                          key={index}>
                          <div
                            className="uppercase font-medium"
                            style={{ color: column.color }}>
                            {column.label}
                          </div>
                          <Input
                            name={`columns[${index}].name`}
                            required
                            label={column.label}
                            aria-label={column.label}
                            placeholder={column.placeholder}
                            onChange={handleChange}
                            error={errors.columns?.[index]?.name}
                            value={column[`columns[${index}].name`]}
                          />
                        </div>
                      );
                    }
                  })}
                </div>
              )}
            </FieldArray>
          </form>
        </FormikProvider>
      </main>
    </div>
  );
}

export default DatasetItemsNewItemPromptResponsePair;
